import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductEs } from '../../price-calculator-es/product-es';
import { MainGroup, mainGroupLongToShortMap } from '../../price-calculator-es/price-calculator-es';
import { PriceCalculatorEsService } from '../../price-calculator-es/price-calculator-es.service';

@Component({
	selector: 'app-product-es-table-content, [app-product-es-table-content]',
	templateUrl: './product-es-table-content.component.html',
	styleUrls: ['./product-es-table-content.component.scss'],
})
export class ProductEsTableContentComponent implements OnInit {

	@Input()
	public products: ProductEs.WithResult[] = [];

	@Input()
	public group: MainGroup;

	@Output()
	public onDelete = new EventEmitter<ProductEs.WithResult>();

	public hasDeletionObservers = false;

	public ngOnInit(): void {
		this.hasDeletionObservers = Boolean(this.onDelete.observers.length);
	}

	constructor(
			private readonly priceCalculatorEsService: PriceCalculatorEsService,
	) {
	}

	public isBelt(group: MainGroup) {
		return this.priceCalculatorEsService.isBelt(mainGroupLongToShortMap[group]);
	}
}
