import { Injectable } from '@angular/core';
import { USER_ADMIN, USER_CUSTOMER, USER_PARTNER, USER_SALES, UserGroup } from './user-group';
import { CachedAjaxService } from '../../../services/cached-ajax.service';


@Injectable({
	providedIn: 'root',
})
export class UserGroupService {
	constructor(private readonly cachedAjaxService: CachedAjaxService) {
	}

	/**
	 * Returns an array of label object (of groups) that can be displayed in a select
	 */
	public async getAllGroupsForSelect(): Promise<any[]> {
		const groups = await this.getAllGroups();
		return groups.map(group => ({
			label: group.title,
			value: group.id,
			object: group
		}));
	}

	/**
	 * Returns a list of groups (currently hard coded, later from database)
	 */
	public async getAllGroups(): Promise<UserGroup[]> {
		return [
			{title: 'Customer', id: USER_CUSTOMER},
			{title: 'Partner', id: USER_PARTNER},
			{title: 'Sales', id: USER_SALES},
			{title: 'Admin', id: USER_ADMIN}
		];
	}

	/**
	 * Calls a service which updates the access of groups for SMA's
	 */
	public async updateGroupAccess(updateData): Promise<any> {
		const response = await this.cachedAjaxService.fetchRequest({
			url: 'user-groups/update-access',
			body: updateData,
			method: 'POST'
		});
		if (!response.success) {
			throw new Error('Group Access could not be updated');
		}
		return response
	}

	/**
	 * Returns a multidimensional array of user groups that can be used by the administrators to update the access
	 */
	public async getAccessMatrix(): Promise<any> {
		const response = await this.cachedAjaxService.fetchRequest({
			url: 'user-groups/access-matrix'
		});
		if (!response.success) {
			throw new Error('Access Matrix could not be loaded');
		}
		return response
	}
}
