import { Component } from '@angular/core';
import { ConfirmService } from './confirm.service';

@Component({
	selector: 'app-confirm',
	templateUrl: './confirm.component.html'
})
export class ConfirmComponent {
	constructor(public readonly confirm: ConfirmService) {
	}
}
