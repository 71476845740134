import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListComponent } from './pages/list/list.component';
import { AuthGuard } from '../authorization/services/auth-guard.service';
import { DownloadCategoriesResolver } from './download-categories.resolver';
import { DownloadDocumentResolver } from './download-document.resolver';


const routes: Routes = [
	{
		path: 'download-area',
		component: ListComponent,
		resolve: {
			categories: DownloadCategoriesResolver,
			documents: DownloadDocumentResolver
		},
		canActivate: [AuthGuard]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule]
})
export class DownloadAreaRoutingModule {
}
