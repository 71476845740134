import { Component, Input } from '@angular/core';
import { Card } from './card';

@Component({
	selector: 'card-grid',
	templateUrl: './card.component.html'
})
export class CardComponent {
	/**
	 * The items that will be displayed in the card
	 */
	@Input()
	public items: Card[];
}
