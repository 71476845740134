import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../price-calculator/product';
import { WishlistService } from '../wishlist/wishlist.service';
import { Wishlist } from '../wishlist/wishlist';
import { ProductService } from '../generic-product-table/product.service';
import { AjaxLoaderService } from '../layout/ajax-loader/ajax-loader.service';

@Component({
	selector: 'printable-table',
	templateUrl: './printable-table.component.html',
})
export class PrintableTableComponent implements OnInit {
	public list: Wishlist = null;
	public groups = this.productService.getGroups();
	public products: Product[];

	constructor(
			private readonly productService: ProductService,
			private readonly wishlistService: WishlistService,
			private readonly route: ActivatedRoute,
			private readonly ajaxLoader: AjaxLoaderService
	) {
	}

	public async ngOnInit(): Promise<void> {
		this.list = await this.wishlistService.getWishlist(this.route.snapshot.params.id);
		this.products = this.list.products;
		await this.ajaxLoader.show();
		await Promise.all(this.products.map(product => product.pricePromise));
		requestAnimationFrame(() => {
			this.ajaxLoader.hide();
			window.print();
		});
	}

	public filterProductsByGroup(value: Product[], group) {
		return value.filter(product => product.mainGroup === group);
	}
}
