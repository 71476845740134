import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AbstractUserFormComponent, USER_FORM_MODE_ADD } from '../abstract.user-form.component';
import { UserService } from '../user.service';
import { User } from '../user';
import { LanguageService } from '../../../layout/language/language.service';
import { UserGroupService } from '../user-group/user-group.service';
import {
	NOTIFICATION_ERROR,
	NOTIFICATION_SUCCESS,
	NotificationService
} from '../../../layout/notification/notification.service';
import { UserNationalityService } from '../user-nationality/user-nationality.service';

@Component({
	selector: 'app-user-add',
	templateUrl: './user-form.component.html',
})
export class UserAddComponent extends AbstractUserFormComponent {
	mode: number = USER_FORM_MODE_ADD;

	constructor(
			protected location: Location,
			protected languageService: LanguageService,
			protected userGroupService: UserGroupService,
			protected userNationalityService: UserNationalityService,
			private router: Router,
			private userService: UserService,
			private notificationService: NotificationService
	) {
		super(location, languageService, userGroupService, userNationalityService);
	}

	public async ngOnInit(): Promise<void> {
		await super.ngOnInit();
		this.user = new User();
	}

	/**
	 * Adding new User to user list
	 */
	public async addUser(): Promise<void> {
		if (!this.formValid()) {
			this.notificationService.notify('Missing Data!', NOTIFICATION_ERROR);
			return;
		}
		try {
			await this.userService.addUser(this.user);
			this.notificationService.notify('UserController was successfully added. An E-Mail for Password Recovery was send.', NOTIFICATION_SUCCESS);
			await this.router.navigate(['/user-admin']);
		} catch (e) {
			this.notificationService.notify('Error creating User! Does a user with the given E-Mail already exist?', NOTIFICATION_ERROR);
		}
	}
}
