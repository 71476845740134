import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { URL } from '../../generic-types';

export type AUTH_TOKEN = string;

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	public redirectUrl: URL;

	constructor(protected readonly localStorage: LocalStorage) {
	}

	/**
	 * Returns the header with a valid auth token.
	 */
	public async addAuthTokenToHeader(initialHeader: Object = {}): Promise<any> {
		initialHeader['auth-token'] = await this.getAuthToken();
		return initialHeader;
	}

	/**
	 * Sets the auth token
	 */
	public setAuthToken(token): Promise<any> {
		return new Promise((resolve, reject) => {
			this.localStorage.setItem('authToken', token).subscribe(resolve, reject);
		});
	}

	/**
	 * Gets the auth token
	 */
	public async getAuthToken(): Promise<AUTH_TOKEN> {
		return new Promise((resolve, reject) => {
			this.localStorage.getItem<AUTH_TOKEN>('authToken').subscribe(resolve, reject);
		});
	}

	/**
	 * Remove auth token
	 */
	public removeAuthToken(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.localStorage.removeItem('authToken').subscribe(resolve, reject);
		});
	}
}
