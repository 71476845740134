export const PRICE_LIST_DATE = "01.01.2024";
export const STATIC_PAGE_DATA = {
	privacy: {
		title: "Privacy Policy",
		content: "Content of the Privacy Policy",
	},
	imprint: {
		title: "Imprint",
		content: "Content of the Imprint",
	},
};
