import { PriceCalculatorService } from './price-calculator.service';
import { WishlistPriceCalculatorInterface } from '../wishlist/wishlist-price-calculator.interface';
import { GROUP } from './groups';

/**
 * @description
 * This class controls the whole price calculation process with the setters of the class.
 * The calculation is called if one of the following setters is called:
 * - set series
 * - set discount
 * - set units
 * - set sma
 * - set group
 * - set width
 * - set length
 * - set corn
 * - set markings
 */
export class PriceCalculator implements WishlistPriceCalculatorInterface {
	_series: string;
	discount: string;
	units: number;
	_sma: string;
	group: GROUP;
	width: string;
	length: string;
	corn: string = '';
	markings: string;
	_debugValues = {
		_priceIncreaseFactor: 0,
		_generalSurcharge: 0,
		_rWidthSurcharge: 0,
		_dimensionSurcharge: 0,
		_qmSurcharge: 0,
		_widthSurcharge: 0,
		_wastePercentage: 0,
		_changeFactor: 0,
		_price: 0,
		_cornFactor: 0,
		_fiber: 0,
		_cornLine: 0,
		_area: 0,
		_group: '',
		_sma: '',
		_series: '',
		_width: 0,
		_length: 0,
		_corn: 0,
		_markings: 0,
		_fixedPrice: undefined,
		_isValidForPriceCheck: false,
		_backingFactor: 0
	};
	unitsPerPrice: number;
	netPrice: number;//Price in cents
	netPriceWithDiscount: number;
	unitsPerPackaging: string = null;
	private _smaOption: string = '';
	articleNumber: string = '';
	connection: string = '';
	private _dimension: string = '';
	minOrderUnits: number = null;

	constructor(private readonly priceCalculatorService: PriceCalculatorService) {
		this.reset();
	}

	public reset() {
		this.articleNumber = '';
		this._smaOption = '';
		this.connection = '';
		this._dimension = '';
		this.minOrderUnits = null;
		this._series = '';
		this.discount = '0';
		this.units = 100;
		this._sma = '';
		this.group = GROUP.none;
		this.width = '';
		this.length = '';
		this.corn = '';
		this.markings = '0';
		this.unitsPerPrice = 100;
		this.netPrice = 0;
		this.netPriceWithDiscount = 0;
		this.unitsPerPackaging = '';
		this._debugValues = {
			_priceIncreaseFactor: 0,
			_generalSurcharge: 0,
			_rWidthSurcharge: 0,
			_dimensionSurcharge: 0,
			_qmSurcharge: 0,
			_widthSurcharge: 0,
			_wastePercentage: 0,
			_changeFactor: 0,
			_price: 0,
			_cornFactor: 0,
			_fiber: 0,
			_cornLine: 0,
			_area: 0,
			_group: '',
			_sma: '',
			_series: '',
			_width: 0,
			_length: 0,
			_corn: 0,
			_markings: 0,
			_fixedPrice: undefined,
			_isValidForPriceCheck: false,
			_backingFactor: 0
		};
	}

	public allValuesSet(): boolean {
		return !!(this.series && this.sma && this.width && this.length && this.corn);
	}

	get series() {
		return this._series;
	}

	set series(series) {
		this.discount = ''; // reset discount form-input
		this._series = series && series.toUpperCase();
		this.corn = '';
		this.width = undefined;

		// experimental change to how RGE's and ROL's receive default values.
		if (series && (this.group === 'RGE' || this.group === 'ROR')) {
			this.length = '100';
		} else {
			this.length = undefined;
		}
	}

	get sma(): string {
		return this._sma;
	}

	set sma(sma: string) {
		this._sma = sma && sma.toUpperCase();
	}

	public get dimension(): string {
		return this._dimension;
	}

	public set dimension(value: string) {
		this.connection = '';
		this._dimension = value;
	}

	public get smaOption(): string {
		return this._smaOption;
	}

	public set smaOption(value: string) {
		this._dimension = '';
		this.connection = '';
		this._smaOption = value;
	}

	public async setGroup(group: GROUP) {
		this.discount = ''; // reset discount form-input
		this.group = (group && group.toUpperCase()) as GROUP;
		this.sma = this.group ? this.group[0] : '';//Sets sma to the first letter of the group. If no group is set, an empty string will be used

		if (this.group === 'ROR') {
			const result = await this.priceCalculatorService.getMinWidthByGroupAndSeries(this.series, 'ROR');
			this.width = result || undefined;
			this.length = '100';
		} else if (this.group === 'RGE') {
			this.length = '100';
		}
	}

	public async refreshProductPrice(mainGroup: string) {
		let price = 0;
		try {
			price = await this.priceCalculatorService.queryCurrentNetPrice(this, mainGroup);
		} catch (e) {
			throw e;
		} finally {
			this.netPrice = price;
		}

		let units = this.units - 1;//because we want to charge for MORE than the unitsPerPrice. not exactly unitsPerPrice
		let newNet = (((units - (units % this.unitsPerPrice)) / this.unitsPerPrice) + 1) * price;
		let discount = newNet / 100 * this.getDiscountAsNumber();
		this.netPriceWithDiscount = (newNet - discount) / 100;
		const resp = await this.priceCalculatorService.getPackaging(this.group, this.series, this.sma, this.width, this.length, this.corn, this.markings);
		this.unitsPerPackaging = typeof resp === 'string' ? resp : null;
	}

	private getDiscountAsNumber(): number {
		const stringify = `${this.discount || '0'}`;
		const cleared = stringify.replace(/[^\d,.]/, '');
		return +cleared.replace(',', '.');
	}
}
