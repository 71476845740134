import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./authorization/login/login.component";
import { ResetPasswordComponent } from "./authorization/reset-password/reset-password.component";
import { SetPasswordComponent } from "./authorization/reset-password/set-password.component";
import { AdminGuard } from "./authorization/services/admin-guard.service";
import { AuthGuard } from "./authorization/services/auth-guard.service";
import { ChineseNationalityGuard } from "./authorization/services/chinese-nationality-guard.service";
import { GermanNationalityGuard } from "./authorization/services/german-nationality-guard.service";
import { NotAuthGuard } from "./authorization/services/not-auth-guard.service";
import { SpanishNationalityGuard } from "./authorization/services/spanish-nationality-guard.service";
import { UserAdminComponent } from "./authorization/user/user-admin/user-admin.component";
import { UserAddComponent } from "./authorization/user/user-forms/user-add.component";
import { UserEditComponent } from "./authorization/user/user-forms/user-edit.component";
import { UserComponent } from "./authorization/user/user.component";
import { UserResolver } from "./authorization/user/user.resolver";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FeatureFlag } from "./feature-flags";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PriceCalculatorCnComponent } from "./price-calculator-cn/price-calculator-cn.component";
import { PriceCalculatorEsComponent } from "./price-calculator-es/price-calculator-es.component";
import { PriceCalculatorAccessComponent } from "./price-calculator/price-calculator-access/price-calculator-access.component";
import { PriceCalculatorComponent } from "./price-calculator/price-calculator.component";
import { PrintableTableComponent } from "./printable-table/printable-table.component";
import { SwitchPageComponent } from "./switch-page/switch-page.component";
import { WishlistEsDetailPrintComponent } from "./wishlist-es/wishlist-es-detail/wishlist-es-detail-print/wishlist-es-detail-print.component";
import { WishlistEsDetailComponent } from "./wishlist-es/wishlist-es-detail/wishlist-es-detail.component";
import { WishlistEsComponent } from "./wishlist-es/wishlist-es.component";
import { WishlistDetailComponent } from "./wishlist/wishlist-detail/wishlist-detail.component";
import { WishlistComponent } from "./wishlist/wishlist.component";

const routes: Routes = [
	{ path: "login", component: LoginComponent, canActivate: [NotAuthGuard] },
	{ path: "language-select", component: SwitchPageComponent, canActivate: [NotAuthGuard] },
	{ path: "reset-password", component: ResetPasswordComponent, canActivate: [NotAuthGuard] },
	{ path: "reset-password/:token", component: SetPasswordComponent, canActivate: [NotAuthGuard] },
	{
		path: "price-calculator",
		component: PriceCalculatorComponent,
		canActivate: [GermanNationalityGuard],
		resolve: { user: UserResolver },
	},
	{ path: "price-calculator-access", component: PriceCalculatorAccessComponent, canActivate: [AuthGuard] },
	{ path: "price-calculator-es", component: PriceCalculatorEsComponent, canActivate: [SpanishNationalityGuard] },
	...(FeatureFlag.WishlistEs
		? [
				{ path: "wishlist-es", component: WishlistEsComponent, canActivate: [SpanishNationalityGuard] },
				{ path: "wishlist-es/:id", component: WishlistEsDetailComponent, canActivate: [SpanishNationalityGuard] },
				{
					path: "wishlist-es/:id/print",
					component: WishlistEsDetailPrintComponent,
					canActivate: [SpanishNationalityGuard],
				},
		  ]
		: []),
	{
		path: "price-calculator-cn",
		component: PriceCalculatorCnComponent,
		canActivate: [ChineseNationalityGuard],
		resolve: { user: UserResolver },
	},
	{ path: "user", component: UserComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
	{ path: "user-admin", component: UserAdminComponent, canActivate: [AdminGuard] },
	{ path: "user-admin/redirect", redirectTo: "/user-admin" },
	{ path: "user-admin/add", component: UserAddComponent, canActivate: [AdminGuard] },
	{ path: "user-admin/:id", component: UserEditComponent, canActivate: [AdminGuard] },
	{ path: "home", component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: "wishlist", component: WishlistComponent, canActivate: [AuthGuard] },
	{ path: "wishlist/:id", component: WishlistDetailComponent, canActivate: [AuthGuard] },
	{ path: "wishlist/:id/print", component: PrintableTableComponent, canActivate: [AuthGuard] },
	{ path: "", redirectTo: "language-select", pathMatch: "full" },
	{ path: "**", pathMatch: "full", component: PageNotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
