import { Component } from '@angular/core';
import { CategoryDto } from '../../dto/category.dto';
import { DownloadAreaService } from '../../download-area.service';
import { ModalService } from '../../../layout/modal/modal.service';

@Component({
	selector: 'app-download-list-add-category',
	templateUrl: './add-category.component.html'
})
export class AddCategoryComponent {
	public category: CategoryDto = {
		name: '',
		sorting: ''
	};

	constructor(
			private readonly downloadAreaService: DownloadAreaService,
			private readonly modalService: ModalService
	) {
	}

	public abort(): void {
		this.modalService.close();
	}

	public async save(): Promise<void> {
		await this.downloadAreaService.addCategory(this.category);
		this.modalService.close();
	}
}
