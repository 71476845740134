import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-price-calculator-cn",
	templateUrl: "./price-calculator-cn.component.html",
	styleUrls: ["./price-calculator-cn.component.scss"],
})
export class PriceCalculatorCnComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
