export class UserGroup {
	public id: number = USER_CUSTOMER;
	public title: string;
}

/**
 * all possible user groups
 */
export const USER_CUSTOMER = 1;
export const USER_PARTNER = 2;
export const USER_SALES = 3;
export const USER_ADMIN = 4;
