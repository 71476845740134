import { EventEmitter, Injectable } from "@angular/core";
import { USER_ADMIN, USER_SALES } from "../../authorization/user/user-group/user-group";
import { UserService } from "../../authorization/user/user.service";
import { NAVIGATION_ELEMENTS } from "./navigation-elements";

export interface NavigationElement {
	label: string;
	icon: string;
	path?: string;
	action?: string;
	category?: string;
	params?: string;
	externalPath?: string;
	target?: string;
}

@Injectable({
	providedIn: "root",
})
export class NavigationService {
	public navigationStatus: boolean = true;
	public navigationChange: EventEmitter<boolean> = new EventEmitter();

	constructor(private readonly userService: UserService) {}

	/**
	 * Returns the current status of the navigation
	 */
	public getNavigationStatus(): boolean {
		return this.navigationStatus;
	}

	/**
	 * Either opens or closes the navigation
	 */
	public toggleNavigationStatus(): boolean {
		this.navigationStatus = !this.navigationStatus;
		window.dispatchEvent(new Event("resize"));
		this.navigationChange.emit(this.navigationStatus);
		return this.navigationStatus;
	}

	/**
	 * Returns all routes that the user can navigate to.
	 * If the user has a specific group (e.g. admin) additional routes will be outputted
	 */
	public async getNavigationElements(): Promise<NavigationElement[]> {
		const user = await this.userService.getCurrentUser();

		let elements: NavigationElement[] = JSON.parse(JSON.stringify(NAVIGATION_ELEMENTS));
		elements.forEach((element) => {
			if (element.action != "") {
				element.category = user.userGroup.title;
			}
		});
		if (user.userGroup.id === USER_ADMIN) {
			elements.push({
				label: "Nutzerverwaltung",
				icon: "icon-person-plus",
				path: "/user-admin",
				action: "",
				category: "",
				params: "",
			});
			elements.push({
				label: "Preisberechnung Zugriff",
				icon: "icon-rechner",
				path: "/price-calculator-access",
				action: "",
				category: "",
				params: "",
			});
		}
		if (user.userGroup.id === USER_ADMIN || user.userGroup.id === USER_SALES) {
			elements.push({
				label: "Kosten-Nutzen-Rechner",
				icon: "icon-cost-benefit",
				path: "/cost-benefit",
				action: "CostBenefitRechner",
				category: "",
				params: "Cost Benefit Rechner wurde aufgerufen",
			});
		}

		if (await this.userService.isGerman()) {
			elements.splice(1, 0, {
				label: "Preisberechnung",
				icon: "icon-rechner",
				path: "/price-calculator",
				action: "",
				category: "",
				params: "",
			});
			elements.splice(2, 0, {
				label: "Merkliste",
				icon: "icon-wishlist",
				path: "/wishlist",
				action: "Merkliste",
				category: "",
				params: "Merkliste Navigation wurde aufgerufen",
			});
			elements.push({
				label: "VSM — Katalog",
				icon: "icon-catalogue",
				externalPath: "catalogue-link",
				target: "_blank",
				action: "",
				category: "",
				params: "",
			});
		}

		if (await this.userService.isSpanish()) {
			elements.splice(1, 0, { label: "Preisberechnung ES", icon: "icon-rechner", path: "/price-calculator-es" });
			elements.splice(4, 0, {
				label: "Merkliste ES",
				icon: "icon-wishlist",
				path: "/wishlist-es",
				action: "Merkliste",
				category: "",
				params: "Merkliste Navigation wurde aufgerufen",
			});
			elements.push({
				label: "VSM — Catálogo ES",
				icon: "icon-catalogue",
				externalPath: "/assets/files/catalogues/20231121.CATALOGO.NUESTROS.PRODUCTOS.E7_compressed.pdf",
				target: "_blank",
				action: "",
				category: "",
				params: "",
			});
		}

		if (await this.userService.isChinese()) {
			elements.splice(1, 0, { label: "Preisberechnung CN", icon: "icon-rechner", path: "/price-calculator-cn" });
		}

		return elements;
	}
}
