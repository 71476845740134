import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserAdminSalesGuardService } from "../authorization/services/user-admin-sales-guard.service";
import { PrintComponent } from "./pages/print/print.component";
import { ShowComponent } from "./pages/show/show.component";

const routes: Routes = [
	{
		path: "cost-benefit",
		component: ShowComponent,
		canActivate: [UserAdminSalesGuardService],
	},
	{
		path: "cost-benefit/print",
		component: PrintComponent,
		canActivate: [UserAdminSalesGuardService],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
	exports: [RouterModule],
})
export class CostBenefitRoutingModule {}
