import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm, NgModel, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmService } from '../../../confirm/confirm.service';
import { NotificationService, NOTIFICATION_ERROR } from '../../../layout/notification/notification.service';
import { CommonTranslations } from '../../common-translations';
import { CostBenefitService } from '../../cost-benefit.service';

declare const _etracker;
declare const et_UserDefinedEvent;

@Component({
	selector: 'app-cost-benefit',
	templateUrl: './show.component.html'
})
export class ShowComponent extends CommonTranslations implements OnInit {
	/**
	 * The form used to submit the calculation
	 */
	@ViewChild('form')
	public form: NgForm;
	@ViewChildren(NgModel)
	public fieldDDD: QueryList<any>;

	public scheme: any = {domain: ['#cd0529', '#888']};

	constructor(
			public readonly costBenefitService: CostBenefitService,
			private readonly confirmService: ConfirmService,
			protected readonly translatePipe: TranslatePipe,
			private readonly router: Router,
			private readonly notification: NotificationService
	) {
		super(translatePipe, costBenefitService);
	}

	/**
	 * Initializes the form that could be stored in the localstorage
	 */
	public async ngOnInit(): Promise<void> {
		await this.costBenefitService.getCalculationFormFromCache();
	}

	/**
	 * Starts the calculation when the form is valid and writes the result to the calculationResult property
	 */
	public async startCalculation(): Promise<void> {
		if (!this.form.valid || !this.costBenefitService.isFormValidForCalculation()) {
			return this.notification.notify('Die Berechnung konnte nicht gestartet werden, da das Formular nicht korrekt ausgefüllt wurde.', NOTIFICATION_ERROR);
		}

		await this.costBenefitService.calculate();
	}

	/**
	 * Resets the form after the user confirmed his choice
	 */
	public async resetForm(): Promise<void> {
		const message = this.translatePipe.transform('Sind Sie sicher, dass Sie das Formular zurücksetzen möchten?');
		const button = this.translatePipe.transform('Zurücksetzen');
		if (await this.confirmService.confirm(message, button)) {
			await this.costBenefitService.resetCalculationForm();
		}
	}

	/**
	 * Opens a confirmation dialog and deletes the station at the passed index
	 * @param index
	 */
	public async deleteStation(index: number): Promise<void> {
		if (this.costBenefitService.calculationForm.stations.length <= 1) {
			return this.notification.notify('Das löschen der Station wurde abgebrochen, da nur noch eine Station vorhanden ist.', NOTIFICATION_ERROR);
		}
		const message = this.translatePipe.transform('Sind Sie sicher, dass Sie diese Station löschen möchten?');
		const button = this.translatePipe.transform('Löschen');
		if (await this.confirmService.confirm(message, button)) {
			await this.costBenefitService.removeStationFromCalculationForm(index);
		}
	}

	/**
	 * Opens the print page for the current calculation form
	 */
	public async print(): Promise<void> {
		await this.costBenefitService.calculate();
		if (this.costBenefitService.calculationForm === null || this.costBenefitService.calculationResult === null) {
			// This error is only thrown if the disabled button could be pressed somehow.
			return this.notification.notify('Der Druckvorgang wurde abgebrochen, da das Formular nicht richtig ausgefüllt wurde.', NOTIFICATION_ERROR);
		}
		await this.costBenefitService.writeCalculationToDatabase().catch(e => console.log(e));
		window.open('#/cost-benefit/print');
	}

	public getErrorMessage(field?: ValidationErrors): string {
		if (field.required) {
			return `'${this.translatePipe.transform('Dieses Feld ist ein Pflichtfeld!')}'`;
		}
		if (field.pattern) {
			return `'${this.translatePipe.transform('Nur Zahlenwerte sind erlaubt!')}'`;
		}
		return `'${this.translatePipe.transform('Dieses Feld ist nicht Korrekt ausgefüllt!')}'`;
	}

	eTrackerSendEvent() {
		_etracker.sendEvent(new et_UserDefinedEvent('Cost Benefit Rechners Drucken', 'Ergebnis des Cost Benefit Rechners wurde ausgedruckt', '', 'Click'));
	}
}
