import { Component, Input } from '@angular/core';
import { ProductFinderService } from '../product-finder.service';

@Component({
	selector: 'app-product-detail-link',
	templateUrl: './product-detail-link.component.html'
})
export class ProductDetailLinkComponent {

	private _series: string;
	public productLink: string = '';

	constructor(private readonly productFinder: ProductFinderService) {
	}

	@Input('class')
	public classList: string = '';

	@Input()
	set series(series) {
		this._series = series;
		this.productFinder.getProductFinderLink(series).then((success: string) => {
			this.productLink = success;
		});
	}

	/**
	 * get series from template
	 */
	get series() {
		return this._series;
	}
}
