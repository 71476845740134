import { UserGroup } from './user-group/user-group';
import { Language } from '../../layout/language/language';
import { UserNationality } from './user-nationality/user-nationality';
import { AUTH_TOKEN } from '../services/authentication.service';
import { EMAIL, PASSWORD } from '../../generic-types';
import { LastLoginObject } from '../../pipes/user-last-login-date.pipe';

export class User {
	public id: number;
	public authToken: AUTH_TOKEN;
	public name: string = '';
	public userGroup: UserGroup = new UserGroup();
	public customerNumber: string = '';
	public country: string = '';
	public language: Language = new Language();
	public email: EMAIL = '';
	public emailOfficeStaff: EMAIL = '';
	public emailOutdoorStaff: EMAIL = '';
	public password: PASSWORD;
	public status: string = '';
	public nationalities: UserNationality[] = [{id: 1, name: 'Deutscher Preisrechner'}];
	public lastLogin?: LastLoginObject;
	public disallowedSeries: { id: string }[] = []; // fixme refine type and potentially default value
	public disallowedGroups: { id: string }[] = []; // fixme refine type and potentially default value

	constructor() {
		this.userGroup = new UserGroup();
	}
}
