import { Injectable } from '@angular/core';
import { CachedAjaxService } from '../services/cached-ajax.service';
import { from, Observable, ReplaySubject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ConfirmService } from '../confirm/confirm.service';
import { NOTIFICATION_SUCCESS, NotificationService } from '../layout/notification/notification.service';
import { ProductEs } from '../price-calculator-es/product-es';

@Injectable({
	providedIn: 'root'
})
export class WishlistEsService {

	private refetch$ = new ReplaySubject();

	wishlists$: Observable<WishlistEs[]> = this.refetch$.pipe(
			startWith([]),
			switchMap(() => this.cachedAjaxService.fetchRequest<WishlistEs[]>({
				url: 'wishlist-es'
			})),
	)

	constructor(
			private readonly cachedAjaxService: CachedAjaxService,
			private readonly confirmService: ConfirmService,
			private readonly notificationService: NotificationService
	) {
	}

	getAll() {
		this.refetch$.next(null);
	}

	getOne(id: number): Observable<WishlistEs> {
		return from(this.cachedAjaxService.fetchRequest<WishlistEs>({
			url: `wishlist-es/${id}`
		}));
	}

	updateOne(wishlist: WishlistEs): void {
		from(
				this.cachedAjaxService.fetchRequest<WishlistEs>({
					url: `wishlist-es/${wishlist.id}`,
					body: wishlist,
					method: 'PUT'
				})
		).subscribe(() => {
			this.notificationService.notify(`Name aktualisiert`, NOTIFICATION_SUCCESS);
			this.getAll();
		});
	}

	addOne(title: string): void {
		from(
				this.cachedAjaxService.fetchRequest<WishlistEs>({
					url: 'wishlist-es',
					body: {title},
					method: 'POST'
				})
		).subscribe(() => {
			this.notificationService.notify(`"{{title}}" wurde erfolgreich angelegt.`, NOTIFICATION_SUCCESS, {title});
			this.getAll();
		})
	}

	deleteOne({id, title}: WishlistEs) {
		from(
				this.confirmService.confirm(
						'Möchten Sie die Merkliste {{name}} wirklich löschen?',
						'Merkliste löschen',
						{name: title}
				)
		).pipe(
				switchMap(confirmed => {
					if (confirmed) {
						return this.cachedAjaxService.fetchRequest<WishlistEs>({
							url: `wishlist-es/${id}`,
							method: 'DELETE'
						});
					}
				}),
		).subscribe(() => {
			this.notificationService.notify('Die Merkliste {{name}} wurde erfolgreich entfernt', NOTIFICATION_SUCCESS, {name: title});
			this.getAll();
		});
	}

	addProduct(wishlistId: number, product: ProductEs.Base) {
		const {group, series, corn, width, length, size} = product;

		from(
				this.cachedAjaxService.fetchRequest({
					url: `wishlist-es/${wishlistId}/products`,
					body: {group, series, corn, width, length, size},
					method: 'POST'
				})
		).subscribe(() => {
			this.notificationService.notify(`Das Produkt wurde erfolgreich hinzugefügt.`, NOTIFICATION_SUCCESS);
			this.getAll();
		});
	}

	deleteProduct(wishlistId: number, productId: number) {
		from(
				this.confirmService.confirm(
						'Möchten Sie dieses Produkt löschen?',
						'Produkt löschen'
				)
		).pipe(
				switchMap(confirmed => {
					if (confirmed) {
						return this.cachedAjaxService.fetchRequest<WishlistEs>({
							url: `wishlist-es/${wishlistId}/${productId}`,
							method: 'DELETE'
						})
					}
				})
		).subscribe(() => {
			this.notificationService.notify(`Das Produkt wurde erfolgreich entfernt.`, NOTIFICATION_SUCCESS);
			this.getAll();
		})
	}
}

export type WishlistEs<TProductsEs extends ProductEs.Persisted[] = ProductEs.Persisted[]> = {
	title: string,
	id: number,
	creationDate: Date,
	products: TProductsEs
}
