import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../authorization/user/user.service";
import { ConfirmService } from "../confirm/confirm.service";
import { GenericDialogComponent, Payload } from "../generic-dialog/generic-dialog.component";
import { NotificationService, NOTIFICATION_SUCCESS } from "../layout/notification/notification.service";
import { Wishlist } from "./wishlist";
import { WishlistService } from "./wishlist.service";

declare const _etracker;
declare const et_UserDefinedEvent;

@Component({
	selector: "app-wishlist",
	templateUrl: "./wishlist.component.html",
})
export class WishlistComponent implements OnInit {
	@ViewChild(GenericDialogComponent) renameDialog: GenericDialogComponent;

	/**
	 * the wishlist
	 */
	public wishlists: Wishlist[] = [];
	/**
	 * the title of the page
	 */
	public title: string = "";
	/**
	 * the new title
	 */
	public newTitle: string = "";
	/**
	 * userGroup
	 */
	public userGroup: string = "";

	constructor(
		public readonly wishlistService: WishlistService,
		private readonly notificationService: NotificationService,
		private readonly confirmService: ConfirmService,
		private readonly element: ElementRef,
		private readonly userService: UserService
	) {}

	public async ngOnInit(): Promise<void> {
		const user = await this.userService.getCurrentUser();
		this.userGroup = user.userGroup.title;
		await this.refreshWishlist();
	}

	/**
	 * @description
	 * Adds a new wishlist based by the user entered title
	 */
	public async saveNewWishList(): Promise<void> {
		await this.wishlistService.addWishlist(this.title);
		await this.refreshWishlist();
		this.notificationService.notify(`"${this.title}" wurde erfolgreich angelegt.`, NOTIFICATION_SUCCESS);
		this.title = "";
	}

	/**
	 * Updates the name of the wishlist based by the value of the newTitle property
	 */
	public async renameWishlist({ data }: Payload<number>): Promise<void> {
		const wishlist = await this.wishlistService.getWishlist(data);
		await this.wishlistService.updateWishlist({ ...wishlist, title: this.newTitle } as Wishlist);
		await this.refreshWishlist();
		this.newTitle = "";
		this.notificationService.notify(`Name aktualisiert`, NOTIFICATION_SUCCESS);
	}

	/**
	 * Opens a confirmation for the user and deletes the wishlist by positive input
	 */
	public async deleteWishlist(wishlistId: number): Promise<void> {
		const toBeDeletedList = await this.wishlistService.getWishlist(wishlistId);

		if (
			await this.confirmService.confirm("Möchten Sie die Merkliste {{name}} wirklich löschen?", "Merkliste löschen", {
				name: toBeDeletedList.title,
			})
		) {
			await this.wishlistService.removeWishlist(toBeDeletedList.id);
			await this.refreshWishlist();
			this.notificationService.notify("Die Merkliste {{name}} wurde erfolgreich entfernt", NOTIFICATION_SUCCESS, {
				name: toBeDeletedList.title,
			});
		}
	}

	/**
	 * Pulls the current wishlists from the server
	 */
	private async refreshWishlist(): Promise<void> {
		this.wishlists = await this.wishlistService.getWishlists();
	}

	eTrackerSendEvent() {
		_etracker.sendEvent(new et_UserDefinedEvent("Merkliste anlegen", "Merkliste speichern", this.userGroup, "Click"));
	}
}
