import { Injectable } from "@angular/core";
import { URL } from "../../generic-types";
import { AuthGuard } from "./auth-guard.service";

/**
 * This guard prevents user without spanish nationality to access a route that is protected by this guard
 */
@Injectable()
export class ChineseNationalityGuard extends AuthGuard {
	public async checkLogin(url: URL): Promise<boolean> {
		if (await this.userService.isChinese()) {
			return true;
		}
		this.authenticationService.redirectUrl = url;
		await this.router.navigate(["/home"]);
		return false;
	}
}
