import { Injectable } from '@angular/core';
import {
	filesMap,
	MainGroup,
	mainGroupLongToShortMap,
	MainGroupShort,
	mainGroupShortToLongMap,
	PriceCalculatorEs
} from './price-calculator-es';
import { DatabaseQueryCondition } from '../article-list/database-query-condition';
import { ArticleListService } from '../article-list/article-list.service';
import { AltProducts, SelectOptions, WhereConditionsEs } from './select-options.interface';
import { UserService } from '../authorization/user/user.service';
import { USER_CUSTOMER, USER_PARTNER } from '../authorization/user/user-group/user-group';
import { DatabaseFile } from '../article-list/database-file';
import { ProductEs } from './product-es';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PriceCalculatorEsService {
	/**
	 * Local instance of PriceCalculatorEs class. Gets shared with the component
	 */
	public calculationForm: PriceCalculatorEs;
	/**
	 * Save the current file in class member, depending on the main group
	 */
	private currentFile: DatabaseFile;
	/**
	 * Remember if a user belongs to the 'customer' user group to customize the form accordingly
	 */
	public isCustomerOrPartner: boolean = false;
	/**
	 * Object literal to store all the different pf files on page load
	 */
	private files = {
		accessorios: null,
		bportatiles: null,
		cepillos: null,
		discos: null,
		hojas: null,
		producto: null,
		rolloStandard: null,
		coeficientes: null,
	};
	/**
	 * Object literal to store all the select options. Will get updated on every form-select onChange
	 * during the component's lifetime.
	 */
	public options: SelectOptions = {
		abrasive: [],
		series: [],
		corn: [],
		size: [],
	};
	private altProducts: AltProducts = {
		anchas: [],
		estrechas: [],
		rollos: [],
	};
	private currentMainGroup: string;

	private lastAbrasive: string = null;
	private lastSeries = null;
	private lastCorn = null;

	public get filesInitialized$(): Observable<boolean> {
		return this._filesInitialized$.asObservable();
	}

	private _filesInitialized$ = new BehaviorSubject(false);

	/**
	 * Initialize all pf files, initialize the PriceCalculator and retrieve the user's current user group.
	 */
	constructor(
			private readonly articleListService: ArticleListService,
			private readonly userService: UserService,
	) {
		this.calculationForm = new PriceCalculatorEs();
		this.initializeFiles().then(() => {
			this._filesInitialized$.next(true);
		});
		this.userService.getCurrentUser().then((response) => {
			this.isCustomerOrPartner = response.userGroup.id === USER_CUSTOMER || response.userGroup.id === USER_PARTNER;
		});
	}

	/**
	 * Initialize pf files on page load.
	 */
	private async initializeFiles(): Promise<void> {
		const fileNames = ['Accesorios', 'Bportatiles', 'Cepillos', 'Discos', 'Hojas', 'Producto', 'RolloStandard', 'Coeficientes'];
		const promises = [];
		fileNames.forEach((fileName) => promises.push(this.articleListService.getPfFile(fileName, 'es')));
		[
			this.files.accessorios,
			this.files.bportatiles,
			this.files.cepillos,
			this.files.discos,
			this.files.hojas,
			this.files.producto,
			this.files.rolloStandard,
			this.files.coeficientes,
		] = await Promise.all(promises);

		this.getDataFromProduct();
	}

	/**
	 * Resets specific select options targeted by the keys that will be provided via the method's parameter.
	 */
	public resetSpecificSelectOptions(keys: string[]): void {
		keys.forEach(entry => {
			this.options[entry] = [];
			this.calculationForm[entry] = '';
		});
	}

	/**
	 * Reset everything
	 */
	public resetAllSelectOptions(): void {
		for (let entry in this.options) {
			if (this.options.hasOwnProperty(entry)) {
				this.options[entry] = [];
			}

			this.calculationForm.reset();
		}
	}

	/**
	 * Specialized method to retrieve all the different products from ProductoPF (BAN, BES and ROL).
	 */
	public getDataFromProduct(): void {
		const largeBelts = this.files.producto.select('abrasivo').where({'anchas': new DatabaseQueryCondition('==', 'SI')}).execute();
		const smallBelts = this.files.producto.select('abrasivo').where({'estrechas': new DatabaseQueryCondition('==', 'SI')}).execute();
		const rolls = this.files.producto.select('abrasivo').where({'rollos': new DatabaseQueryCondition('==', 'SI')}).execute();

		this.altProducts.anchas = this.removeDuplicates(largeBelts, 0);
		this.altProducts.estrechas = this.removeDuplicates(smallBelts, 0);
		this.altProducts.rollos = this.removeDuplicates(rolls, 0);
	}

	/**
	 * Set the main group, set the current file, set the upcoming abrasive select options and split into two different
	 * procedures, depending on the current selected main group. Products from ProductoPF get their abrasive options
	 * directly from the abrasivo field. All other main groups get combined select options consisting of tipo and
	 *   abrasivo.
	 *
	 * Note: filesMap and mainGroupMap are object literals to map the fully written-out mainGroup into the according
	 * file's name and main group abbreviation respectively.
	 */
	public selectMainGroup(mainGroup: MainGroup): void {
		if (mainGroup === 'Banda Estrecha' && this.isCustomerOrPartner) {
			this.calculationForm.batch = '0';
		}

		if (mainGroup === 'Banda Ancha' || mainGroup === 'Banda Estrecha' || mainGroup === 'Rollos') {
			this.options.abrasive = this.altProducts[filesMap[mainGroup]];
			this.currentFile = this.files.producto;
			return mainGroup ? this.calculationForm.setGroup(mainGroupLongToShortMap[mainGroup]) : this.calculationForm.setGroup('');
		}
		const tipos = this.removeDuplicates(this.files[filesMap[mainGroup]].select('tipo').execute(), 0);

		this.currentFile = this.files[filesMap[mainGroup]];
		this.currentMainGroup = mainGroup;
		this.options.abrasive = this.combineTypeAndAbrasive(tipos);
		return mainGroup ? this.calculationForm.setGroup(mainGroupLongToShortMap[mainGroup]) : this.calculationForm.setGroup('');
	}

	/**
	 * Fill a given select option according to its provided key.
	 */
	public selectFromDB(key: string): void {
		if (this.lastAbrasive !== null && this.calculationForm.abrasive !== this.lastAbrasive) {
			this.calculationForm.series = '';
			this.calculationForm.corn = '';
			this.calculationForm.size = '';
		}
		if (this.lastSeries !== null && this.calculationForm.series !== this.lastSeries) {
			this.calculationForm.corn = '';
			this.calculationForm.size = '';
		}
		if (this.lastCorn !== null && this.calculationForm.corn !== this.lastCorn) {
			this.calculationForm.size = '';
		}
		this.lastAbrasive = this.calculationForm.abrasive;
		this.lastSeries = this.calculationForm.series;
		this.lastCorn = this.calculationForm.corn;
		const whereConditions = !this.isProduct() && this.calculationForm.abrasive.indexOf('/') !== -1
				? this.buildWhereConditionCombinedAbrasive()
				: this.buildWhereCondition();
		const header = this.transformToTableHeader(key);

		if (!header) {
			console.warn('No corresponding table header!');
			return;
		}
		this.options[key] = this.removeDuplicates(this.currentFile.select(header).where(whereConditions).execute(), 0);
	}

	/**
	 * Remove any duplicates and empty entries from any given string array.
	 */
	private removeDuplicates(arrayToFilter: any[], idx: number): string[] {
		let result = arrayToFilter.map(item => item[idx]);
		return result.filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);
	}

	/**
	 * Translate keys into their respective spanish table header name.
	 */
	private transformToTableHeader(key: string): string {
		return {
			type: 'tipo',
			abrasive: 'abrasivo',
			series: 'calidad',
			corn: this.currentMainGroup === 'Accesorios' ? 'dureza' : 'grano',
			size: 'medidas',
		}[key] || '';
	}

	/**
	 * Build the where condition for any DB query where the current main group is of any 'non-product' main group
	 * (all but BAN, BES or ROL).
	 * This method can be used at any stage of the form filling progression as empty values will just result in an
	 * undefined entry in the where condition which will get successfully ignored during any query.
	 */
	private buildWhereConditionCombinedAbrasive(): WhereConditionsEs {
		const {abrasive, series, corn, size} = this.calculationForm;

		return {
			tipo: abrasive.length ? new DatabaseQueryCondition('==', abrasive.split('/')[0].trim()) : undefined,
			abrasivo: abrasive.length ? new DatabaseQueryCondition('==', abrasive.split('/')[1].trim()) : undefined,
			calidad: series.length ? new DatabaseQueryCondition('==', series.trim()) : undefined,
			[this.currentMainGroup === 'Accesorios' ? 'dureza' : 'grano']: corn.length ? new DatabaseQueryCondition('==', corn.trim()) : undefined,
			medidas: size.length ? new DatabaseQueryCondition('==', size.trim()) : undefined,
			solo_vitex: this.isCustomerOrPartner ? new DatabaseQueryCondition('==', 'NO') : undefined,
		};
	}

	/**
	 * Build the where condition for any DB query where the current main group is of any product from the file ProductoPF
	 * (BAN, BES or ROL).
	 * This method can be used at any stage of the form filling progression as empty values will just result in an
	 * undefined entry in the where condition which will get successfully ignored during any query.
	 */
	private buildWhereCondition(): WhereConditionsEs {
		const {group, abrasive, series, corn, size} = this.calculationForm;
		const map = {BAN: 'anchas', BES: 'estrechas', ROL: 'rollos'};

		return {
			[map[group]]: this.isProduct() ? new DatabaseQueryCondition('==', 'SI') : undefined,
			[this.isProduct() ? 'abrasivo' : 'tipo']: abrasive.length ? new DatabaseQueryCondition('==', abrasive.trim()) : undefined,
			calidad: series.length ? new DatabaseQueryCondition('==', series.trim()) : undefined,
			[this.currentMainGroup === 'Accesorios' ? 'dureza' : 'grano']: corn.length ? new DatabaseQueryCondition('==', corn.trim()) : undefined,
			medidas: size.length ? new DatabaseQueryCondition('==', size.trim()) : undefined,
			solo_vitex: this.isCustomerOrPartner ? new DatabaseQueryCondition('==', 'NO') : undefined,
		};
	}

	/**
	 * Combines tipos and abrasivos for all main groups that are not BES, BAN or ROL. The separator used is '/'.
	 * This is crucial for splitting back into tipo and abrasivo later on.
	 */
	private combineTypeAndAbrasive(types): string[] {
		let trimmedTypes = types.map((item: string) => item.trim());
		let ret = [];
		trimmedTypes = trimmedTypes.filter((value: string, index: number, self: string[]) => self.indexOf(value) === index && value);

		trimmedTypes.forEach((type: string) => {
			let matchingAbrasives: string[] = this.currentFile.select('abrasivo').where({
				'tipo': new DatabaseQueryCondition('==', type),
			}).execute();

			matchingAbrasives = this.removeDuplicates(matchingAbrasives, 0);
			matchingAbrasives = matchingAbrasives.filter((item: string) => item); // remove empty strings

			if (!matchingAbrasives.length) {
				ret.push(type);
			}

			for (let abrasive of matchingAbrasives) {
				ret.push(type + '/' + abrasive);
			}
		});

		return ret;
	}

	/**
	 * Gets executed on text-input onChange. Determines the current error message and automatically sets the inputs model
	 * if the given value exceeds the maximum allowed value.
	 *
	 * If no error message has been found, we assume that the input is correct and return ''.
	 */
	public checkWidthRange(): DimensionValidationResponse {
		const maxWidth: string = this.files.producto.select('anchostd').where({
			'abrasivo': new DatabaseQueryCondition('%', this.calculationForm.abrasive),
			'calidad': new DatabaseQueryCondition('%', this.calculationForm.series),
			'grano': new DatabaseQueryCondition('%', this.calculationForm.corn),
		}).execute()[0][0];

		if (this.calculationForm.group == 'BES' && +this.calculationForm.width > 600) {
			return {
				error: true,
				message: 'El ancho de las bandas estrechas debe ser menor de 600 mm.',
				dimensions: {
					maxWidth: 600
				}
			};
		}

		if (this.calculationForm.group == 'BAN' && +this.calculationForm.width < 600) {
			return {
				error: true,
				message: 'El ancho de las bandas anchas debe ser mayor o igual a 600 mm.',
				dimensions: {
					minWidth: 600
				}
			};
		}

		if (this.calculationForm.group == 'ROL') {
			this.calculationForm.maxWidth = maxWidth;

			if (+this.calculationForm.width > +maxWidth) {
				return {
					error: true,
					message: `El ancho no puede superar los ${maxWidth}mm del ancho bobina.`,
					dimensions: {
						maxWidth: +maxWidth
					}
				};
			}
		}

		return {error: false};
	}

	/**
	 * Gets executed on text-input onChange. Determines the current error message and automatically sets the inputs model
	 * if the given value exceeds the maximum allowed value.
	 *
	 * If no error message has been found, we assume that the input is correct and return ''
	 */
	public checkLengthRange(): DimensionValidationResponse {
		if (+this.calculationForm.length > 50000) {
			return {
				error: true,
				message: 'El largo no puede superar 50000 mm.',
				dimensions: {
					maxLength: 50000
				}
			};
		}

		if ((this.calculationForm.group == 'BAN' || this.calculationForm.group == 'BES') && +this.calculationForm.length < 700) {
			return {
				error: true,
				message: 'Dirigirse a categoria Banda Portatil',
				dimensions: {
					minLength: 700
				}
			};
		}

		return {error: false};
	}

	/**
	 * Combines both width range check and length range check and returns any error message if one of the two methods
	 * returns one.
	 */
	public checkBothRanges(): DimensionValidationResponse {
		return [this.checkWidthRange(), this.checkLengthRange()].find(el => el.error) ?? {error: false};
	}

	/**
	 * Gets executed on mousedown. Restrict any user input to numbers. Allow certain modifier keys and deletion as well.
	 */
	public checkIfValidInput(event: KeyboardEvent): boolean {
		return event.key >= '0' && event.key <= '9' || event.key === 'Backspace' || event.key === 'Tab' || event.ctrlKey;
	}

	/**
	 * Calculate net price on calculate-button click
	 */
	public getNetPrice(): void {
		const whereConditions = !this.isProduct() && this.calculationForm.abrasive.indexOf('/') !== -1
				? this.buildWhereConditionCombinedAbrasive()
				: this.buildWhereCondition();

		const result = this.currentFile.select('precio').where(whereConditions).execute();
		this.calculationForm.reference = this.isProduct()
				? this.getComposedReference(whereConditions)
				: this.getSimpleReference(whereConditions);

		const unitsPerPackaging = this.currentFile.select('embalaje').where(whereConditions).execute()[0];
		if (typeof unitsPerPackaging === 'undefined') {
			this.calculationForm.unitsPerPackaging = '';
		} else {
			this.calculationForm.unitsPerPackaging = unitsPerPackaging[0];
		}

		const minimumOrderQuantity = this.currentFile.select('MOQ').where(whereConditions).execute()[0];
		if (typeof minimumOrderQuantity === 'undefined') {
			this.calculationForm.minimumOrderQuantity = '';
		} else {
			this.calculationForm.minimumOrderQuantity = minimumOrderQuantity[0];
		}

		const stock = this.currentFile.select('Stock').where(whereConditions).execute()[0];
		if (typeof stock === 'undefined') {
			this.calculationForm.stock = '';
		} else {
			this.calculationForm.stock = stock[0];
		}
		const r = result[0];
		// For all groups that only use medidas for size configuration, simply query the price without further calculations.
		if (typeof r !== 'undefined') {
			this.calculationForm.netPrice = this.isProduct()
					? this.factorInCoefficient(this.calculationForm, PriceCalculatorEsService.convertPriceToNumber(r[0]))
					: PriceCalculatorEsService.convertPriceToNumber(r[0]);
		} else {
			this.calculationForm.netPrice = 0;
		}
		this.calculationForm.netPriceWithDiscount = this.calculateDiscountedPrice();
	}

	public initFromProduct(product: ProductEs.Base) {
		for (const key in product) {
			this.calculationForm[key] = product[key];
		}

		this.selectMainGroup(mainGroupShortToLongMap[product.group]);
	}

	public isBelt(group: MainGroupShort): boolean {
		const belts: MainGroupShort[] = ['BAN', 'BES', 'ROL'];
		return belts.includes(group);
	}

	/**
	 * Simple Query of PF file's reference value.
	 */
	private getSimpleReference(where): string | undefined | null {
		return this.currentFile.select('referencia').where(where).execute()?.[0]?.[0];
	}

	/**
	 * Compose the reference for any ProductoPF product (BAN, BES, or ROL) as the reference is not given for these
	 *   products.
	 *
	 * Note: familia gets composed via main group and Tela/Papel. We bind those two results to the number values found in
	 * familia object literal. Grano gets used as is with additional zero padding to guarantee a character length of 3.
	 */
	private getComposedReference(where: WhereConditionsEs): string {
		const familia = {
			BAN: {
				K: 72,
				V: 72,
				P: 58,
			},
			BES: {
				K: 54,
				V: 54,
				P: 51,
			},
			ROL: {
				K: 47,
				V: 47,
				P: 44,
			},
		};

		const selectedProduct = this.currentFile.select([
			'Tela/Papel',
			'Serieref',
			'grano',
			'Empalme',
		]).where(where).execute()[0];
		if (typeof selectedProduct === 'undefined') {
			return '';
		}
		const [telaPapel, serieref, grano, empalme] = selectedProduct;

		if (!familia[this.calculationForm.group][telaPapel] || !serieref || !grano || !empalme) {
			return '';
		}

		return familia[this.calculationForm.group][telaPapel.trim()] + serieref + PriceCalculatorEsService.zeroPadding(grano, 3) + empalme;
	}

	/**
	 * Calculate discount.
	 */
	private calculateDiscountedPrice(): number {
		const discountInPercent = 1 - +this.calculationForm.discount / 100;
		return +this.calculationForm.netPrice * discountInPercent;
	}

	private static convertPriceToNumber(str: string): number {
		return +str.replace('€', '').replace('.', '').replace(',', '.').trim();
	}

	/**
	 * Reset all values that get computed on 'calculate'-button click.
	 */
	public resetResultFields() {
		this.calculationForm.netPrice = 0;
		this.calculationForm.netPriceWithDiscount = 0;
		this.calculationForm.unitsPerPackaging = '';
		this.calculationForm.minimumOrderQuantity = '';
		this.calculationForm.stock = '';
		this.calculationForm.reference = '';
	}

	/**
	 * Determine which formula should be used for the given main group.
	 */
	public factorInCoefficient(form: PriceCalculatorEs, unitPrice: number): number {
		return form.group === 'BES' ? this.calculateForBes(form, unitPrice) : this.calculateForBanAndRol(form, unitPrice);
	}

	/**
	 * Calculate method specifically for BAN and ROL
	 */
	private calculateForBanAndRol({group, width, length, maxWidth}: PriceCalculatorEs, unitPrice: number): number {
		const map = {
			BAN: 'banda ancha',
			ROL: 'rollos',
		};

		const coefficients = ['c1', 'c2'].map((item: string) => {
			return this.files.coeficientes.select('valor').where({
				'parametro': new DatabaseQueryCondition('==', `${map[group]} ${item}`),
			}).execute();
		});

		const [c1, c2] = coefficients.map((item: string) => +item[0][0].replace(',', '.').trim());

		return group === 'ROL'
				? (unitPrice * +width * +length * c1 / 1000000) + (+length / ((+maxWidth / +width) * c2))
				: (unitPrice * +width * +length * c1 / 1000000) + (+width * c2);
	}

	/**
	 * Calculate specifically for BES.
	 */
	private calculateForBes({width, length, batch}: PriceCalculatorEs, unitPrice: number): number {
		const besC1: string[][] = this.files.coeficientes.select('valor').where({
			'parametro': new DatabaseQueryCondition('==', 'banda estrecha c1'),
		}).execute();

		const batches = ['c1', 'c2', 'c3'].map((item: string) => {
			return this.files.coeficientes.select('valor').where({
				'parametro': new DatabaseQueryCondition('==', `estrecha lote ${item}`),
			}).execute();
		});

		const fixCoefficient: number = +besC1[0][0].replace(',', '.').trim();
		const batchCoefficients: number[] = batches.map((item: string[][]) => +item[0][0].replace(',', '.').trim());

		return (unitPrice * +width * +length * fixCoefficient / 1000000) + (+width * batchCoefficients[+batch]);
	}

	/**
	 * Add zero padding to a given number or string where the length is dependent on the size parameter.
	 */
	private static zeroPadding(num: number | string, size: number): string {
		const convertToNum = +num; // remove any zero padding by type casting to a number
		let numAsString = `${convertToNum}`; // convert back to string

		while (numAsString.length < size) {
			numAsString = `0${numAsString}`;
		}

		return numAsString;
	}

	/**
	 * helper method to easily determine if the current product is of the storage file "ProductoPF.csv"
	 * (BAN, BES or ROL)
	 */
	private isProduct(): boolean {
		const {group} = this.calculationForm;
		return group === 'BES' || group === 'ROL' || group === 'BAN';
	}
}

export type DimensionValidationResponse = {
	error: false
} | {
	error: true,
	message: string,
	dimensions: {
		minWidth?: number,
		maxWidth?: number,
		minLength?: number,
		maxLength?: number
	}
};
