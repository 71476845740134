import { Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root',
})
export class ConfirmService {
	public show: boolean = false;
	public message: string;
	public button: string;
	public resolver: (value: boolean) => void;
	public param: any;

	public async confirm(message: string, button: string = 'Löschen', param = {}): Promise<boolean> {
		this.param = param;
		this.message = message;
		this.button = button;
		this.show = true;
		return new Promise<boolean>((resolve) => {
			this.resolver = resolve;
		});
	}

	/**
	 * Show items on accepted state
	 */
	public accept() {
		this.resolver(true);
		this.show = false;
	}

	/**
	 * Hide items on denied state
	 */
	public deny() {
		this.resolver(false);
		this.show = false;
	}
}
