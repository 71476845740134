import { NgModule } from '@angular/core';
import { ListComponent } from './pages/list/list.component';
import { DownloadAreaRoutingModule } from './download-area-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '../app.module';
import { LayoutModule } from '../layout/layout.module';
import { DownloadAreaService } from './download-area.service';
import { DownloadCategoriesResolver } from './download-categories.resolver';
import { CommonModule } from '@angular/common';
import { DownloadDocumentResolver } from './download-document.resolver';
import { FileSizePipe } from './size-to-text.pipe';
import { EditCategoryComponent } from './modals/edit-category/edit-category.component';
import { DeleteCategoryComponent } from './modals/delete-category/delete-category.component';
import { AddCategoryComponent } from './modals/add-category/add-category.component';
import { AddDocumentComponent } from './modals/add-document/add-document.component';
import { DeleteDocumentComponent } from './modals/delete-document/delete-document.component';
import { EditDocumentComponent } from './modals/edit-document/edit-document.component';
import { FormsModule } from '@angular/forms';
import { FormModule } from '../form/form.module';
import { FilterByLanguage } from './filter-by-language.pipe';
import {GtagModule} from "angular-gtag";
import {SimplebarAngularModule} from "simplebar-angular";

@NgModule({
	declarations: [
		ListComponent,
		FileSizePipe,
		EditCategoryComponent,
		DeleteCategoryComponent,
		AddCategoryComponent,
		AddDocumentComponent,
		DeleteDocumentComponent,
		EditDocumentComponent,
		FilterByLanguage
	],
    imports: [
        FormModule,
        LayoutModule,
        CommonModule,
        DownloadAreaRoutingModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GtagModule,
        SimplebarAngularModule,
    ],
	providers: [
		DownloadAreaService,
		DownloadCategoriesResolver,
		DownloadDocumentResolver
	]
})
export class DownloadAreaModule {
}
