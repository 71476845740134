import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root',
})
export class CryptoService {
	/**
	 * decrypts a string with AES-256
	 */
	public decrypt(string: string, passphrase: string): string {
		return CryptoJS.AES.decrypt(string, passphrase).toString(CryptoJS.enc.Utf8);
	}
}
