import { Injectable } from '@angular/core';
import { Card } from '../card/card';
import { UserService } from '../authorization/user/user.service';


@Injectable({
	providedIn: 'root',
})
export class DashboardService {

	constructor(private readonly userService: UserService) {
	}

	/**
	 * This method currently returns mocked data that is displayed as a card on the dashboard
	 */
	public async getDashboardElements(): Promise<Card[]> {
		const ret = [];

		if (await this.userService.isGerman()) {
			ret.push({
				id: 1,
				title: 'Artikel-Preisberechnung',
				subTitle: 'Bruttopreisinformationen zu einem Artikel Ihrer Wahl aus dem zulässigen Sortiment.',
				creationDate: 0,
				icon: 'icon-rechner',
				linkTarget: '/price-calculator',
				linkText: 'Bruttolistenpreis ermitteln'
			});
		}

		if (await this.userService.isSpanish()) {
			ret.push({
				id: 1,
				title: 'Preisberechnung ES',
				subTitle: 'Bruttopreisinformationen zu einem Artikel Ihrer Wahl aus dem zulässigen Sortiment.',
				creationDate: 0,
				icon: 'icon-rechner',
				linkTarget: '/price-calculator-es',
				linkText: 'Bruttolistenpreis ermitteln'
			});
		}

		return ret as Card[];
	}
}
