import { Injectable } from "@angular/core";
import { PASSWORD } from "../../generic-types";
import { CachedAjaxService } from "../../services/cached-ajax.service";
import { AUTH_TOKEN, AuthenticationService } from "../services/authentication.service";
import { User } from "./user";
import { NATIONALITY_CHINESE, NATIONALITY_GERMAN, NATIONALITY_SPANISH } from "./user-nationality/user-nationality";

@Injectable({
	providedIn: "root",
})
export class UserService {
	/**
	 * The user cached to prevent the api from making unnecessary requests
	 */
	private user: User = null;

	constructor(
		private readonly authenticationService: AuthenticationService,
		private readonly cachedAjaxService: CachedAjaxService
	) {}

	/**
	 * Deletes the passed user.
	 * Calls the "remove user object" function of the wiki (https://gitlab.wendweb.de/agentur/websites/vsmabrasives/vsm-pisa-angular-6/wikis/API-Documentation)
	 */
	public async deleteUser(user: User): Promise<void> {
		const { success } = await this.cachedAjaxService.fetchRequest({
			url: `user/${user.id}`,
			method: "DELETE",
		});
		this.cachedAjaxService.removeCacheItem("users", "GET");
		if (!success) {
			throw new Error("User could not been deleted.");
		}
	}

	/**
	 * Updates user based by the data passed.
	 * Calls the "update user object" function of the wiki (https://gitlab.wendweb.de/agentur/websites/vsmabrasives/vsm-pisa-angular-6/wikis/API-Documentation)
	 */
	public async updateUser(user: User): Promise<void> {
		const { success } = await this.cachedAjaxService.fetchRequest({
			url: `user/${user.id}`,
			body: user,
			method: "PUT",
		});
		this.cachedAjaxService.removeCacheItem("users", "GET");
		if (!success) {
			throw new Error("User could not been updated.");
		}
	}

	/**
	 * Gets a user based by the passed id.
	 * Calls the "Get user object by id" function of the wiki (https://gitlab.wendweb.de/agentur/websites/vsmabrasives/vsm-pisa-angular-6/wikis/API-Documentation)
	 */
	public async getUser(id: number): Promise<User> {
		const { success, user, code } = await this.cachedAjaxService.fetchRequest({
			url: `user/${id}`,
		});
		if (!success) {
			throw code;
		}
		return user;
	}

	/**
	 * Adds a new user based by the data passed.
	 * Calls the "Create new user object" function of the wiki (https://gitlab.wendweb.de/agentur/websites/vsmabrasives/vsm-pisa-angular-6/wikis/API-Documentation)
	 */
	public async addUser(user: User): Promise<void> {
		const { success } = await this.cachedAjaxService.fetchRequest({
			url: "user",
			body: user,
			method: "POST",
		});
		this.cachedAjaxService.removeCacheItem("users", "GET");
		if (!success) {
			throw new Error("User could not been updated.");
		}
	}

	/**
	 * Returns all Users
	 * Calls the "Get all user objects" function of the wiki (https://gitlab.wendweb.de/agentur/websites/vsmabrasives/vsm-pisa-angular-6/wikis/API-Documentation)
	 */
	public async getAllUsers(): Promise<User[]> {
		const { success, users, code } = await this.cachedAjaxService.fetchRequest({
			url: "users",
			cached: true,
		});
		if (!success) {
			throw code;
		}
		return users.sort(function (a: User, b: User) {
			return `${a.customerNumber}`.localeCompare(b.customerNumber);
		});
	}

	/**
	 * Returns the current user from the api.
	 * Calls the "Get own user object" function of the wiki (https://gitlab.wendweb.de/agentur/websites/vsmabrasives/vsm-pisa-angular-6/wikis/API-Documentation)
	 */
	public async getCurrentUser(): Promise<User> {
		if (this.user !== null) {
			return this.user;
		}
		const user = await this.cachedAjaxService.fetchRequest({
			url: "me",
			cached: true,
		});
		if (user?.id) {
			this.user = user;
		}
		return this.user;
	}

	/**
	 * Calls a service which then sends a mail to the user with a link to reset his password
	 */
	public async sendResetPasswordMail(userMail): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: "user/pw-reset/mail",
			body: { email: userMail },
			method: "POST",
		});
	}

	/**
	 * Sends a request to an api endpoint which sets the password of the user based by the passed token
	 */
	public async resetPassword(password: PASSWORD, token: AUTH_TOKEN): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: "user/pw-reset",
			body: {
				password: password,
				token: token,
			},
			method: "POST",
		});
	}

	/**
	 * Logs the user out by removing the auth token
	 */
	public async logout(): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: "user/logout",
			method: "DELETE",
		});
		this.cachedAjaxService.removeCacheItem("me", "GET");
		window.localStorage.removeItem("me");
		await this.authenticationService.removeAuthToken();
		this.user = null;
	}

	/**
	 * Logs the user in. Stores the authentication token in the local storage
	 */
	public async authenticate(mail, password): Promise<boolean> {
		const response = await this.cachedAjaxService.fetchRequest({
			url: "user/authenticate",
			body: {
				email: mail,
				password: password,
			},
			method: "POST",
		});
		if (!response.success || !response["auth-token"]) {
			throw new Error("Login failed");
		}
		await this.authenticationService.setAuthToken(response["auth-token"]);
		return true;
	}

	/**
	 * Check if the current User has spanish as one of its nationalities
	 */
	public async isSpanish(): Promise<boolean> {
		const user = await this.getCurrentUser();
		return user.nationalities.some((item) => item.id === NATIONALITY_SPANISH);
	}

	/**
	 * Check if the current User has german as one of its nationalities.
	 */
	public async isGerman(): Promise<boolean> {
		const user = await this.getCurrentUser();
		return user.nationalities.some((item) => item.id === NATIONALITY_GERMAN);
	}

	/**
	 * Check if the current User has chinese as one of its nationalities.
	 */
	public async isChinese(): Promise<boolean> {
		const user = await this.getCurrentUser();
		return user.nationalities.some((item) => item.id === NATIONALITY_CHINESE);
	}
}
