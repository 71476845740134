import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class LocalSettingsService {

	public languageData = new BehaviorSubject<any[]>([]);

	constructor(protected readonly localStorage: LocalStorage) {
	}

	/**
	 * Get all language options
	 */
	public getLanguage(): Observable<unknown> {
		return this.localStorage.getItem('language');
	}

	/**
	 * Set language from select
	 */
	public setLanguage(language: string): void {
		this.languageData.next([...this.languageData.getValue(), language]);
		this.localStorage.setItem('language', language).subscribe(() => {
		});
	}
}
