import { Injectable } from '@angular/core';
import { AuthGuard } from './auth-guard.service';

/**
 * This guard prevents authenticated users to access a route that is protected by this guard
 */
@Injectable()
export class NotAuthGuard extends AuthGuard {
	public async checkLogin(): Promise<boolean> {
		try {
			await this.userService.getCurrentUser();
			await this.router.navigate(['/home']);
			return false;
		} catch (e) {
			return true;
		}
	}
}
