import { OnInit, Component } from '@angular/core';
import { Location } from '@angular/common';
import { User } from './user';
import { LanguageService } from '../../layout/language/language.service';
import { UserGroupService } from './user-group/user-group.service';
import { UserGroup } from './user-group/user-group';
import { UserNationalityService } from './user-nationality/user-nationality.service';
import { UserNationality } from './user-nationality/user-nationality';

export const USER_FORM_MODE_EDIT = 1;
export const USER_FORM_MODE_ADD = 2;

@Component({
	selector: 'abstract-user-form-component',
	template: 'component only used for imports',
})
export class AbstractUserFormComponent implements OnInit {

	public mode: number = 1;
	public user: User = new User();
	public editMode: number = USER_FORM_MODE_EDIT;
	public addMode: number = USER_FORM_MODE_ADD;
	public userGroups: UserGroup[] = [];
	public languages: any[] = [];
	public validationCheck: boolean = null;
	public nationalities: UserNationality[];

	constructor(
			protected readonly location: Location,
			protected readonly languageService: LanguageService,
			protected readonly userGroupService: UserGroupService,
			protected readonly userNationalityService: UserNationalityService
	) {
	}

	/**
	 * back button functionality
	 */
	public back(): void {
		this.location.back();
	}

	public async ngOnInit(): Promise<void> {
		this.userGroups = await this.userGroupService.getAllGroupsForSelect();
		this.nationalities = await this.userNationalityService.getAllNationalitiesForCheckboxes();
		this.languages = await this.languageService.getLanguagesForSelect();
	}

	/**
	 * Adding form validation
	 */
	protected formValid(): boolean {
		this.validationCheck = this.user.name !== '' && this.user.name !== undefined && this.user.customerNumber !== '' && this.user.customerNumber !== undefined && this.user.email !== '' && this.user.email !== undefined;
		setTimeout(function () {
			const element = document.querySelectorAll('.is-filled input.ng-invalid.ng-pristine, .is-filled input.ng-invalid.ng-untouched')[0] as HTMLElement;
			if (element !== undefined && typeof element.focus === 'function') {
				element.focus();
			}
		}, 100);

		return this.validationCheck;
	}

	public updateNationalities(event: any, nationality: UserNationality): void {
		if (event.target.checked) {
			// Fix for the case: the user.nationalities array is empty but german checkbox is checked in frontend (default) and then spanish checkbox is checked by the user
			if (nationality.id == 2 && this.user.nationalities.length === 0 && document.getElementsByClassName('check-1')['Deutscher Preisrechner'].checked) {
				this.user.nationalities.push({id: 1, name: 'Deutscher Preisrechner'});
			}
			this.user.nationalities.push(nationality);
		} else {
			this.user.nationalities = this.user.nationalities.filter(item => item.id !== nationality.id);
		}
	}
}
