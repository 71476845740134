import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * @description Arbitrary data to pass to the dialog
 */
export type Payload<T = any> = { data: T };

@Component({
	selector: 'app-generic-dialog',
	templateUrl: './generic-dialog.component.html',
	styleUrls: ['./generic-dialog.scss']
})
export class GenericDialogComponent {
	@Input() public headline: string;
	@Input() public withActions = true;
	@Output() public confirm = new EventEmitter<Payload>();
	@Output() public dismiss = new EventEmitter<Payload>();

	public isOpen = false;
	private payload?: Payload;

	public open(payload?: Payload) {
		this.payload = payload;
		this.isOpen = true;
	}

	public close(): Payload | null {
		this.isOpen = false;
		return this.payload;
	}

	public onDismiss(): void {
		this.dismiss.emit(this.payload);
		this.isOpen = false;
	}

	public onConfirm(): void {
		this.confirm.emit(this.payload);
		this.isOpen = false;
	}
}
