import { Component, OnInit } from '@angular/core';
import { User } from './user';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { TableData, TableLabel } from '../../layout/table/table.component';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
	/**
	 * The table data that is passed to the table component.
	 */
	public tableData: TableData = [];

	constructor(
			private readonly route: ActivatedRoute,
			private readonly translate: TranslateService
	) {
	}

	/**
	 * Initialize the component based by the resolved user
	 */
	public ngOnInit(): void {
		this.route.data.subscribe(({user}) => this.createTableContent(user));
	}

	/**
	 * Creates the table label
	 */
	private static createTableLabel(text: string, secondary: string = ''): TableLabel {
		return {
			text: text,
			secondary: secondary,
			bold: true
		};
	}

	/**
	 * Creates the table content
	 */
	private createTableContent(user: User): void {
		forkJoin([
			this.translate.get('Nutzername'),
			this.translate.get('Nutzergruppe'),
			this.translate.get('Kundennummer'),
			this.translate.get('Land'),
			this.translate.get('Sprache'),
			this.translate.get('E-Mail'),
			this.translate.get('Nutzer'),
			this.translate.get('Innendienst'),
			this.translate.get('Außendienst'),
		]).subscribe(translations => {
			this.tableData = [
				[UserComponent.createTableLabel(translations[0]), {text: user.name}],
				[UserComponent.createTableLabel(translations[1]), {text: user.userGroup.title}],
				[UserComponent.createTableLabel(translations[2]), {text: user.customerNumber}],
				[UserComponent.createTableLabel(translations[3]), {text: user.country}],
				[UserComponent.createTableLabel(translations[4]), {text: user.language.long}],
				[UserComponent.createTableLabel(translations[5], `(${translations[6]})`), {text: user.email}],
				[UserComponent.createTableLabel(translations[5], `(${translations[7]})`), {text: user.emailOfficeStaff}],
				[UserComponent.createTableLabel(translations[5], `(${translations[8]})`), {text: user.emailOutdoorStaff}]
			];
		});
	}
}
