/**
 * A Helper class that provides the ability to create a query condition.
 */
export class DatabaseQueryCondition {
	constructor(
			private readonly operand,
			private readonly localValue
	) {
	}

	/**
	 * Starts the comparison of the configured value against the passed value based by the operand that was passed by object creation.
	 */
	public compare(comparedWithValue: any): boolean {
		if (typeof comparedWithValue === 'undefined') {
			return false;
		}
		const trimmedString = comparedWithValue.trim();
		const trimmedNumber = +trimmedString.replace(',', '.');
		switch (this.operand) {
			case '==':
				return `${this.localValue}`.toLowerCase() == trimmedString.toLowerCase();
			case '!=':
				return this.localValue != trimmedString;
			case '===':
				return this.localValue === trimmedString;
			case '%': // Like comparison. Ignores the length of the word in the database
				return DatabaseQueryCondition.staticCompareLike(trimmedString, this.localValue);
			case '%l': // Like comparison. Checks for equals length of the word in the database
				return DatabaseQueryCondition.staticCompareLike(trimmedString, this.localValue, true);
			case '%[]':
				return this.compLikeArray(trimmedString);
			case '>=':
				return trimmedNumber >= +this.localValue;
			case '<=':
				return trimmedNumber <= +this.localValue;
			case '<':
				return trimmedNumber < +this.localValue;
			case '>':
				return trimmedNumber > +this.localValue;
		}
		return false;
	}

	/**
	 * Basically check whether the passed value is either a '?' or a '*'
	 */
	public static isAsterisk(val: string): boolean {
		return val === '*' || val === '?';
	}

	/**
	 * Compares the passed values against each other with the possibility to use asterisk ('*' and '?' are both asterisk)
	 */
	public static staticCompareLike(comparedWithValue: any, localValue: any, exactLength: boolean = false): boolean {
		//If either of the values is not a string (e.g. undefined) then the comparison is already falsy
		if (typeof comparedWithValue !== 'string' || typeof localValue !== 'string') {
			return false;
		}
		if (exactLength && comparedWithValue.length !== localValue.length) {
			return false;
		}
		for (let i = 0; i < comparedWithValue.length; i++) {
			//either one of the letters is an asterisk
			if (DatabaseQueryCondition.isAsterisk(localValue[i]) || DatabaseQueryCondition.isAsterisk(comparedWithValue[i])) {
				continue;
			}
			//Passed word is smaller than db word (and letter is asterisk)
			if (typeof localValue[i] === 'undefined' && DatabaseQueryCondition.isAsterisk(comparedWithValue[i])) {
				continue;
			}
			//If just a single comparison failed, the whole comparison is falsy
			if (comparedWithValue[i] !== localValue[i]) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Basically calls the staticCompareLike method for all this.localValue elements
	 * (this comparison assumes that the configured value is an array)
	 */
	private compLikeArray(val: string): boolean {
		for (let i = 0; i < this.localValue.length; i++) {
			if (DatabaseQueryCondition.staticCompareLike(val.toLowerCase(), this.localValue[i].toLowerCase())) {
				return true;
			}
		}
		return false;
	}
}
