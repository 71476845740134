import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { Language } from '../language';
import { TranslateService } from '@ngx-translate/core';
import { LocalSettingsService } from '../language-settings.service';

@Component({
	selector: 'app-language-switch-header',
	templateUrl: './switch-header.component.html',
	styleUrls: ['./switch-header.component.scss']
})
export class SwitchHeaderComponent implements OnInit {
	public languages: Language[] = [];
	public languageMenuOpen = false;

	constructor(
			private readonly languageService: LanguageService,
			private readonly languageSettings: LocalSettingsService,
			public readonly translate: TranslateService
	) {
	}

	public async ngOnInit(): Promise<void> {
		this.languages = await this.languageService.getAllLanguages();
		this.sortLanguages();
	}

	/**
	 * re-initializes the order of the language list and then moves the selected language to the top of the list
	 */
	private sortLanguages(): void {
		this.languages.sort((a, b) => {
			if (a.short.localeCompare(b.short) < 1) {
				return -1;
			}
			if (a.short.localeCompare(b.short) > 1) {
				return 1;
			}
			return 0;
		});

		this.languages.sort((a, b) => {
			if (a.iso === this.translate.currentLang) {
				return -1;
			}
			if (b.iso === this.translate.currentLang) {
				return 1;
			}

			return 0;
		});
	}

	/**
	 * @description
	 * Switches to the selected language, closes the language menu and sort the languages that the selected language is on the top
	 * @param language
	 */
	public selectLanguage(language: string): void {
		this.languageMenuOpen = false;
		this.translate.use(language);
		this.translate.currentLang = language;
		this.languageSettings.setLanguage(language);
		this.sortLanguages();
	}
}
