import { ProductEs } from './product-es';
import { ReverseMap } from '../utils';

export const filesMap = {
	'Accesorios': 'accessorios',
	'Banda Ancha': 'anchas',
	'Banda Estrecha': 'estrechas',
	'Banda Portatil': 'bportatiles',
	'Cepillos': 'cepillos',
	'Discos': 'discos',
	'Hojas': 'hojas',
	'Rollos': 'rollos',
	'Rollos Estandar': 'rolloStandard',
	'Anillos reforzados': 'anillos',
} as const;

export const mainGroupLongToShortMap = {
	'Accesorios': 'ACC',
	'Banda Ancha': 'BAN',
	'Banda Estrecha': 'BES',
	'Banda Portatil': 'BPO',
	'Cepillos': 'CEP',
	'Discos': 'DIS',
	'Hojas': 'HOJ',
	'Rollos': 'ROL',
	'Rollos Estandar': 'RES',
	'Anillos reforzados': 'ARF',
} as const;

export const mainGroupShortToLongMap = Object
		.entries(mainGroupLongToShortMap)
		.reduce((prev, [key, value]) => ({
			...prev,
			[value]: key
		}), {} as ReverseMap<typeof mainGroupLongToShortMap>);

export type MainGroup = keyof typeof mainGroupLongToShortMap;
export type MainGroupShort = typeof mainGroupLongToShortMap[MainGroup] | '';

/**
 * This class controls the whole price calculation process.
 * PriceCalculatorEs holds any calculation relevant data to be processed at a later point in its lifetime.
 * PriceCalculatorEs should not hold any business logic and only serves to temporarily store the necessary data.
 * Solely a reset method is provided and can be executed to clear any stored data.
 */
export class PriceCalculatorEs implements ProductEs.Base {
	public width: string;
	public length: string;
	public largo:string;
	public maxWidth: string;
	public discount: string;
	public group: MainGroupShort;
	public netPrice: number;//Price in cents
	public netPriceWithDiscount: number;
	public batch: string;
	public maquina: string;
	public abrasive: string;
	public format: string;
	public type: string;
	public hardness: string;
	public series: string;
	public units: string;
	public size: string;
	public corn: string;
	public reference: string;
	public unitsPerPackaging: string;
	public minimumOrderQuantity: string;
	public stock: string;
	public formato: string;
	public extDiameter: string | number;
	public intDiameter: string | number;
	public tipo: string;
	public alto: string;

	constructor() {
		this.reset();
	}

	public reset() {
		this.width = '';
		this.maxWidth = '';
		this.length = '';
		this.largo = '';
		this.discount = '0';
		this.group = '';
		this.netPrice = 0;
		this.netPriceWithDiscount = 0;
		this.batch = '';
		this.abrasive = '';
		this.format = '';
		this.type = '';
		this.hardness = '';
		this.series = '';
		this.units = '';
		this.size = '';
		this.corn = '';
		this.maquina = '';
	}

	public resetSpecificParams(): void {
		this.extDiameter= '';
		this.intDiameter= '';
		this.abrasive= '';
		this.alto= '';
		this.width= '';
		this.length= '';
		this.corn= '';
	}

	public setGroup(group: MainGroupShort) {
		this.discount = ''; // reset discount form-input
		this.group = group ? group.toUpperCase() as MainGroupShort : '';
	}
}
