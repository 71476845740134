import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'abs',
	pure: true,
})
export class AbsPipe implements PipeTransform {
	/**
	 * Format a price to a string with 2 decimals.
	 */
	public transform(value: number): number {
		return Math.abs(value);
	}
}
