import { Component, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { LayoutService } from './layout.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterViewInit {
	public connectionLost: boolean = !navigator.onLine;

	@Input()
	public type: string = 'default';

	@ViewChild('scrollTarget')
	public scrollTarget: ElementRef;

	constructor(private readonly layoutService: LayoutService) {
	}

	public ngAfterViewInit(): void {
		this.layoutService.scrollTarget = this.scrollTarget.nativeElement;
	}

	public onWrapperScroll($event: Event): void {
		this.layoutService.scrollSubject.next($event);
	}
}
