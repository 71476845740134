import { inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { combineLatest } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { ProductService } from "../generic-product-table/product.service";
import { MainGroup, mainGroupLongToShortMap } from "../price-calculator-es/price-calculator-es";
import { PriceCalculatorEsService } from "../price-calculator-es/price-calculator-es.service";
import { ProductEs } from "../price-calculator-es/product-es";
import { WishlistEs, WishlistEsService } from "./wishlist-es.service";

export abstract class WishlistEsDetailBase {
	protected readonly priceCalculatorEsService = inject(PriceCalculatorEsService);
	protected readonly wishlistEsService = inject(WishlistEsService);
	private readonly route = inject(ActivatedRoute);
	private readonly productService = inject(ProductService);

	wishlist$ = combineLatest([
		this.priceCalculatorEsService.filesInitialized$,
		this.route.params,
		this.wishlistEsService.wishlists$,
	]).pipe(
		filter(([isInitialized]) => isInitialized),
		map(([_, params]) => params.id),
		switchMap((id) => this.wishlistEsService.getOne(+id)),
		map((wishlist) => {
			const products = wishlist.products.map((product) => {
				this.priceCalculatorEsService.resetAllSelectOptions();
				this.priceCalculatorEsService.initFromProduct(product);
				this.priceCalculatorEsService.getNetPrice();
				return (window as any).structuredClone(this.priceCalculatorEsService.calculationForm);
			});

			return {
				...wishlist,
				products: products,
			} as WishlistEs<ProductEs.WithResult[]>;
		})
	);

	groups = this.productService.getGroupsEs();

	public getProductsByGroup(wishlist: WishlistEs<ProductEs.WithResult[]>, group: MainGroup): ProductEs.WithResult[] {
		return wishlist.products.filter((p) => p.group === mainGroupLongToShortMap[group]);
	}
}
