import { Directive, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[afterViewInit]'
})
export class AfterViewInitDirective implements AfterViewInit {

	@Output()
	afterViewInit = new EventEmitter();

	public ngAfterViewInit(): void {
		this.afterViewInit.emit();
	}
}
