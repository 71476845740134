import { User } from './user';
import { Resolve } from '@angular/router';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';

@Injectable()
export class UserResolver implements Resolve<User> {

	constructor(private readonly userService: UserService) {
	}

	/**
	 * resolves the user
	 */
	public resolve(): Promise<User> {
		return this.userService.getCurrentUser();
	}
}
