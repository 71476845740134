import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LanguageService } from '../layout/language/language.service';
import { Language } from '../layout/language/language';
import { LocalSettingsService } from '../layout/language/language-settings.service';

@Component({
	selector: 'app-language-switch-page',
	templateUrl: './switch-page.component.html'
})
export class SwitchPageComponent implements OnInit {
	public language: string = 'en';
	public languages: Language[] = [];
	public options: any[] = [];

	constructor(
			private readonly translate: TranslateService,
			private readonly router: Router,
			private readonly languageService: LanguageService,
			private readonly languageSettings: LocalSettingsService
	) {
	}

	public async ngOnInit(): Promise<void> {
		this.languages = await this.languageService.getAllLanguages();
		this.options = await this.languageService.getLanguagesForSelect();
		this.language = 'en';
	}

	/**
	 * Set language from select field
	 */
	public selectLanguage(): void {
		this.languageSettings.setLanguage(this.language);
		this.translate.use(this.language);
	}

	/**
	 * Go to log in screen after selecting language
	 */
	public async nextStep(): Promise<void> {
		await this.router.navigate(['login']);
	}
}
