import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'form-input',
	templateUrl: './input.component.html',
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true},
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => InputComponent), multi: true}
	],
})
export class InputComponent implements ControlValueAccessor {
	/**
	 * Date formatting
	 */
	dateRegex = /(\d{2})\.(\d{2})\.(\d{4})/;

	/**
	 * All possible inputs
	 */
	@Input() tooltip: string = '';
	@Input() placeholder: string = '';
	@Input() id: string = '';
	@Input() fieldName: string;
	@Input() pattern: string = '.*';
	@Input() required: boolean = false;
	@Input() disabled: boolean = false;
	@Input() fieldType: string = 'text';
	@Input() class: string = '';
	@Input() visible: boolean = false;

	@Input() inputGroupClass: string;

	@Input('model') _model: string | number = '';

	@Output() modelChange = new EventEmitter();
	@Output() fieldValidation = new EventEmitter();

	@ViewChild('field', {static: true}) field: ElementRef;

	constructor() {
		this.id = Math.random().toString(36).substr(2, 9);
	}

	propagateChange: any = () => {
		let field = this.field as any;
		let control = field && field.control;
		this.modelChange.emit(this._model);
		this.fieldValidation.emit(control);
	};

	validateFn: any = () => {
	};

	get model() {
		return this._model;
	}

	/**
	 * Adding date formatting to date fields
	 */
	set model(val) {
		let value = `${val}`;
		if (this.fieldType === 'date' && this.dateRegex.test(value)) {
			const matches = value.match(this.dateRegex);
			const date = new Date(matches[2] + '.' + matches[1] + '.' + matches[3] + ' 12:00:00');
			this._model = `${date.getTime() / 1000}`;
			this.propagateChange();
		} else if (this.fieldType !== 'date') {
			this._model = value;
			this.propagateChange();
		}
	}

	writeValue(value) {
		if (value) {
			this.model = value;
		}
	}

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched() {
	}

	validate(c: FormControl) {
		return this.validateFn(c);
	}
}
