import { isSafariBrowser } from './is-safari';

type CsvString = string;

/**
 * Create a blob, a temporary link navigating to URL containing blob,
 * click the link and remove link straight after.
 */
export function downloadFile({ data, title }: { data: CsvString, title: string }): void {
	let blob = new Blob(['\ufeff' + data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;'});
	let downloadAnchor = document.createElement('a');

	if (isSafariBrowser()) {
		// if Safari open in new window to save file with random filename.
		downloadAnchor.setAttribute('target', '_blank');
	}

	downloadAnchor.setAttribute('href', URL.createObjectURL(blob));
	downloadAnchor.setAttribute('download', title);
	downloadAnchor.style.visibility = 'hidden';
	document.body.appendChild(downloadAnchor);
	downloadAnchor.click();
	document.body.removeChild(downloadAnchor);
}
