import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../user/user.service';
import { AuthenticationService } from './authentication.service';
import { URL } from '../../generic-types';

/**
 * This guard prevents unauthenticated users to access a route that is protected by this guard
 */
@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
			protected readonly userService: UserService,
			protected readonly router: Router,
			protected readonly authenticationService: AuthenticationService
	) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this.checkLogin(state.url);
	}

	public async checkLogin(url: URL): Promise<boolean> {
		try {
			await this.userService.getCurrentUser();
			return true;
		} catch (e) {
			this.authenticationService.redirectUrl = url;
			await this.router.navigate(['/login']);
			return false;
		}
	}
}
