import { Pipe, PipeTransform } from '@angular/core';
import { DocumentDto } from './dto/document.dto';

@Pipe({name: 'filterByLanguage'})
export class FilterByLanguage implements PipeTransform {

	transform(documents: DocumentDto[], language: string): DocumentDto[] {
		return documents.filter((item) => item.language === language);
	}
}
