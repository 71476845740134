import { Component } from '@angular/core';
import { AjaxLoaderService } from './ajax-loader.service';

@Component({
	selector: 'app-ajax-loader',
	templateUrl: './ajax-loader.component.html',
	styleUrls: ['./ajax-loader.component.scss']
})
export class AjaxLoaderComponent {
	constructor(public readonly ajaxLoader: AjaxLoaderService) {
	}
}
