import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractUserFormComponent, USER_FORM_MODE_EDIT } from '../abstract.user-form.component';
import { UserService } from '../user.service';
import { LanguageService } from '../../../layout/language/language.service';
import { UserGroupService } from '../user-group/user-group.service';
import {
	NOTIFICATION_ERROR,
	NOTIFICATION_SUCCESS,
	NotificationService
} from '../../../layout/notification/notification.service';
import { UserNationalityService } from '../user-nationality/user-nationality.service';

@Component({
	selector: 'app-user-add',
	templateUrl: './user-form.component.html',
})
export class UserEditComponent extends AbstractUserFormComponent {

	mode: number = USER_FORM_MODE_EDIT;

	constructor(
			protected location: Location,
			protected languageService: LanguageService,
			protected userGroupService: UserGroupService,
			protected userNationalityService: UserNationalityService,
			private userService: UserService,
			private route: ActivatedRoute,
			private router: Router,
			private notificationService: NotificationService
	) {
		super(location, languageService, userGroupService, userNationalityService);
	}

	public async ngOnInit(): Promise<void> {
		await super.ngOnInit();
		this.user = await this.userService.getUser(this.route.snapshot.params.id);
		await super.ngOnInit();
	}

	/**
	 * Edit user data
	 */
	public async updateUser(): Promise<void> {
		if (!this.formValid()) {
			this.notificationService.notify('Missing Data!', NOTIFICATION_ERROR);
			return;
		}
		this.userService.updateUser(this.user).then(() => {
			this.notificationService.notify('Nutzer wurder erfolgreich bearbeitet.', NOTIFICATION_SUCCESS);
			this.router.navigate(['/user-admin']);
		}, () => {
			this.notificationService.notify('Nutzer konnte nicht bearbeitet werden!', NOTIFICATION_ERROR);
		});
	}

	/**
	 * Forgot password functionality
	 */
	public resendPasswordMail(): void {
		this.userService.sendResetPasswordMail(this.user.email).then(() => {
			this.notificationService.notify('Passwort zurücksetzen Mail wurde versendet', NOTIFICATION_SUCCESS);
		});
	}
}
