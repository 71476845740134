import { Component } from '@angular/core';
import { WishlistEs } from '../wishlist-es.service';
import { DatePipe, Location } from '@angular/common';
import { ProductEs } from '../../price-calculator-es/product-es';
import {
	MainGroup,
	mainGroupLongToShortMap,
	mainGroupShortToLongMap
} from '../../price-calculator-es/price-calculator-es';
import { TranslateService } from '@ngx-translate/core';
import { FixedSizeArray } from '../../utils';
import { downloadFile } from '../../../utilities/download-file';
import { WishlistEsDetailBase } from '../wishlist-es-detail-base';

const TABLE_HEAD: FixedSizeArray<[keyof ProductEs.WithResult, string], 7> = [
	['series', 'Serie'],
	['group', 'Gruppe'],
	['width', 'Breite'],
	['length', 'Länge'],
	['size', 'Größe'],
	['corn', 'Korn'],
	['netPrice', 'Kalkulatorischer Nettopreis'],
];

@Component({
	selector: 'app-wishlist-es-detail',
	templateUrl: './wishlist-es-detail.component.html',
	styleUrls: ['./wishlist-es-detail.component.scss'],
})
export class WishlistEsDetailComponent extends WishlistEsDetailBase {

	constructor(
			private readonly location: Location,
			private readonly translateService: TranslateService,
			private readonly datePipe: DatePipe,
	) {
		super();
	}

	public download(e: MouseEvent, wishlist: WishlistEs<ProductEs.WithResult[]>): void {
		e.preventDefault();
		downloadFile({
			data: this.convertToCsv(wishlist),
			title: `${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}_${wishlist.title}-wishlist-export.xls`
		});
	}

	private convertToCsv(wishlist: WishlistEs<ProductEs.WithResult[]>) {
		const br = '\r\n';
		const head = TABLE_HEAD.map((el) => {
			return this.translateService.instant(el[1]);
		}).join(';') + ';';
		const rows = wishlist.products.map((product) => {
			const {group, series, width, length, size, corn, netPrice} = product;
			const row: FixedSizeArray<string, 7> = this.priceCalculatorEsService.isBelt(group)
					? [
						series,
						mainGroupShortToLongMap[group],
						width,
						length,
						'',
						corn,
						netPrice.toString()
					] : [
						series,
						mainGroupShortToLongMap[group],
						'',
						'',
						size,
						corn,
						netPrice.toString()
					];

			return row.join(';');
		});

		return head + br + rows.join(';' + br);
	}

	public back(): void {
		this.location.back();
	}

	public delete(wishlist: WishlistEs<ProductEs.WithResult[]>, product: ProductEs.WithResult): void {
		this.wishlistEsService.deleteProduct(wishlist.id, product.id);
	}

	public isBelt(group: MainGroup) {
		return this.priceCalculatorEsService.isBelt(mainGroupLongToShortMap[group]);
	}
}
