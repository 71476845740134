import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-cookie-message',
	templateUrl: './cookie-message.component.html',
	styleUrls: ['./cookie-message.component.scss']
})
export class CookieMessageComponent implements OnInit {

	/**
	 * Cookie message settings
	 */
	private EXPIRE_TIME = 30; // in Days
	private COOKIE_NAME = 'vsmcm';
	public visible = false;

	/**
	 * Close cookie message and set cookie for expire date
	 */
	public close() {
		let cookieExpireDate = (new Date(Date.now() + (this.EXPIRE_TIME * 864000000))).toUTCString();
		document.cookie = `${this.COOKIE_NAME}=1; expires=${cookieExpireDate}; path=/`;
		this.visible = false;
	}

	public ngOnInit(): void {
		this.visible = `${CookieMessageComponent.readCookie(this.COOKIE_NAME)}` !== '1';
	}

	/**
	 * Only show message when cookie is not set
	 */
	private static readCookie(name): any {
		const nameEQ = `${name}=`;
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}
}
