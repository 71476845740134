import { Injectable } from '@angular/core';
import { WishlistEs } from './wishlist-es.service';
import { ProductEs } from '../price-calculator-es/product-es';

const MOCK_WISHLISTS: WishlistEs[] = [
	{
		title: 'Wishlist 1',
		creationDate: new Date('2023-01-02'),
		id: 1,
		products: [
			{
				id: 1,
				width: '54655',
				maxWidth: '',
				length: '50000',
				discount: '',
				group: 'BAN',
				batch: '',
				abrasive: 'Compacto',
				format: '',
				type: '',
				hardness: '',
				series: 'KK772X',
				units: '',
				size: '',
				corn: '0240',
			},
			{
				id: 2,
				width: '',
				maxWidth: '',
				length: '',
				discount: '',
				group: 'HOJ',
				batch: '',
				abrasive: 'Vellon/Oxido Al',
				format: '',
				type: '',
				hardness: '',
				series: 'KV301',
				units: '',
				size: '150x229',
				corn: 'Medium-0180',
			}
		],
	},
	{
		title: 'Wishlist 2',
		creationDate: new Date('2023-01-03'),
		id: 2,
		products: [],
	},
];

@Injectable({
	providedIn: 'root'
})
export class WishlistEsMockService {

	wishlists = MOCK_WISHLISTS;

	getAll(): WishlistEs[] {
		return this.wishlists;
	}

	getOne(id: number): WishlistEs {
		return this.wishlists.find(el => el.id === id);
	}

	updateOne(wishlist: WishlistEs): WishlistEs[] {
		this.wishlists = this.wishlists.map(el => el.id === wishlist.id ? wishlist : el);
		return this.wishlists;
	}

	addOne(title: string): WishlistEs[] {
		this.wishlists = [
			...this.wishlists,
			{
				id: Math.max(...this.wishlists.map(e => e.id)) + 1,
				title,
				products: [],
				creationDate: new Date(),
			}
		];

		return this.wishlists;
	}

	deleteOne(id: number): WishlistEs[] {
		this.wishlists = this.wishlists.filter(el => el.id !== id);
		return this.wishlists;
	}

	deleteProduct(wishlistId: number, productId: number): WishlistEs[] {
		this.wishlists = this.wishlists.map(el => el.id !== wishlistId ? el : {
			...el,
			products: el.products.filter(x => x.id !== productId)
		});

		return this.wishlists;
	}

	addProduct(wishlistId: number, product: ProductEs.Base): WishlistEs[] {
		const ghettoId = 1 + Math.max(...this.wishlists.reduce((acc, curr) => {
			return [...acc, ...curr.products.map(l => l.id)];
		}, []));

		this.wishlists = this.wishlists.map(el => el.id !== wishlistId ? el : {
			...el,
			products: [...el.products, {
				...product,
				id: ghettoId
			}]
		})

		return this.wishlists;
	}
}
