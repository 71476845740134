import { Component, AfterViewInit } from '@angular/core';
import { DownloadAreaService } from '../../download-area.service';
import { ModalService } from '../../../layout/modal/modal.service';
import { DocumentDto } from '../../dto/document.dto';
import { UserGroup } from '../../../authorization/user/user-group/user-group';
import { UserGroupService } from '../../../authorization/user/user-group/user-group.service';
import { AjaxLoaderService } from '../../../layout/ajax-loader/ajax-loader.service';
import { GroupDto } from '../../dto/group.dto';
import { UserNationalityService } from '../../../authorization/user/user-nationality/user-nationality.service';
import { LanguageService } from '../../../layout/language/language.service';
import { UserNationality } from '../../../authorization/user/user-nationality/user-nationality';

@Component({
	selector: 'app-download-list-edit-document',
	templateUrl: './edit-document.component.html'
})
export class EditDocumentComponent implements AfterViewInit {
	public document: DocumentDto = null;
	public categoryGroups: any;
	public comparer: any = this._compareFn.bind(this);
	public userGroup: UserGroup[] = [];
	public nationalities: UserNationality[];
	public languages: any[] = [];


	_compareFn(a, b) {
		return a?.id === b?.id;
	}

	constructor(
			private readonly downloadAreaService: DownloadAreaService,
			private readonly modalService: ModalService,
			private readonly userGroupService: UserGroupService,
			private readonly ajaxLoaderService: AjaxLoaderService,
			private readonly userNationalityService: UserNationalityService,
			private readonly languageService: LanguageService
	) {
		this.categoryGroups = this.modalService.currentData[0];
		this.document = this.modalService.currentData[1];
	}


	public ngAfterViewInit(): void {
		this.document = JSON.parse(JSON.stringify(this.document));
	}

	public async ngOnInit(): Promise<void> {
		await this.ajaxLoaderService.show();
		this.userGroup = await this.userGroupService.getAllGroups();
		await this.ajaxLoaderService.hide();
		this.nationalities = await this.userNationalityService.getAllNationalitiesForCheckboxes();
		this.languages = await this.languageService.getLanguagesForSelect();
	}

	public abort(): void {
		this.modalService.close();
	}

	public async edit(): Promise<void> {
		await this.downloadAreaService.updateDocument(this.document);
		this.modalService.close();
	}

	public onFileSelected(event) {
		if (event.target.files.length > 0) {
			this.document.file = event.target.files[0];
		}
	}

	public selectGroup(item): void {
		if (this.isGroupChecked(item)) {
			this.document.group = this.document.group.filter(group => group.id !== item.id);
		} else {
			this.document.group.push(item);
		}
	}

	public isGroupChecked(item: GroupDto): any {
		return this.document.group.find(x => x.id === item.id) !== undefined;
	}

	public getFileName(): string {
		if (this.document.file instanceof File) {
			return this.document.file?.name;
		}
		return '';
	}

	public selectNationality(nationality: any): void {
		if (this.isNationalityChecked(nationality)) {
			this.document.nationalities = this.document.nationalities.filter(selected => selected.id !== nationality.id);
		} else {
			this.document.nationalities.push(nationality);
		}
	}

	public isNationalityChecked(nationality: any): any {
		return this.document.nationalities.find(x => x.id === nationality.id) !== undefined;
	}
}
