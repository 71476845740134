import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { NOTIFICATION_SUCCESS, NotificationService } from '../../layout/notification/notification.service';
import { PASSWORD } from '../../generic-types';

@Component({
	selector: 'app-set-password',
	templateUrl: './set-password.component.html'
})
export class SetPasswordComponent {
	/**
	 * the password of the user
	 */
	public password: PASSWORD;
	/**
	 * the password confirmation of the user
	 */
	public passwordConfirm: PASSWORD;

	constructor(
			private readonly route: ActivatedRoute,
			private readonly userService: UserService,
			private readonly notificationService: NotificationService,
			private readonly router: Router
	) {
	}

	/**
	 * sets the password of the user
	 */
	public async resetPassword(): Promise<void> {
		await this.userService.resetPassword(this.password, this.route.snapshot.params.token);
		this.notificationService.notify('Passwort wurde erfolgreich zurückgesetzt', NOTIFICATION_SUCCESS);
		await this.router.navigate(['/login']);
	}
}
