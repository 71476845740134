import { Pipe, PipeTransform } from '@angular/core';

/**
 * The structure of the date returned by the API.
 */
export interface LastLoginObject {
	date: string
	timezone: string
	timezone_type: number
}

@Pipe({
	name: 'userLastLoginDatePipe'
})
export class UserLastLoginDatePipe implements PipeTransform {

	/**
	 * Returns the last login date of a user based on the last login object
	 */
	public transform(value?: LastLoginObject): string {
		if (value && typeof value.date === 'string') {
			let lastLogin = value.date.substr(0, 10);
			if (!lastLogin.startsWith('-')) {
				return lastLogin;
			}
		}
		return 'Never';
	}
}

