import { Injectable } from '@angular/core';
import { AjaxLoaderService } from '../layout/ajax-loader/ajax-loader.service';
import { AuthenticationService } from '../authorization/services/authentication.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DatabaseFile } from '../article-list/database-file';

type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface RequestConfig {
	single?: boolean,
	authenticated?: boolean,
	url: string,
	method?: RequestMethods,
	body?: Object,
	cached?: boolean,
}

@Injectable({
	providedIn: 'root',
})
export class CachedAjaxService {
	/**
	 * Abort controllers that are used to abort requests that should be single.
	 */
	private abortControllers: { [index: string]: AbortController } = {};
	/**
	 * The cache for cached requests that return the same data.
	 */
	private dataCache: { [index: string]: any } = {};

	private offlineResponse: Response;

	constructor(
			private readonly ajaxLoader: AjaxLoaderService,
			private readonly authenticationService: AuthenticationService,
			private dbService: NgxIndexedDBService
) {
	}

	public removeCacheItem(url: string, method: RequestMethods): void {
		const id = this.generateId({url, method});
		delete this.dataCache[id];
	}

	/**
	 * Makes a request to the server based on the given parameters.
	 */
	public async fetchRequest<T = any>(config: RequestConfig): Promise<T> {
		this.updateConfigDefaults(config);
		if (config.cached) {
			// @todo: check if cache is still valid by comparing with server data
			const id = this.generateId(config);
			if (typeof this.dataCache[id] !== 'undefined') {
				return this.dataCache[id];
			}
		}
		await this.ajaxLoader.show(config.url !== 'pf-files');
		const response = await fetch(`/assets/api/${config.url}`, await this.buildRequestInit(config));
		let responseStatus = response.status;
		let json;
		try {
			json = await response.json();
		} catch (e) {
			json = null;
			if (window.localStorage.getItem(config.url) && window.localStorage.getItem(config.url) !== 'null') {
				json = JSON.parse(window.localStorage.getItem(config.url));
				responseStatus = 200;
			}
		}
		if (config.url === 'pf-files') {
			if (json !== null) {
				this.dbService.clear('databaseFile').subscribe((successDeleted) => {
					console.log('success? ', successDeleted);
				});
				const myString = JSON.stringify(json);
				const partOne = myString.slice(0, myString.length / 2)
				const partTwo = myString.slice(myString.length / 2, myString.length)

				this.dbService.add('databaseFile', {
					name: partOne,
					csvData: partTwo,
					groupedField: '',
					header: '',
					orderDirection: '',
					orderField: '',
					whereClauses: '',
					selectedField: '',
					orderMultiple: ''
				}).subscribe((key) => {
					console.log('key: ', key);
				});
			} else {
				const key = await (this.dbService.getAll('databaseFile').toPromise());
				key.forEach(file => {
					const jsonString = file['name'] + file['csvData'];
					this.offlineResponse = JSON.parse(jsonString);
				});
				json = this.offlineResponse;
				responseStatus = 200;
			}
		}

		if (json && config.url !== 'pf-files') {
			window.localStorage.setItem(config.url, JSON.stringify(json));
		}
		if (responseStatus !== 200) {
			await this.ajaxLoader.hide();
			throw new Error(json);
		} else {
			if (config.cached) {
				const id = this.generateId(config);
				this.dataCache[id] = json;
			}
		}
		await this.ajaxLoader.hide();

		return json;
	}

	/**
	 * Updates the config object with default values.
	 */
	private updateConfigDefaults(config: RequestConfig): void {
		config.method = config.method ?? 'GET';
		config.single = config.single ?? false;
		config.authenticated = config.authenticated ?? true;
		config.body = config.body ?? false;
		config.cached = config.cached ?? false;
	}

	/**
	 * Generates an id for a given request.
	 */
	private generateId(config: RequestConfig): string {
		return config.url + config.method;
	}

	/**
	 * Builds the request init object for the fetch request.
	 */
	private async buildRequestInit(config: RequestConfig): Promise<RequestInit> {
		const requestInit: RequestInit = {method: config.method};
		if (config.single) {
			const id = this.generateId(config);
			if (typeof this.abortControllers[id] === 'undefined') {
				this.abortControllers[id] = new AbortController();
				requestInit.signal = this.abortControllers[id].signal;
			} else {
				this.abortControllers[id].abort();
				this.abortControllers[id] = undefined;
			}
		}
		if (config.authenticated) {
			requestInit.headers = await this.authenticationService.addAuthTokenToHeader({
				'Content-Type': 'application/json'
			});
		}
		if (config.body) {
			requestInit.body = JSON.stringify(config.body);
		}
		return requestInit;
	}

	/**
	 * makes a post request to the server.
	 */
	public async fetchUpload(url: string, file: File): Promise<any> {
		await this.ajaxLoader.show(url !== 'pf-files');

		const requestInit: RequestInit = {method: 'POST'};

		requestInit.headers = await this.authenticationService.addAuthTokenToHeader();


		const form = new FormData();
		form.append('file', file);
		requestInit.body = form;

		const response = await fetch(`/assets/api/${url}`, requestInit);
		let json;
		try {
			json = await response.json();
		} catch (e) {
			json = null;
		}

		if (response.status !== 200) {
			throw new Error(json);
		}
		await this.ajaxLoader.hide();
		return json;
	}
}
