import { DatePipe, Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { downloadFile } from "src/utilities/download-file";
import { UserService } from "../../authorization/user/user.service";
import { ConfirmService } from "../../confirm/confirm.service";
import { PRICE_LIST_DATE } from "../../constant";
import { ProductService } from "../../generic-product-table/product.service";
import { NOTIFICATION_SUCCESS, NotificationService } from "../../layout/notification/notification.service";
import { Product } from "../../price-calculator/product";
import { Wishlist } from "../wishlist";
import { WishlistService } from "../wishlist.service";

declare const _etracker;
declare const et_UserDefinedEvent;

@Component({
	selector: "app-wishlist-detail",
	templateUrl: "./wishlist-detail.component.html",
})
export class WishlistDetailComponent implements OnInit {
	/**
	 * The wishlist to display
	 */
	public wishlist: Wishlist = null;
	/**
	 * the groups of the wishlist
	 */
	public groups = this.productService.getGroups();
	/**
	 * the table headers
	 */
	private tableHead: string[] = [
		"hauptgruppe",
		"serie",
		"breite / außendurchmesser",
		"länge / innendurchmesser",
		"korn",
		"preis (Euro)",
	];
	/**
	 * the current date when the price-list was updated
	 */
	public PRICE_LIST_DATE = PRICE_LIST_DATE;

	/**
	 * userGroup
	 */
	public userGroup: string = "";

	constructor(
		private readonly route: ActivatedRoute,
		private readonly wishlistService: WishlistService,
		private readonly datePipe: DatePipe,
		private readonly translateService: TranslateService,
		protected readonly location: Location,
		public readonly productService: ProductService,
		private readonly userService: UserService,
		private readonly confirmService: ConfirmService,
		private readonly notificationService: NotificationService
	) {}

	/**
	 * Initializes the component.
	 */
	public async ngOnInit(): Promise<void> {
		const user = await this.userService.getCurrentUser();
		this.userGroup = user.userGroup.title;
		this.wishlist = await this.wishlistService.getWishlist(this.route.snapshot.params.id);
	}

	/**
	 * navigates the user back.
	 */
	public back(): void {
		this.location.back();
	}

	/**
	 * Starts the csv creation and its download
	 */
	public processForCsvExport(event: Event): void {
		event.preventDefault();
		downloadFile({
			data: this.convertJsonToCsv(),
			title: `${this.datePipe.transform(new Date(), "yyyy-MM-dd")}_${this.wishlist.title}-wishlist-export.xls`,
		});
	}

	/**
	 * Take all Products and return all keys and values in the form of strings, separated by semicolons
	 */
	private convertJsonToCsv(): string {
		let string = "";

		for (let index = 0; index < this.tableHead.length; index++) {
			string += `${this.translateService.instant(this.tableHead[index])};`;
		}

		string += "\r\n";

		for (let index in this.wishlist.products) {
			for (let property in this.wishlist.products[index]) {
				// translate all mainGroups
				switch (property) {
					case "mainGroup":
						string += `${this.translateService.instant(this.wishlist.products[index][property])};`;
						break;
					case "price":
						if (
							typeof this.wishlist.products[index][property] === "number" &&
							this.wishlist.products[index][property] !== 0
						) {
							string += `${Math.round(this.wishlist.products[index][property] as number) / 100};`
								.toString()
								.replace(".", ",");
						}
						break;
					case "pricePromise":
					case "id":
					case "group":
						break;
					default:
						string += `${this.wishlist.products[index][property]};`;
				}
			}

			string += "\r\n";
		}

		return string;
	}

	async delete(product: Product) {
		if (await this.confirmService.confirm("Möchten Sie dieses Produkt löschen?", "Produkt löschen")) {
			await this.wishlistService.removeFromWishlist(this.wishlist, product);
			this.wishlist = await this.wishlistService.getWishlist(this.wishlist.id);
			this.notificationService.notify("Das Produkt wurde erfolgreich entfernt", NOTIFICATION_SUCCESS);
		}
	}

	getProductsByGroup(group): Product[] {
		return this.wishlist.products.filter(({ mainGroup }) => mainGroup === group);
	}

	eTrackerSendEvent(name: string) {
		_etracker.sendEvent(new et_UserDefinedEvent("Merklisten Export", name, this.userGroup, "Click"));
	}
}
