import { Component, OnInit, } from '@angular/core';
import { CostBenefitService } from '../../cost-benefit.service';
import { Router } from '@angular/router';
import { NotificationService, NOTIFICATION_ERROR } from '../../../layout/notification/notification.service';
import { CommonTranslations } from '../../common-translations';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
	selector: 'app-cost-benefit-print',
	templateUrl: './print.component.html'
})
export class PrintComponent extends CommonTranslations implements OnInit {
	public scheme: any = {domain: ['#cd0529', '#888']};

	constructor(
			protected readonly translatePipe: TranslatePipe,
			public readonly costBenefitService: CostBenefitService,
			private readonly router: Router,
			private readonly notification: NotificationService
	) {
		super(translatePipe, costBenefitService);
	}

	/**
	 * Initializing the calculation form from cache and then immediately calculating the result.
	 */
	public async ngOnInit(): Promise<void> {
		await this.costBenefitService.getCalculationFormFromCache();
		if (this.costBenefitService.isFormValidForCalculation()) {
			await this.costBenefitService.calculate();
		} else {
			this.notification.notify('Der Druckvorgang wurde abgebrochen, da das Formular nicht richtig ausgefüllt wurde.', NOTIFICATION_ERROR);
			await this.router.navigate(['/cost-benefit']);
		}
	}
}
