import { Component, AfterViewInit } from '@angular/core';
import { ModalableComponentInterface } from '../../../layout/modal/modalable-component.interface';
import { CategoryDto } from '../../dto/category.dto';
import { DownloadAreaService } from '../../download-area.service';
import { ModalService } from '../../../layout/modal/modal.service';

@Component({
	selector: 'app-download-list-delete-category',
	templateUrl: './delete-category.component.html'
})
export class DeleteCategoryComponent implements ModalableComponentInterface, AfterViewInit {
	public additionalData: CategoryDto;

	constructor(
			private readonly downloadAreaService: DownloadAreaService,
			private readonly modalService: ModalService
	) {
	}

	public ngAfterViewInit(): void {
		this.additionalData = JSON.parse(JSON.stringify(this.additionalData));
	}

	public abort(): void {
		this.modalService.close();
	}

	public async delete(): Promise<void> {
		await this.downloadAreaService.deleteCategory(this.additionalData);
		this.modalService.close();
	}
}
