import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../authorization/user/user.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	public connectionLost: boolean = !navigator.onLine;

	constructor(
			private readonly router: Router,
			private readonly userService: UserService
	) {
	}

	/**
	 * Logout for users with redirect to log in screen
	 */
	public async logout(): Promise<void> {
		await this.userService.logout();
		await this.router.navigate(['/login']);
	}
}
