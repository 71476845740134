import { Component } from '@angular/core';
import { WishlistEsDetailBase } from '../../wishlist-es-detail-base';

@Component({
	selector: 'app-wishlist-es-detail-print',
	templateUrl: './wishlist-es-detail-print.component.html',
	styleUrls: ['./wishlist-es-detail-print.component.scss']
})
export class WishlistEsDetailPrintComponent extends WishlistEsDetailBase {
	constructor() {
		super();
	}

	print(): void {
		requestAnimationFrame(window.print);
	}
}
