import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ucFirst'
})
export class UcfirstPipe implements PipeTransform {
	/**
	 * Capitalize the first letter of the string
	 */
	public transform(value: string): string {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}
}
