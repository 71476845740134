import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

/**
 * Defines product series strings for germany and spain
 */
export class ProductService {
	private static groups = [
		'band',
		'blattware',
		'rolle',
		'scheibe',
		'jumbo',
		'zubehoer'
	] as const;

	private static groupsEs = [
		'Accesorios',
		'Banda Ancha',
		'Banda Estrecha',
		'Banda Portatil',
		'Cepillos',
		'Discos',
		'Hojas',
		'Rollos',
		'Rollos Estandar',
	] as const;


	public async getGroups(): Promise<typeof ProductService.groups> {
		return ProductService.groups;
	}

	public async getGroupsEs(): Promise<typeof ProductService.groupsEs> {
		return ProductService.groupsEs;
	}
}
