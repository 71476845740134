import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../price-calculator/product';

@Pipe({
	name: 'productGroupFilter',
	pure: true
})
export class ProductGroupFilterPipe implements PipeTransform {
	/**
	 * Filters the products by group.
	 */
	public transform(value: Product[], group: any): Product[] {
		return value.filter(product => product.mainGroup === group);
	}
}
