import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatPrice',
	pure: true,
})
export class FormatPricePipe implements PipeTransform {
	/**
	 * Format a price to a string with 2 decimals.
	 */
	public transform(value: number): string {
		const priceFormatted = value.toFixed(2);
		return `${priceFormatted.split('.')[0]},${priceFormatted.split('.')[1]}`;
	}
}
