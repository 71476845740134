import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { USER_ADMIN } from '../../../authorization/user/user-group/user-group';
import { UserService } from '../../../authorization/user/user.service';
import { LayoutService } from '../../../layout/layout.service';
import { ModalService } from '../../../layout/modal/modal.service';
import { DownloadAreaService } from '../../download-area.service';
import { CategoryDto } from '../../dto/category.dto';
import { DocumentDto } from '../../dto/document.dto';
import { AddCategoryComponent } from '../../modals/add-category/add-category.component';
import { AddDocumentComponent } from '../../modals/add-document/add-document.component';
import { DeleteCategoryComponent } from '../../modals/delete-category/delete-category.component';
import { DeleteDocumentComponent } from '../../modals/delete-document/delete-document.component';
import { EditCategoryComponent } from '../../modals/edit-category/edit-category.component';
import { EditDocumentComponent } from '../../modals/edit-document/edit-document.component';

declare const _etracker;
declare const et_UserDefinedEvent;

export interface DownloadCategoryGroup {
	category: CategoryDto;
	documents: DocumentDto[];
}

@Component({
	selector: 'app-download-list',
	templateUrl: './list.component.html'
})
export class ListComponent implements OnInit, AfterViewInit {
	private fixedNavOffset: number = 200; // in pixel
	private documents: DocumentDto[] = [];
	public categoryGroups: DownloadCategoryGroup[] = [];
	public isAdmin: boolean = false;
	public currentCategory: string = '1';
	private clientRectCache = [];
	public noDocumentsVisible: boolean = false;

	/**
	 * userGroup
	 */
	public userGroup: string = '';
	@ViewChildren('categoryGroup')
	public categoryGroup: QueryList<ElementRef>;
	private categories: any;
	public language: string;

	constructor(
			private readonly route: ActivatedRoute,
			private readonly userService: UserService,
			private readonly modalService: ModalService,
			private readonly layoutService: LayoutService,
			private readonly downloadAreaService: DownloadAreaService,
			public readonly translate: TranslateService,
	) {
		this.documents = this.route.snapshot.data.documents;
		this.categories = this.route.snapshot.data.categories;
		this.categoryGroups = this.downloadAreaService.createDownloadCategoryGroups(this.documents);
		this.layoutService.scrollSubject.subscribe((event) => {
			const num = this.clientRectCache.findIndex(number => {
				return number >= event.target.scrollTop;
			});

			if (this.currentCategory && num !== -1) {
				this.currentCategory = this.categoryGroups[num].category.id;
			}
		});
	}

	public async ngOnInit(): Promise<void> {
		const user = await this.userService.getCurrentUser();
		this.userGroup = user.userGroup.title;
		this.isAdmin = user.userGroup.id === USER_ADMIN;
		this.downloadAreaService.entityChangedSubject.subscribe(async () => {
			this.categories = await this.downloadAreaService.getCategories();
			this.documents = await this.downloadAreaService.getDocuments();
			this.categoryGroups = this.downloadAreaService.createDownloadCategoryGroups(this.documents);
		});

		if (this.documents.length === 0) {
			this.noDocumentsVisible = !this.noDocumentsVisible;
		}
		this.translate.onLangChange.subscribe(() => {
			this.language = this.translate.currentLang;
		});
		this.language = this.translate.currentLang;
	}

	public ngAfterViewInit(): void {
		this.updateListener();
		this.categoryGroup.changes.subscribe(() => {
			setTimeout(() => {
				this.updateListener();
			}, 500);
		});
	}

	private updateListener() {
		this.clientRectCache.length = 0;
		setTimeout(() => {
			// The timeout is needed to wait for the view to be rendered
			this.categoryGroup.forEach(element => {
				const rect = element.nativeElement.getBoundingClientRect();
				this.clientRectCache.push(this.layoutService.scrollTarget.scrollTop + rect.y + rect.height - this.fixedNavOffset);
			});
		}, 500);
	}

	public openAddCategory(): void {
		return this.modalService.open(AddCategoryComponent);
	}

	public async openEditCategoryModal(category: CategoryDto): Promise<void> {
		return this.modalService.open(EditCategoryComponent, category);
	}

	public async openDeleteCategoryModal(category: CategoryDto): Promise<void> {
		return this.modalService.open(DeleteCategoryComponent, category);
	}

	public openAddDocument(): void {
		return this.modalService.open(AddDocumentComponent, this.categories);
	}

	public async openEditDocumentModal(document: DocumentDto): Promise<void> {
		return this.modalService.open(EditDocumentComponent, [this.categories, document]);
	}

	public async openDeleteDocumentModal(document: DocumentDto): Promise<void> {
		return this.modalService.open(DeleteDocumentComponent, document);
	}

	public scrollToGroup(category: CategoryDto): void {
		const index = this.categoryGroups.findIndex(x => x.category.id === category.id);
		if (index !== -1) {
			const element = this.categoryGroup.toArray()[index].nativeElement;
			this.layoutService.scrollTarget.scroll({
				// The 100 is a magic number to make the scroll animation smooth
				top: element.offsetTop - this.fixedNavOffset + 100,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	eTrackerSendEvent(name: string) {
		_etracker.sendEvent(new et_UserDefinedEvent('Dokumenten Export', `${name} export`, this.userGroup, 'Click'));
	}
}
