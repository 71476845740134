import { Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	public isOpen: boolean = false;
	public currentComponent: Type<unknown> | null = null;
	public modalStateSubject: Subject<boolean> = new Subject<boolean>();
	public currentData: any | null = null;

	public close(): void {
		this.isOpen = false;
		this.currentComponent = null;
		this.currentData = null;
		this.modalStateSubject.next(this.isOpen);
	}

	public open(component: Type<unknown>, data?: any): void {
		this.currentComponent = component;
		this.currentData = data;
		this.isOpen = true;
		this.modalStateSubject.next(this.isOpen);
	}
}
