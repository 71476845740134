import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { Card } from '../card/card';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	public dashboardItems: Card[] = [];

	constructor(private readonly dashboardService: DashboardService) {
	}

	/**
	 * Initialize all Dashboard items
	 */
	public async ngOnInit(): Promise<void> {
		this.dashboardItems = await this.dashboardService.getDashboardElements();
	}
}
