import { Component } from '@angular/core';
import { UserService } from '../user/user.service';
import { EMAIL } from '../../generic-types';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {
	/**
	 * determines if the form is submitted
	 */
	public submitted: boolean = false;
	/**
	 * the email address of the user
	 */
	public email: EMAIL = '';

	constructor(private readonly userService: UserService) {
	}

	/**
	 * resets the password
	 */
	public async resetPassword(): Promise<void> {
		await this.userService.sendResetPasswordMail(this.email);
		this.submitted = true;
	}
}
