import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user';
import { Router } from '@angular/router';
import { NOTIFICATION_SUCCESS, NotificationService } from '../../../layout/notification/notification.service';
import { AjaxLoaderService } from '../../../layout/ajax-loader/ajax-loader.service';

@Component({
	selector: 'app-user-admin',
	templateUrl: './user-admin.component.html',
	styleUrls: [
		'./user-admin.component.scss'
	]
})
export class UserAdminComponent implements OnInit {
	public users: User[] = [];
	public search: string = '';

	constructor(
			private readonly router: Router,
			private readonly userService: UserService,
			private readonly notificationService: NotificationService,
			private readonly ajaxLoaderService: AjaxLoaderService
	) {
	}

	public async ngOnInit(): Promise<void> {
		await this.ajaxLoaderService.show();
		// @todo: ajax loader is hidden before user list is resolved
		this.users = await this.userService.getAllUsers();
		await this.ajaxLoaderService.hide();
	}

	/**
	 * deletes the user with notifications
	 */
	public async deleteUser(user: User): Promise<void> {
		if (confirm('Sind Sie sicher den Nutzer zu löschen?')) {
			await this.userService.deleteUser(user);
			this.notificationService.notify('Nutzer wurde erfolgreich gelöscht.', NOTIFICATION_SUCCESS);
			location.reload();
		} else {
			this.notificationService.notify('Nutzer wurde nicht gelöscht.');
		}
	}
}
