import { Component, Input } from '@angular/core';

export interface TableLabel {
	text: string;
	secondary: string;
	bold: boolean;
}

export type TableData = [TableLabel, { text: string }][];

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent {
	@Input()
	public tableData: TableData;
}
