import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CachedAjaxService } from '../services/cached-ajax.service';

@Injectable({
	providedIn: 'root',
})
export class ProductFinderService {
	constructor(
			private readonly translate: TranslateService,
			private readonly cachedAjaxService: CachedAjaxService
	) {
	}

	public async getProductFinderLink(series): Promise<string> {
		const url = `https://www.vsmabrasives.com/${this.translate.currentLang}/${series.toLowerCase()}`;
		const {success} = await this.cachedAjaxService.fetchRequest({
			url: `product/?url=${encodeURIComponent(url)}`
		});
		return success ? url : '';
	}
}
