import { Injectable } from '@angular/core';
import { AuthGuard } from './auth-guard.service';
import { URL } from '../../generic-types';

/**
 * This guard prevents user without spanish nationality to access a route that is protected by this guard
 */
@Injectable()
export class SpanishNationalityGuard extends AuthGuard {

	public async checkLogin(url: URL): Promise<boolean> {
		if (await this.userService.isSpanish()) {
			return true;
		}
		this.authenticationService.redirectUrl = url;
		await this.router.navigate(['/home']);
		return false;
	}
}
