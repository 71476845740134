import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { NavigationComponent } from '../navigation.component';

@Component({
	selector: 'app-burger',
	templateUrl: './burger.component.html',
	styleUrls: ['./burger.component.scss'],
})
export class BurgerComponent implements OnInit {
	public navigationIsOpen = true;

	constructor(private readonly navigationService: NavigationService) {
	}

	public ngOnInit() {
		if (NavigationComponent.isMobileMenu()) {
			this.navigationIsOpen = false;
		} else {
			this.navigationIsOpen = this.navigationService.getNavigationStatus();
		}
		this.navigationService.navigationChange.subscribe(() => {
			this.navigationIsOpen = this.navigationService.navigationStatus;
		});
	}

	/**
	 * Show and hide side navigation
	 */
	public toggleNavigationStatus(): void {
		this.navigationIsOpen = this.navigationService.toggleNavigationStatus();
	}
}
