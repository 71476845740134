import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalSettingsService } from './layout/language/language-settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { UserService } from './authorization/user/user.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	constructor(
			private readonly translate: TranslateService,
			private readonly _localSettings: LocalSettingsService,
			private readonly router: Router,
			private readonly gtag: Gtag,
			private readonly userService: UserService
	) {
		translate.setDefaultLang('de');
		_localSettings.getLanguage().subscribe(storedLang => {
			translate.use(storedLang !== '' && storedLang !== null ? (storedLang as string) : 'en');
		});
	}

	public ngOnInit(): void {
		this.router.events.subscribe(evt => {
			if (evt instanceof NavigationEnd) {
				this.scrollToTop();
			}
		});
	}

	/**
	 * Scrolls to the top of the page and triggers a Google Analytics event.
	 */
	private scrollToTop(): void {
		window.scrollTo(0, 0);
		this.userService.getCurrentUser().then(user => {
			this.gtag.event('Eingeloggt als', {
				method: 'Eingeloggt als',
				event_category: 'Nutzergruppe',
				event_label: user.userGroup.title
			});
		});
	}
}
