import { Injectable } from '@angular/core';
import { CategoryDto } from './dto/category.dto';
import { Resolve } from '@angular/router';
import { DownloadAreaService } from './download-area.service';


@Injectable()
export class DownloadCategoriesResolver implements Resolve<CategoryDto[]> {
	constructor(private readonly downloadAreaService: DownloadAreaService) {
	}

	public resolve() {
		return this.downloadAreaService.getCategories();
	}
}
