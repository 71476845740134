import { Directive, OnInit, Output, EventEmitter } from '@angular/core';
@Directive({
	selector: '[init]'
})
export class InitDirective implements OnInit {

	@Output()
	init = new EventEmitter();

	public ngOnInit(): void {
		this.init.emit();
	}
}
