import { Component, EventEmitter, Output, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
	host: {
		class: 'action-card'
	}
})
export class ActionCardComponent {

	@Input()
	public href: RouterLink['routerLink'];

	@Input()
	public title: string;

	@Output()
	public onDelete = new EventEmitter();

	@Output()
	public onEdit = new EventEmitter();
}
