import { Pipe, PipeTransform } from '@angular/core';
import { User } from './user';

@Pipe({
	name: 'userFilter',
	pure: true
})
export class UserFilterPipe implements PipeTransform {

	/**
	 * Filters the user array by the search query and returns the filtered array.
	 */
	public transform(users: User[], query: string): User[] {
		if (query.length === 0) {
			return users;
		}
		if (typeof users === 'undefined') {
			return [];
		}

		return users.filter(user => {
			return user.name.toLowerCase().includes(query.toLowerCase());
		});
	}
}
