import { Component, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
})
export class ModalComponent {
	/**
	 * View child for rendering component
	 */
	@ViewChild('component', {read: ViewContainerRef})
	public componentViewContainerRef: ViewContainerRef;

	constructor(
			public readonly modalService: ModalService,
			private readonly resolver: ComponentFactoryResolver
	) {
		this.modalService.modalStateSubject.subscribe(() => {

			this.renderComponent();
		})
	}

	public close() {
		this.modalService.close();
	}

	/**
	 * Renders the component of the current message
	 */
	private renderComponent(): void {
		this.componentViewContainerRef?.clear(); // Remove previously rendered component
		if (this.modalService.currentComponent === null) {
			return;
		}
		const componentFactory = this.resolver.resolveComponentFactory(this.modalService.currentComponent);
		const componentRef = this.componentViewContainerRef.createComponent(componentFactory);
		Object.assign(componentRef.instance, {additionalData: this.modalService.currentData}); // pass the message object to the component
	}
}
