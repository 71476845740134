import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DownloadAreaService } from './download-area.service';
import { DocumentDto } from './dto/document.dto';


@Injectable()
export class DownloadDocumentResolver implements Resolve<DocumentDto[]> {
	constructor(private readonly downloadAreaService: DownloadAreaService) {
	}

	public resolve() {
		return this.downloadAreaService.getDocuments();
	}
}

