import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AjaxLoaderService {
	private timeout: number;

	private counter: number = 0;

	isVisible() {
		return this.counter >= 1;
	}

	/**
	 * Show state
	 */
	public async show(hasTimeout: boolean = false): Promise<void> {
		this.counter++;

		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		if (!hasTimeout) {
			return;
		}
		this.timeout = window.setTimeout(() => {
			this.hide();
		}, 5000);
	}

	/**
	 * Hide state
	 */
	public async hide(): Promise<void> {
		if (this.counter > 0) {
			this.counter--;
		} else {
			this.counter = 0;
		}
	}
}
