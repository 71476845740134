import { Injectable, Renderer2, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';

type Error = {
	file: string;
	header: string;
};

@Injectable({
	providedIn: 'root'
})
export class AdminErrorMessageService {
	errors: Error[] = [];
	errorsSubject: Subject<any> = new Subject<any>();
	private elementRef!: ElementRef;
	private renderer!: Renderer2;

	public addError(file: string, header: string): void {
		let hasErrors = false;

		this.errors.map((error) => {
			if (error.file === file && !error.header?.includes(header)) {
				hasErrors = true;
				error.header += `, ${header}`;
				this.errorsSubject.next(this.errors);
			}
			if (error.header?.includes(header)) {
				hasErrors = true;
			}
		})

		if (hasErrors) return;

		this.errors.push({file, header});
		this.errorsSubject.next(this.errors);
		this.removeParentClass('is-hidden');
	}

	setElement(el: ElementRef, renderer: Renderer2) {
		this.elementRef = el;
		this.renderer = renderer;
	}

	removeParentClass(className: string) {
		if (this.elementRef && this.renderer) {
			const parent = this.elementRef.nativeElement.parentElement;
			if (parent) {
				this.renderer.removeClass(parent, className);
			}
		}
	}
}
