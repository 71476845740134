export enum GROUP {
	none = '',
	ZUB = 'ZUB',
	EBR = 'EBR',
	RGE = 'RGE',
	ROR = 'ROR',
	BLA = 'BLA',
	BST = 'BST',
	ERI = 'ERI',
	EKU = 'EKU',
	EFE = 'EFE',
	ESM = 'ESM',
	ELA = 'ELA',
	EBM = 'EBM',
	SCH = 'SCH',
}
