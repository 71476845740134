import { Injectable } from '@angular/core';
import { STATIC_PAGE_DATA } from '../constant';


@Injectable({
	providedIn: 'root',
})
export class StaticPageService {
	static getPageContentByRoute(name): string {
		return STATIC_PAGE_DATA[name].content || '';
	}

	static getPageTitleByRoute(name): string {
		return STATIC_PAGE_DATA[name].title || '';
	}
}
