import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { Gtag } from 'angular-gtag';
import { ArticleListService } from '../article-list/article-list.service';
import { DatabaseQueryCondition } from '../article-list/database-query-condition';
import { User } from '../authorization/user/user';
import { PRICE_LIST_DATE } from '../constant';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { ProductService } from '../generic-product-table/product.service';
import {
	NOTIFICATION_INFO,
	NOTIFICATION_SUCCESS,
	NotificationService,
} from '../layout/notification/notification.service';
import { Wishlist } from '../wishlist/wishlist';
import { WishlistService } from '../wishlist/wishlist.service';
import { PriceCalculator } from './price-calculator';
import { PriceCalculatorService } from './price-calculator.service';

declare const _etracker;
declare const et_UserDefinedEvent;

@Component({
	selector: 'app-price-calculator',
	templateUrl: './price-calculator.component.html',
	styleUrls: ['./price-calculator.component.scss'],
})
export class PriceCalculatorComponent implements OnInit {
	@Input()
	isChinese = false;

	@ViewChild(GenericDialogComponent) wishlistDialog: GenericDialogComponent;

	public mainGroup: string = '';
	public seriesOptions = [];
	public groupOptions = [];
	public cornOptions = [];
	public calculationForm: PriceCalculator;
	public step = 0;
	public sizeDisabled: boolean = false;
	public currentUser: User;
	public groups = this.productService.getGroups();
	public PRICE_LIST_DATE = PRICE_LIST_DATE;
	public smaVisible: boolean = false;
	public smaOptions: any = [];
	public connections: any = [];
	public dimensions: any = [];
	public smaSelected: boolean = false;
	public dimensionSelected: boolean = false;

	public wishlists: Wishlist[] = [];
	public newWishlistTitle: string;

	constructor(
			private readonly priceCalculatorService: PriceCalculatorService,
			private readonly notification: NotificationService,
			private readonly route: ActivatedRoute,
			private readonly gtag: Gtag,
			private readonly wishlist: WishlistService,
			private readonly productService: ProductService,
			public readonly articleListService: ArticleListService,
			private readonly translate: TranslatePipe,
			private readonly wishListService: WishlistService
	) {
		this.calculationForm = priceCalculatorService.calculationForm;
		this.calculationForm.reset();
	}

	public async ngOnInit(): Promise<void> {
		this.route.data.subscribe(({user}) => {
			this.currentUser = user;
		});
		this.priceCalculatorService.isChinese = this.isChinese;
	}

	/**
	 * Shows or hides prices for users by role
	 */
	public canUserSeeGroup(group: string): boolean {
		for (let i = 0; i < this.currentUser.disallowedGroups.length; i++) {
			if (this.currentUser.disallowedGroups[i].id === group) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Show main group selection
	 */
	public async mainGroupSelect(): Promise<void> {
		await this.priceCalculatorService.selectMainGroup(this.mainGroup, this.priceCalculatorService.calculationForm);
		await this.filterGroupsBasedByMainGroup();
		if (this.step <= 0) {
			this.step = 1;
		}
		this.resetPrice();
	}

	public groupSelect(): void {
		this.priceCalculatorService.selectGroup(this.calculationForm.group, this.priceCalculatorService.calculationForm);
	}

	/**
	 * Show series selection
	 */
	public async seriesSelect(): Promise<void> {
		const {series, group} = this.calculationForm;
		this.calculationForm.sma = group ? group[0] : '';//Sets sma to the first letter of the group. If no group is set, an empty string will be used
		this.smaSelected = false;
		if (this.mainGroup === 'zubehoer') {
			this.smaOptions = this.priceCalculatorService.createOptionsArray(
					await this.priceCalculatorService.getAccessorySmas(series),
					0
			);
			this.smaVisible = true;
		} else {
			this.smaVisible = false;
			await this.priceCalculatorService.selectSeries(
					this.mainGroup,
					series,
					this.priceCalculatorService.calculationForm
			);
			if (this.step == 1 && series !== '' && series !== undefined) {
				this.step = 2;
				this.resetPrice();
			} else if (this.step > 1) {
				await this.widthSelect();
			}
			await this.filterCornBasedBySeries();
		}
	}

	/**
	 * Show width selection
	 */
	public async widthSelect(): Promise<void> {
		if (this.step == 2) {
			const {length, width} = this.calculationForm;
			await this.priceCalculatorService.selectWidth(width, length, this.priceCalculatorService.calculationForm);
			this.step = 3;
			this.resetPrice();
		} else if (this.step > 2) {
			await this.lengthSelect();
		}
	}

	/**
	 * Show length selection
	 */
	public async lengthSelect(): Promise<void> {
		if (this.step == 3) {
			const {length, width} = this.calculationForm;
			await this.priceCalculatorService.selectLength(length, width, this.priceCalculatorService.calculationForm);
			this.step = 4;
			this.resetPrice();
		} else if (this.step > 3) {
			await this.cornSelect();
		}
	}

	private resetPrice() {
		this.calculationForm.netPrice = 0;
		this.calculationForm.netPriceWithDiscount = 0;
	}

	public getLengthLabel(): string {
		const unit = this.getUnit();
		return this.calculationForm.group == 'SCH' ? `Innendurchmesser (${unit})` : `Länge (${unit})`;
	}

	/**
	 * Setting units for "rolle" and "jumbo"
	 */
	public getUnit(): 'm' | 'mm' {
		const isMetres = this.mainGroup === 'rolle' || this.mainGroup === 'jumbo';
		return isMetres ? 'm' : 'mm';
	}

	public async cornSelect(): Promise<void> {
		if (this.step == 4) {
			const {length, corn, width} = this.calculationForm;
			await this.priceCalculatorService.selectCorn(
					width,
					length,
					this.mainGroup,
					corn,
					this.priceCalculatorService.calculationForm
			);
		}
		this.resetPrice();
	}

	/**
	 * Adding filter for corn based series by checking used groups
	 */
	private async filterCornBasedBySeries(): Promise<void> {
		const groups = PriceCalculatorComponent.filterAllowedGroupsByMainGroup(this.mainGroup);
		const databaseFile = await this.articleListService.getPfFile('710');
		// noinspection JSNonASCIINames
		const filteredDatabase = databaseFile
				.select('von Korn')
				.where({
					Hauptgruppe: new DatabaseQueryCondition('%[]', groups),
					Serie: new DatabaseQueryCondition('%l', this.calculationForm.series),
					Löschkennzeichen: new DatabaseQueryCondition('!=', 'L'),
					Ignorieren: new DatabaseQueryCondition('==', 'N'),
				})
				.groupBy('von Korn')
				.orderBy('von Korn')
				.execute();
		this.cornOptions = this.priceCalculatorService.createOptionsArray(filteredDatabase, 0);
	}

	/**
	 * Setting filters for several groups
	 */
	private static filterAllowedGroupsByMainGroup(mainGroup: string): string[] {
		switch (mainGroup) {
			case 'band':
				return ['esm', 'efe', 'eri', 'ebr', 'eku', 'ela', 'ebm'];
			case 'scheibe':
				return ['SCH'];
			case 'rolle':
				return ['rge'];
			case 'jumbo':
				return ['ror'];
			case 'blattware':
				return ['bst', 'bla'];
		}
		return [];
	}

	private async filterGroupsBasedByMainGroup(): Promise<void> {
		if (this.mainGroup === 'zubehoer') {
			// Make the accessory types into options
			this.seriesOptions = this.priceCalculatorService
					.createOptionsArray(
							await this.priceCalculatorService.getAccessoryTypes(), // Get the accessory types
							0 // index of the label in the database result
					)
					.map((item) => {
						// Translate the label of the options
						item.label = this.translate.transform(item.label);
						return item;
					});
		} else {
			// If the user wants to see something else, pull the data from the main files
			let allowed = PriceCalculatorComponent.filterAllowedGroupsByMainGroup(this.mainGroup);
			this.groupOptions = allowed.map((item) => ({
				value: item.toUpperCase(),
				label: item.toUpperCase(),
			}));
			await this.filterSeriesBasedByAllowedGroups(allowed);
		}
	}

	private async filterSeriesBasedByAllowedGroups(groups): Promise<void> {
		const databaseFile = await this.articleListService.getPfFile('710');

		// noinspection JSNonASCIINames
		const filteredDatabase = databaseFile
				.select('Serie')
				.where({
					Hauptgruppe: new DatabaseQueryCondition('%[]', groups),
					Löschkennzeichen: new DatabaseQueryCondition('!=', 'L'),
				})
				.groupBy('Serie')
				.orderBy('Serie')
				.execute();
		let options = this.priceCalculatorService.createOptionsArray(filteredDatabase, 0);
		this.seriesOptions = options.filter((value) => {
			for (let i = 0; i < this.currentUser.disallowedSeries.length; i++) {
				if (this.currentUser.disallowedSeries[i].id.toLowerCase().trim() === value.value.toLowerCase().trim()) {
					return false;
				}
			}
			return true;
		});
	}

	/**
	 * Price calculation
	 */
	public async calculatePrice(): Promise<void> {
		const {length, corn, series, width, smaOption, group} = this.calculationForm;

		if (group === 'SCH' && series === 'KK670J') {
			this.calculationForm.sma += '*L*';
		}

		try {
			await this.calculationForm.refreshProductPrice(this.mainGroup); // possibly throws error if combination is not valid
			this.gtag.event('Artikelkombination berechnet', {
				method: 'Artikelkombination berechnet',
				event_category: 'Preisrechner Artikelkombination',
				event_label: `${this.mainGroup}-${series}-${width}-${length}-${
						this.mainGroup === 'zubehoer' ? smaOption : corn
				}`,
			});
		} catch (e) {
			this.notification.notify('No price for configuration found!', NOTIFICATION_INFO);
		}
	}

	public async smaSelect(): Promise<void> {
		const {series, smaOption} = this.calculationForm;
		this.dimensions = this.priceCalculatorService.createOptionsArray(
				await this.priceCalculatorService.getAccessoryDimensions(series, smaOption),
				0
		);
		this.dimensionSelected = false;
		this.smaSelected = true;
	}

	public async dimensionSelect(): Promise<void> {
		const {series, smaOption} = this.calculationForm;
		this.connections = this.priceCalculatorService.createOptionsArray(
				await this.priceCalculatorService.getAccessoryConnections(series, smaOption),
				0
		);
		this.dimensionSelected = true;
	}

	get isTeller(): boolean {
		return (
				this.calculationForm?.series?.includes('TELLER') && this.calculationForm.smaOption !== '' && this.smaSelected
		);
	}

	public async openWishlistModal(): Promise<void> {
		await this.fetchWishlists();
		this.wishlistDialog.open();
	}

	public async addProductToWishList(id: number): Promise<void> {
		await this.wishListService.addToWishlist({
			wishlistId: id,
			mainGroup: this.mainGroup,
			product: this.calculationForm,
		});
		this.wishlistDialog.close();
		this.notification.notify(`Erfolgreich zu Merkliste hinzugefügt.`, NOTIFICATION_SUCCESS);
	}

	public async addWishlist(): Promise<void> {
		await this.wishListService.addWishlist(this.newWishlistTitle);
		await this.fetchWishlists();
		this.notification.notify(`Neue Merkliste "${this.newWishlistTitle}" erstellt.`, NOTIFICATION_SUCCESS);
		this.newWishlistTitle = '';
	}

	private async fetchWishlists() {
		this.wishlists = await this.wishListService.getWishlists();
	}

	eTrackerSendEventProductGroupSelection(group: string) {
		_etracker.sendEvent(new et_UserDefinedEvent('Preisrechner', group, 'Produktgruppe wählen', 'Click'));
	}

	eTrackerSendEventAddToWishlist(series: string, title: string) {
		_etracker.sendEvent(
				new et_UserDefinedEvent('Merkliste Produktauswahl', `${series} in Merkliste aufgenommen`, title, 'Click')
		);
	}
}
