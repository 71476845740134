import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { AdminErrorMessageService } from './admin-error-message.service';

@Component({
	selector: 'admin-error-message',
	templateUrl: './admin-error-message.component.html',
	styleUrls: ['./admin-error-message.component.scss'],
})
export class AdminErrorMessageComponent implements OnInit {
	errors: {file: string, header: string}[] = [];

	constructor(
			private readonly adminErrorMessageService: AdminErrorMessageService,
			private el: ElementRef,
			private renderer: Renderer2) {
	}

	public ngOnInit(): void {
		this.errors = [];
		this.adminErrorMessageService.errorsSubject.subscribe((error: {file: string, header: string}[]): void => {
			this.errors = error;
		});
		this.adminErrorMessageService.setElement(this.el, this.renderer);
	}
}
