import { Component, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

	public message: string;
	public param: any;
	public type: number;
	public visible: boolean = false;
	private timeout;

	constructor(private readonly notification: NotificationService) {
	}

	ngOnInit() {
		this.notification.onMessage((message, type, param = {}, duration) => {
			clearTimeout(this.timeout);
			this.message = message;
			this.type = type;
			this.param = param;
			this.visible = true;
			this.timeout = setTimeout(() => {
				this.visible = false;
			}, duration);
		});
	}
}
