import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { UserNationality } from './user-nationality';
import { CachedAjaxService } from '../../../services/cached-ajax.service';

@Injectable({
	providedIn: 'root',
})
export class UserNationalityService {
	constructor(
			private readonly authenticationService: AuthenticationService,
			private readonly cachedAjaxService: CachedAjaxService
	) {
	}

	/**
	 * Returns an array of label object (of groups) that can be displayed in a select
	 */
	public async getAllNationalitiesForCheckboxes(): Promise<any[]> {
		const nationalities = await this.getAllNationalities();
		return nationalities.map(nationality => ({
			name: nationality.name,
			id: nationality.id,
		}));
	}

	/**
	 * Returns a list of groups
	 */
	public async getAllNationalities(): Promise<UserNationality[]> {
		const nationalities = await this.cachedAjaxService.fetchRequest({
			url: 'user-nationalities',
			cached: true,
		});
		if (!nationalities.length) {
			throw new Error('Failed to get nationalities');
		}
		return nationalities;
	}
}
