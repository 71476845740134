import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '../app.module';
import { LayoutModule } from '../layout/layout.module';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormModule } from '../form/form.module';
import { CostBenefitRoutingModule } from './cost-benefit-routing.module';
import { ShowComponent } from './pages/show/show.component';
import { CostBenefitService } from './cost-benefit.service';
import { PrintComponent } from './pages/print/print.component';
import { BarChartModule } from '@swimlane/ngx-charts';
import { AbsPipe } from '../pipes/abs.pipe';
import {GtagModule} from "angular-gtag";

@NgModule({
	declarations: [
		ShowComponent,
		PrintComponent,
		AbsPipe
	],
    imports: [
        BarChartModule,
        FormModule,
        LayoutModule,
        CommonModule,
        CostBenefitRoutingModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GtagModule,
    ],
	providers: [
		CostBenefitService,
		CurrencyPipe,
		TranslatePipe
	]
})
export class CostBenefitModule {
}
