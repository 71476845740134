import { Injectable } from '@angular/core';
import { CachedAjaxService } from '../services/cached-ajax.service';

@Injectable({
	providedIn: 'root',
})
export class ProductFinderEsService {

	constructor(private readonly cachedAjaxService: CachedAjaxService) {
	}

	public async getProductFinderLink(series): Promise<string> {
		const url = `https://vsmabrasivos.com/productos/${series.toLowerCase().trim()}/`;
		const {success} = await this.cachedAjaxService.fetchRequest({
			url: `product/?url=${encodeURIComponent(url)}`
		});
		return success ? url : '';
	}
}
