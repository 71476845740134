import { Injectable } from '@angular/core';
import { AuthGuard } from './auth-guard.service';
import { USER_ADMIN, USER_SALES } from '../user/user-group/user-group';
import { URL } from '../../generic-types';

/**
 * This guard prevents user without admin rights to access a route that is protected by this guard
 */
@Injectable()
export class UserAdminSalesGuardService extends AuthGuard {

	/**
	 * Checks whether the user has admin rights or not
	 */
	public async checkLogin(url: URL): Promise<boolean> {
		const user = await this.userService.getCurrentUser();
		if (user.userGroup.id === USER_ADMIN || user.userGroup.id === USER_SALES) {
			return true;
		}
		this.authenticationService.redirectUrl = url;
		await this.router.navigate(['/home']);
		return false;
	}
}
