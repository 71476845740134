import { Component, Input, ContentChild, ElementRef, AfterContentInit } from '@angular/core';

@Component({
	selector: 'form-field',
	templateUrl: './form-field.component.html',
})
export class FormFieldComponent implements AfterContentInit{
	@Input() tooltip?: string;
	@Input() placeholder?: string = ' ';
	@Input() id = Math.random().toString(36).substr(2, 9);

	@ContentChild('inputRef') input: ElementRef<HTMLInputElement>;

	ngAfterContentInit(): void {
		this.input.nativeElement.placeholder = this.placeholder;
		this.input.nativeElement.id = this.id;
	}
}
