import { DatabaseQueryCondition } from './article-list/database-query-condition';
import { GROUP } from './price-calculator/groups';

/**
 * Number utility class.
 */
export class NumberUtility {
	/**
	 * formats the passed value into a number. If the passed number is neither a number nor a string, the function returns 0.
	 */
	public static variableToNumber(value: string | number): number {
		if (typeof value === 'number') {
			return value;
		}
		if (typeof value === 'string') {
			return +value.replace(',', '.');
		}
		return 0;
	}

	/**
	 * Calculates the area of a circle based by the passed group, width and length.
	 */
	public static productAreaCalculation(group: GROUP, width: number, length: number): number {
		if (group === GROUP.SCH) {
			const magicNumber = 40000;
			return Math.PI * width * width / magicNumber;
		} else if (DatabaseQueryCondition.staticCompareLike('R**', group)) {
			const magicNumber = 10;
			return width / magicNumber;
		} else if (DatabaseQueryCondition.staticCompareLike('E**', group) && group !== GROUP.EBR) {
			const magicNumber = 10000;
			return width * length / magicNumber;
		} else if (group === GROUP.ZUB) {
			return 0;
		}
		const magicNumber = 10000;
		return width * length / magicNumber;
	}
}
