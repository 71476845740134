import { Component, OnInit } from '@angular/core';
import { StaticPageService } from './static-page.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-static-page',
	templateUrl: './static-page.component.html'
})
export class StaticPageComponent implements OnInit {
	constructor(private readonly route: ActivatedRoute) {
	}

	public pageData: string = '';
	public pageTitle: string = '';

	ngOnInit(): void {
		this.route.data.subscribe(({name}) => {
			this.pageData = StaticPageService.getPageContentByRoute(name);
			this.pageTitle = StaticPageService.getPageTitleByRoute(name);
		});
	}
}
