import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from './input.component';

declare const _etracker;
declare const et_UserDefinedEvent;

@Component({
	selector: 'form-select',
	templateUrl: './select.component.html',
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true},
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectComponent), multi: true}
	],
	styleUrls: ['./select.component.scss']
})
export class SelectComponent extends InputComponent implements ControlValueAccessor, OnInit {
	@Input()
	public options: any = [];

	public ngOnInit(): void {
		if (typeof this.model === 'undefined') {
			this.model = '';
		}
		this.modelChange.subscribe(val => {
			if (typeof val === 'undefined') {
				setTimeout(() => {
					this.model = '';
				}, 300);
			}
		});
	}

	public triggerChange($event: any): void {
		this.modelChange.next($event)
	}

	eTrackerSendEvent(model: string) {
		_etracker.sendEvent(new et_UserDefinedEvent('Preisrechner', `${model} wurde ausgewählt`, 'Serie wählen', 'Click'))
	}
}
