import { Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root',
})

/**
 * Shows notifications and removes it after 6 seconds
 */
export class NotificationService {
	private callback: any = () => {
	};

	public onMessage(callback: any) {
		this.callback = callback;
	}

	public notify(message, type = NOTIFICATION_INFO, param = {}, duration = 6000): void {
		this.callback(message, type, param, duration);
	}
}

/**
 * Notification states
 */
export const NOTIFICATION_INFO = 1;
export const NOTIFICATION_SUCCESS = 2;
export const NOTIFICATION_ERROR = 3;
