import { TranslatePipe } from '@ngx-translate/core';
import { CostBenefitService } from './cost-benefit.service';

type TranslationHelpers<T> = {
	[K in keyof T]: (str: string) => string;
}

export abstract class CommonTranslations implements TranslationHelpers<CommonTranslations> {
	protected constructor(
			protected readonly translatePipe: TranslatePipe,
			protected readonly costBenefitService: CostBenefitService,
	) {
	}

	intlWithWorkpieceUnit(str: string) {
		return this.translatePipe.transform(str, {
			unit: this.translatePipe.transform(this.costBenefitService.calculationForm.workpieceUnit)
		})
	}

	get intlPerUnitPerCurrency() {
		return this.translatePipe.transform("je Werkstück [in {{currency}}]", {
			unit: this.translatePipe.transform(this.costBenefitService.calculationForm.workpieceUnit),
			currency: this.costBenefitService.calculationForm.currency
		})
	}
}
