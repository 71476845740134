import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import { Injector, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { GtagModule } from "angular-gtag";
import { DBConfig, NgxIndexedDBModule } from "ngx-indexed-db";
import { NgxMasonryModule } from "ngx-masonry";
import { SimplebarAngularModule } from "simplebar-angular";
import { ActionCardComponent } from "./action-card/action-card.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./authorization/login/login.component";
import { ResetPasswordComponent } from "./authorization/reset-password/reset-password.component";
import { SetPasswordComponent } from "./authorization/reset-password/set-password.component";
import { AdminGuard } from "./authorization/services/admin-guard.service";
import { AuthGuard } from "./authorization/services/auth-guard.service";
import { ChineseNationalityGuard } from "./authorization/services/chinese-nationality-guard.service";
import { GermanNationalityGuard } from "./authorization/services/german-nationality-guard.service";
import { NotAuthGuard } from "./authorization/services/not-auth-guard.service";
import { SpanishNationalityGuard } from "./authorization/services/spanish-nationality-guard.service";
import { UserAdminSalesGuardService } from "./authorization/services/user-admin-sales-guard.service";
import { AbstractUserFormComponent } from "./authorization/user/abstract.user-form.component";
import { UserAdminComponent } from "./authorization/user/user-admin/user-admin.component";
import { UserFilterPipe } from "./authorization/user/user-filter.pipe";
import { UserAddComponent } from "./authorization/user/user-forms/user-add.component";
import { UserEditComponent } from "./authorization/user/user-forms/user-edit.component";
import { UserComponent } from "./authorization/user/user.component";
import { UserResolver } from "./authorization/user/user.resolver";
import { CardComponent } from "./card/card.component";
import { ConfirmComponent } from "./confirm/confirm.component";
import { CostBenefitModule } from "./cost-benefit/cost-benefit.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AfterViewInitDirective } from "./directives/after-view-init.directive";
import { InitDirective } from "./directives/init.directive";
import { DownloadAreaModule } from "./download-area/download-area.module";
import { FormModule } from "./form/form.module";
import { SelectComponent } from "./form/select.component";
import { GenericDialogComponent } from "./generic-dialog/generic-dialog.component";
import { GenericProductTableComponent } from "./generic-product-table/generic-product-table.component";
import { LayoutModule } from "./layout/layout.module";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { FormatPricePipe } from "./pipes/format-price.pipe";
import { ProductGroupFilterPipe } from "./pipes/product-group-filter.pipe";
import { UcfirstPipe } from "./pipes/ucfirst.pipe";
import { UserLastLoginDatePipe } from "./pipes/user-last-login-date.pipe";
import { UserNationalitiesFilterPipe } from "./pipes/user-nationalities-filter.pipe";
import { PriceCalculatorCnComponent } from "./price-calculator-cn/price-calculator-cn.component";
import { PriceCalculatorEsComponent } from "./price-calculator-es/price-calculator-es.component";
import { ProductDetailLinkEsComponent } from "./price-calculator-es/product-detail-link-es/product-detail-link-es.component";
import { PriceCalculatorAccessComponent } from "./price-calculator/price-calculator-access/price-calculator-access.component";
import { PriceCalculatorComponent } from "./price-calculator/price-calculator.component";
import { ProductDetailLinkComponent } from "./price-calculator/product-detail-link/product-detail-link.component";
import { PrintableTableComponent } from "./printable-table/printable-table.component";
import { ServiceLocator } from "./service-locator";
import { StaticPageComponent } from "./static-page/static-page.component";
import { SwitchPageComponent } from "./switch-page/switch-page.component";
import { ProductEsTableContentComponent } from "./wishlist-es/product-es-table-content/product-es-table-content.component";
import { WishlistEsDetailPrintComponent } from "./wishlist-es/wishlist-es-detail/wishlist-es-detail-print/wishlist-es-detail-print.component";
import { WishlistEsDetailComponent } from "./wishlist-es/wishlist-es-detail/wishlist-es-detail.component";
import { WishlistEsComponent } from "./wishlist-es/wishlist-es.component";
import { WishlistDetailComponent } from "./wishlist/wishlist-detail/wishlist-detail.component";
import { WishlistComponent } from "./wishlist/wishlist.component";
import { AdminErrorMessageComponent } from './admin-error-message/admin-error-message.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const dbConfig: DBConfig = {
	name: "MyDb",
	version: 1,
	objectStoresMeta: [
		{
			store: "databaseFile",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "name", keypath: "name", options: { unique: false } },
				{ name: "csvData", keypath: "name", options: { unique: false } },
				{ name: "groupedField", keypath: "groupedField", options: { unique: false } },
				{ name: "header", keypath: "name", options: { unique: false } },
				{ name: "orderDirection", keypath: "name", options: { unique: false } },
				{ name: "orderField", keypath: "name", options: { unique: false } },
				{ name: "whereClauses", keypath: "name", options: { unique: false } },
				{ name: "selectedField", keypath: "name", options: { unique: false } },
				{ name: "orderMultiple", keypath: "email", options: { unique: false } },
			],
		},
	],
};

@NgModule({
	declarations: [
		AbstractUserFormComponent,
		AppComponent,
		LoginComponent,
		ResetPasswordComponent,
		DashboardComponent,
		UserComponent,
		PriceCalculatorComponent,
		PriceCalculatorEsComponent,
		GenericProductTableComponent,
		CardComponent,
		SwitchPageComponent,
		StaticPageComponent,
		UserAdminComponent,
		UserAddComponent,
		UserEditComponent,
		SetPasswordComponent,
		UcfirstPipe,
		UserFilterPipe,
		ProductGroupFilterPipe,
		UserNationalitiesFilterPipe,
		UserLastLoginDatePipe,
		FormatPricePipe,
		ConfirmComponent,
		ProductDetailLinkComponent,
		ProductDetailLinkEsComponent,
		PriceCalculatorAccessComponent,
		PageNotFoundComponent,
		WishlistComponent,
		WishlistDetailComponent,
		PrintableTableComponent,
		ActionCardComponent,
		GenericDialogComponent,
		WishlistEsComponent,
		WishlistEsDetailComponent,
		ProductEsTableContentComponent,
		WishlistEsDetailPrintComponent,
		InitDirective,
		AfterViewInitDirective,
		PriceCalculatorCnComponent,
		AdminErrorMessageComponent
	],
	imports: [
		FormModule,
		LayoutModule,
		DownloadAreaModule,
		CostBenefitModule,
		GtagModule.forRoot({ trackingId: "UA-141246951-1", trackPageviews: true }),
		BrowserModule,
		AppRoutingModule,
		SimplebarAngularModule,
		NgxMasonryModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		NgxIndexedDBModule.forRoot(dbConfig),
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: true,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000",
		}),
	],
	providers: [
		AuthGuard,
		NotAuthGuard,
		AdminGuard,
		UserAdminSalesGuardService,
		GermanNationalityGuard,
		SpanishNationalityGuard,
		ChineseNationalityGuard,
		UserResolver,
		DatePipe,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: LOCALE_ID, useValue: "de" },
	],
	exports: [SelectComponent],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private readonly injector: Injector) {
		// Create global Service Injector.
		ServiceLocator.injector = this.injector;
	}
}

registerLocaleData(localeFr, "fr");
registerLocaleData(localeDe, "de");
registerLocaleData(localeEs, "es");
