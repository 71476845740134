export const NAVIGATION_ELEMENTS = [
	{ label: "Start", icon: "icon-home", path: "/home", action: "", category: "", params: "" },
	{
		label: "Dokumente",
		icon: "icon-download-area",
		path: "/download-area",
		action: "Dokumentsbereich",
		category: "",
		params: "Dokumentsbereich Navigation wurde aufgerufen",
	},
	{ label: "Nutzerdaten", icon: "icon-person", path: "/user", action: "", category: "", params: "" },
];
