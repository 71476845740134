import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authorization/services/authentication.service';
import { Papa } from 'ngx-papaparse';
import { CryptoService } from '../services/crypto.service';
import { DatabaseFile } from './database-file';
import { CachedAjaxService } from '../services/cached-ajax.service';
import { asyncTimeout } from '../functions/async-timeout';

type CountryCode = 'de' | 'es';

@Injectable({
	providedIn: 'root',
})
export class ArticleListService {
	/**
	 * Defines whether the service has parsed the database file or not.
	 */
	public parsedCSV: boolean = false;
	/**
	 * A Promise that resolves to the list of database files.
	 */
	private pfFilePromise: Promise<DatabaseFile[]> = null;

	constructor(
			private readonly authenticationService: AuthenticationService,
			private readonly papa: Papa,
			private readonly crypto: CryptoService,
			private readonly cachedAjaxService: CachedAjaxService
	) {
	}

	/**
	 * Returns a promise that resolves to a list of all database files.
	 */
	private getPfFilePromise(countryCode: CountryCode = 'de'): Promise<DatabaseFile[]> {
		if (this.pfFilePromise === null) {
			this.pfFilePromise = this.initializeAllPfFiles(countryCode);
		}
		return this.pfFilePromise;
	}

	/**
	 * Basically the entry point to retrieve all PF files (this method always returns a single pf files based by the passed identifier)
	 */
	public async getPfFile(id: string, countryCode: CountryCode = 'de'): Promise<DatabaseFile | undefined> {
		const files = await this.getPfFilePromise(countryCode);
		return files.find(file => file.name === id);
	}

	/**
	 * Retrieves all PF Files from the server and decrypts them with the access token (acts as key).
	 * An array with the files is then returned
	 */
	private async initializeAllPfFiles(countryCode: CountryCode = 'de'): Promise<DatabaseFile[]> {
		const token = await this.authenticationService.getAuthToken();
		if (token === null) { // This is a fix for the first session of a user or if the user remove local storage auth token
			await asyncTimeout(2000);
			return await this.initializeAllPfFiles()
		}

		const urls: Record<typeof countryCode, string> = {
			de: 'pf-files',
			es: 'pf-files-es',
		} as const;

		const response = await this.cachedAjaxService.fetchRequest({
			url: urls[countryCode],
			cached: true,
		});
		if (response === null) { // This is a retry in the case the request failed because a firewall blocked it
			return await this.initializeAllPfFiles();
		}
		if (response.success) {
			return await this.decryptAndParsePfFiles2(response.data, token);
		}
		throw new Error('Failed to retrieve PF files');
	}

	/**
	 * Decrypts the passed data with the passed key.
	 * The then decrypted data is then JSON parsed to retrieve ALL PF Files.
	 * The files are then parsed by the csv parser and resolved by the promise with name of the file
	 */
	private async decryptAndParsePfFiles2(data: string, key: string): Promise<DatabaseFile[]> {
		const decrypted = JSON.parse(this.crypto.decrypt(data, key));
		const databaseFiles = [];
		for (let name in decrypted) {
			if (decrypted.hasOwnProperty(name)) {
				databaseFiles.push(await this.parseCsvWithName(name, decrypted[name]));
			}
		}
		return databaseFiles;
	}

	/**
	 * Parses the passed csv and resolves it in combination with the passed name
	 */
	public parseCsvWithName(name: string, csv: string): Promise<DatabaseFile> {
		return new Promise(resolve => {
			this.papa.parse(csv, {
				delimiter: ';',
				complete: result => {
					resolve(new DatabaseFile(name, result));
				}
			});
			this.parsedCSV = true;
		});
	}
}
