import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authorization/services/authentication.service';
import { ArticleListService } from '../article-list/article-list.service';
import { DatabaseQueryCondition } from '../article-list/database-query-condition';
import { DatabaseFile, ORDER_BY_ASC } from '../article-list/database-file';
import { PriceCalculator } from './price-calculator';
import { NumberUtility } from '../numberUtility';
import { Product } from './product';
import { GROUP } from './groups';

interface Test {
	value: any;
	label: string;
}

interface Properties {
	group: GROUP;
	sma: any;
	series: any;
	width: any;
	length: any;
	corn: any;
	markings: any;
}

interface MaterialPriceCheck {
	price: string;
	cornLine: string;
	fiber: string;
}

export const ERROR_PRODUCT_INVALID_COMBINATION = 1;//@todo: show message for invalid combination
export const ERROR_PRODUCT_MISSING_INFORMATION = 2;//@todo: show message for missing information

@Injectable({
	providedIn: 'root',
})
export class PriceCalculatorService {
	public calculationForm: PriceCalculator;
	public currentCalculationError: number = 0;

	constructor(
			private readonly authenticationService: AuthenticationService,
			private readonly articleListService: ArticleListService
	) {
		this.calculationForm = new PriceCalculator(this);
	}

	public async calculatePriceForProduct(product: Product, form: PriceCalculator): Promise<number | '-'> {
		await this.selectMainGroup(product.mainGroup, form);
		await this.selectSeries(product.mainGroup, product.series, form);
		await this.selectWidth(product.width, product.length, form);
		await this.selectLength(product.length, product.width, form);
		await this.selectCorn(product.width, product.length, product.mainGroup, product.corn, form);
		try {
			return await this.queryCurrentNetPrice(form);
		} catch (e) {
			return '-';
		}
	}

	public async selectMainGroup(mainGroup: string, form: PriceCalculator): Promise<void> {
		await this.selectGroupByMainGroup(mainGroup, form);
		form.series = '';
	}

	public selectGroup(group: string, form: PriceCalculator): void {
		if (group.toUpperCase() === 'SCH') {
			form.length = '22';
		}
	}

	public async selectSeries(mainGroup: string, series: string, form: PriceCalculator): Promise<void> {
		form.series = series;
		if (series !== '' && mainGroup === 'jumbo') {
			const res = await this.getMinWidthByCriteria(form.series, 'ROR');
			form.width = res.length === 1 ? res[0][0] : ''; // Pre-select width if found in 710PF
		}
	}

	public async selectWidth(width?: string, length?: string, form?: PriceCalculator): Promise<void> {
		form.width = width;
		form.length = length;
		if (width !== '' && width !== undefined && PriceCalculatorService.is230x280(width, length)) {
			await form.setGroup(GROUP.BLA);
		}
	}

	public async selectLength(length?: string, width?: string, form?: PriceCalculator): Promise<void> {
		form.width = width;
		form.length = length;
		if (length !== '' && length !== undefined && PriceCalculatorService.is230x280(width, length)) {
			await form.setGroup(GROUP.BLA);
		}
	}

	private static is230x280(width?: string, length?: string): boolean {
		return `${width}` === '230' && `${length}` === '280';
	}

	public async selectCorn(swidth: string, slength: string, mainGroup: string, corn?: string, form?: PriceCalculator): Promise<void> {
		if (corn !== '' && corn) {
			form.corn = corn;
			let width = parseInt(swidth);
			let length = parseInt(slength);

			if (mainGroup === 'blattware') {
				if (width === 230 && length === 280) {
					await form.setGroup(GROUP.BLA);
				} else {
					await form.setGroup(GROUP.BST);
				}
			} else if (mainGroup === 'band') {
				if (width <= 60 && width > 21 && length <= 377 && length >= 30) {
					await form.setGroup(GROUP.ERI);
				} else if (width <= 20 && width >= 6 && length <= 760 && length >= 201) {
					await form.setGroup(GROUP.EFE);
				} else if (width <= 499 && width >= 3 && length >= 761 && length <= 1000) {
					await form.setGroup(GROUP.EKU);
				} else if (width <= 499 && width >= 21 && length >= 378 && length <= 760) {
					await form.setGroup(GROUP.EKU);
				} else if (width <= 499 && width >= 61 && length >= 30 && length <= 377) {
					await form.setGroup(GROUP.EKU);
				} else if (width <= 300 && width >= 3 && length >= 1600 && length <= 4500) {
					await form.setGroup(GROUP.ESM);
				} else if (width <= 499 && width >= 3 && length >= 1001 && length <= 1599) {
					await form.setGroup(GROUP.ESM);
				} else if (width <= 300 && width >= 40 && length >= 4501 && length <= 27500) {
					await form.setGroup(GROUP.ELA);
				} else if (width <= 499 && width >= 301 && length >= 1600 && length <= 4500) {
					await form.setGroup(GROUP.EBM);
				} else if (width >= 500 && length >= 1200 && length <= 4500) {
					await form.setGroup(GROUP.EBR);
				}
			}
		}
	}

	public async selectGroupByMainGroup(mainGroup: string, form: PriceCalculator): Promise<void> {
		switch (mainGroup) {
			case 'scheibe':
				return form.setGroup(GROUP.SCH);
			case 'rolle':
				return form.setGroup(GROUP.RGE);
			case 'jumbo':
				return form.setGroup(GROUP.ROR);
		}
		return form.setGroup(GROUP.none);
	}

	public async queryCurrentNetPrice(calculationForm: PriceCalculator, mainGroup: string = ''): Promise<number> {
		if (mainGroup === 'zubehoer') {
			const [minOrderUnits, articleNumber, price] = await this.getAccessoryPrice(
					calculationForm.series,
					calculationForm.smaOption,
					calculationForm.dimension,
					calculationForm.connection,
			);
			calculationForm.articleNumber = articleNumber;
			calculationForm.minOrderUnits = minOrderUnits;
			return +price.replace(',', '.') * 100;
		}
		this.currentCalculationError = 0;
		if (!calculationForm.allValuesSet()) {
			this.currentCalculationError = ERROR_PRODUCT_MISSING_INFORMATION;
			throw new Error('ERROR_PRODUCT_MISSING_INFORMATION');
		}
		calculationForm._debugValues._isValidForPriceCheck = await this.isValidForPriceCheck(calculationForm);

		if (!calculationForm._debugValues._isValidForPriceCheck) {
			this.currentCalculationError = ERROR_PRODUCT_INVALID_COMBINATION;
			throw new Error('ERROR_PRODUCT_INVALID_COMBINATION');
		}

		calculationForm._debugValues._sma = calculationForm.sma;

		const {group, sma, series, width, length, corn, markings} = await this.substituteProperties(calculationForm);
		calculationForm._debugValues._group = group;

		calculationForm._debugValues._series = series;
		calculationForm._debugValues._width = +width;
		calculationForm._debugValues._length = +length;
		calculationForm._debugValues._corn = +corn;
		calculationForm._debugValues._markings = +markings;

		const fixedPrice = await this.searchInFixedPriceTable(group, sma, series, width, length, corn, markings);

		let area = NumberUtility.productAreaCalculation(group, +width, +length);
		calculationForm._debugValues._area = area;
		calculationForm._debugValues._fixedPrice = fixedPrice;

		const [
			priceIncreaseFactor,
			generalSurcharge,
			rWidthSurcharge,
			dimensionSurcharge,
			qmSurcharge,
			widthSurcharge,
			wastePercentage,
			changeFactor,
			{price, cornLine, fiber},//bei fiberserien wird hier der materialwert gezogen (SFIB Inland DM/100STK)
			backingFactor
		] = await Promise.all([
			this.getPriceIncreaseFactor(group, sma, series),//Preiserhöhungsfaktoren
			this.getGeneralSurcharge(group, sma, series, width, length, markings),//ZuschlägeAllgemein
			this.getRWidthSurcharge(group, width),//LohnzuschlägeRBreite
			this.getDimensionSurcharge(group, width, length),//LohnzuschlägeAbmessung
			this.getQmSurcharge(group, area),//LohnzuschlägeQM
			this.getWidthSurcharge(group, sma, series, width),//LohnzuschlägeBreite
			this.getWastePercentage(group, sma, series, width, area),//Verschnittprozentsätze
			this.getChangeFactor(group, sma, series, width),//Änderungsfaktoren
			this.getMaterialPriceCheck(series),//Materialwerte
			this.getBackingFactor(group, series, area)//Preisfaktor neue Kalkulation
		]);

		calculationForm._debugValues._cornLine = +cornLine;

		const cornFactor = await this.getCornFactor(cornLine, corn);

		let _priceIncreaseFactor = NumberUtility.variableToNumber(priceIncreaseFactor);
		let _generalSurcharge = NumberUtility.variableToNumber(generalSurcharge);
		let _rWidthSurcharge = NumberUtility.variableToNumber(rWidthSurcharge);
		let _dimensionSurcharge = NumberUtility.variableToNumber(dimensionSurcharge);
		let _qmSurcharge = NumberUtility.variableToNumber(qmSurcharge);
		let _widthSurcharge = NumberUtility.variableToNumber(widthSurcharge);
		let _wastePercentage = NumberUtility.variableToNumber(wastePercentage);
		let _changeFactor = NumberUtility.variableToNumber(changeFactor);
		let _price = NumberUtility.variableToNumber(price);
		let _cornFactor = NumberUtility.variableToNumber(cornFactor);
		let _fiber = NumberUtility.variableToNumber(fiber);
		let _backingFactor = backingFactor ? NumberUtility.variableToNumber(backingFactor) : 1;

		calculationForm._debugValues._priceIncreaseFactor = _priceIncreaseFactor;
		calculationForm._debugValues._generalSurcharge = _generalSurcharge;
		calculationForm._debugValues._rWidthSurcharge = _rWidthSurcharge;
		calculationForm._debugValues._dimensionSurcharge = _dimensionSurcharge;
		calculationForm._debugValues._qmSurcharge = _qmSurcharge;
		calculationForm._debugValues._widthSurcharge = _widthSurcharge;
		calculationForm._debugValues._wastePercentage = _wastePercentage;
		calculationForm._debugValues._changeFactor = _changeFactor;
		calculationForm._debugValues._price = _price;
		calculationForm._debugValues._cornFactor = _cornFactor;
		calculationForm._debugValues._fiber = _fiber;
		calculationForm._debugValues._backingFactor = _backingFactor;

		let netPrice;
		if (fixedPrice) {
			netPrice = +`${fixedPrice}`.replace(',', '.');
		} else {
			if (group == 'SCH' && series.substring(1, 2) == 'F') {
				netPrice = (_fiber * _changeFactor * _cornFactor * ((100 + _wastePercentage) / 100) + _widthSurcharge + _qmSurcharge + _dimensionSurcharge + (_rWidthSurcharge * area / 100) + (_generalSurcharge * area * ((100 + _wastePercentage) / 100) / 100)) * _priceIncreaseFactor;
			} else {
				netPrice = ((_price / 100) * _cornFactor * _backingFactor * area * ((100 + _wastePercentage) / 100) + _widthSurcharge + _qmSurcharge + _dimensionSurcharge + (_rWidthSurcharge * area / 100) + (_generalSurcharge * area * ((100 + _wastePercentage) / 100) / 100)) * _priceIncreaseFactor;
				if (_widthSurcharge === 0 && _qmSurcharge == 0 && _dimensionSurcharge == 0 && _rWidthSurcharge === 0 && !(group == 'SCH' && series.substring(1, 2) == 'F')) {
					netPrice = 0;
				}
			}
		}
		/*if (netPrice < 100) {
			netPrice = Math.round(netPrice * 10 + 0.4) / 10;
		} else {
			netPrice = Math.round(netPrice + 0.49);
		}*/

		return netPrice * 100;
	}

	public async getBackingFactor(group: string, series: string, area: number): Promise<any> {
		return this.selectOneFrom('723', 'V23FKTI', {
			'V23ARTG': new DatabaseQueryCondition('%', series),
			'V23ARTH': new DatabaseQueryCondition('==', group),
			'V23QMHU': new DatabaseQueryCondition('<=', area),
		}, [
			['V23QMHU', ORDER_BY_ASC]
		]);
	}

	public async getAccessoryTypes(): Promise<any> {
		const databaseFile = await this.articleListService.getPfFile('Zubehoer');
		return databaseFile.select('Produkt').groupBy('Produkt').execute();
	}

	public async getAccessorySmas(product: string): Promise<any> {
		const databaseFile = await this.articleListService.getPfFile('Zubehoer');
		return databaseFile.select('Typ').where({
			'Produkt': new DatabaseQueryCondition('==', product)
		}).groupBy('Typ').execute();
	}

	public async getAccessoryPrice(product: string, type: string, abmessung: string, befestigung: string): Promise<any> {
		const where = {
			'Produkt': new DatabaseQueryCondition('==', product),
			'Typ': new DatabaseQueryCondition('==', type)
		};
		if (product.includes('TELLER')) {
			where['Abmessung'] = new DatabaseQueryCondition('==', abmessung);
			where['Befestigung'] = new DatabaseQueryCondition('==', befestigung);
		}
		return this.selectFirst('Zubehoer', ['DWMGMA', 'DWARTN', 'Bruttopreis/100'], where);
	}

	/**
	 * Returns the packaging price based by the passed arguments
	 */
	public getPackaging(group, series, sma, width, length, corn, markings): Promise<any> {
		// noinspection JSNonASCIINames
		return this.selectOneFrom('VerpackungNachArtikelnummer', 'VPE', {
			'Serie': new DatabaseQueryCondition('%', series),
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'Länge': new DatabaseQueryCondition('==', parseInt(length)),
			'Breite': new DatabaseQueryCondition('==', parseInt(width)),
			'ZNr': new DatabaseQueryCondition('==', parseInt(markings)),
			'Korn': new DatabaseQueryCondition('==', parseInt(corn))
		});
	}

	/**
	 * Returns the minimum order amount based by the passed product composition
	 */
	public getMinimumOrderAmount(group, series, sma, width, length, corn): Promise<any> {
		// noinspection JSNonASCIINames
		return this.selectOneFrom('MindestbestellmengeNachArtikelnummer', 'Mindestbestellmenge', {
			'Serie': new DatabaseQueryCondition('%', series),
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'Länge': new DatabaseQueryCondition('==', parseInt(length)),
			'Breite': new DatabaseQueryCondition('==', parseInt(width)),
			'Korn': new DatabaseQueryCondition('==', parseInt(corn))
		});
	}

	/**
	 * Creates an array for select options based by the passed attributes.
	 * "undefined" values are filtered
	 */
	public createOptionsArray(filteredDatabase, labelIndex, valueIndex = false): Test[] {
		const response = [];
		for (let i = 0; i < filteredDatabase.length; i++) {
			if (typeof filteredDatabase[i][valueIndex || labelIndex] === 'undefined' || filteredDatabase[i][valueIndex || labelIndex].trim() === '') {
				continue;
			}
			response.push({
				value: filteredDatabase[i][valueIndex || labelIndex],
				label: filteredDatabase[i][labelIndex]
			});
		}
		return response;
	}

	/**
	 * Returns the "Preiserhöhungsfaktor" based by the passed criteria
	 */
	public getPriceIncreaseFactor(group, sma, series): Promise<any> {
		return this.selectOneFrom('780', 'Faktor-Inland', {
			'Serie': new DatabaseQueryCondition('%', series),
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
		});
	}

	/**
	 * Returns the "Zuschlag Allgemein" based by the passed criteria
	 */
	public getGeneralSurcharge(group, sma, series, width, length, markings): Promise<any> {
		// noinspection JSNonASCIINames
		return this.selectOneFrom('770', 'Zuschlag DM/100QM', {
			'Serie': new DatabaseQueryCondition('%', series),
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'SMA': new DatabaseQueryCondition('%', sma),
			'bis Länge': new DatabaseQueryCondition('>=', length),
			'bis Breite': new DatabaseQueryCondition('>=', width),
			'von Zeichnung': new DatabaseQueryCondition('<=', markings),
			'bis Zeichnung': new DatabaseQueryCondition('>=', markings),
			'Löschkennzeichen': new DatabaseQueryCondition('!=', 'L'),
		});
	}

	/**
	 * Returns the "Lohnzuschlag Breite" based by the passed criteria
	 */
	public getRWidthSurcharge(group, width): Promise<any> {
		// noinspection JSNonASCIINames
		return this.selectOneFrom('733', 'Lohnzuschlag DM/100STK', {
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'bis Breite': new DatabaseQueryCondition('>=', width),
			'Löschkennzeichen': new DatabaseQueryCondition('!=', 'L')
		});
	}

	/**
	 * Returns the "Lohnzuschlag QM" based by the passed criteria
	 */
	public getQmSurcharge(group, area): Promise<any> {
		return this.selectOneFrom('731', 'Lohnzuschlag DM/100STK', {
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'Bis QM/100Stk': new DatabaseQueryCondition('>=', area)
		});
	}

	/**
	 * Returns the "Lohnzuschlag Breite" based by the passed criteria
	 */
	public getWidthSurcharge(group, sma, series, width): Promise<any> {
		return this.selectOneFrom('730', 'Lohnzuschlag DM/100STK', {
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'SMA': new DatabaseQueryCondition('%', sma),
			'Serie': new DatabaseQueryCondition('%', series),
			'Durchmesser / Breite': new DatabaseQueryCondition('>=', width)
		});
	}

	/**
	 * Returns the "Lohnzuschlag Abmessung" based by the passed criteria
	 */
	public getDimensionSurcharge(group, width, length): Promise<any> {
		// noinspection JSNonASCIINames
		return this.selectOneFrom('732', 'Lohnzuschlag DM/100STK', {
			'Hauptgruppe': new DatabaseQueryCondition('==', group),
			'bis Breite': new DatabaseQueryCondition('>=', width),
			'bis Länge': new DatabaseQueryCondition('>=', length)
		}, [
			['bis Länge', ORDER_BY_ASC],
			['bis Breite', ORDER_BY_ASC]
		]);
	}

	/**
	 * Returns the "Verschnitt Prozentsatz" based by the passed criteria
	 */
	public getWastePercentage(group, sma, series, width, flaeche): Promise<any> {
		return this.selectOneFrom('760', 'Verschnitt-%', {
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'SMA': new DatabaseQueryCondition('%', sma),
			'Serie': new DatabaseQueryCondition('%', series),
			'bis QM/100Stk': new DatabaseQueryCondition('>=', flaeche),
			'bis Breite': new DatabaseQueryCondition('>=', width)
		}, [['Serie', ORDER_BY_ASC]]);
	}

	/**
	 * Returns the "Änderungsfaktor" based by the passed criteria
	 */
	public getChangeFactor(group, sma, series, width): Promise<string> {
		return this.selectOneFrom('722', 'Durchm-Faktor Inland', {
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'SMA': new DatabaseQueryCondition('%', sma),
			'Serie': new DatabaseQueryCondition('%', series),
			'Durchmesser / Breite': new DatabaseQueryCondition('>=', width)
		});
	}

	/**
	 * Returns the "Kornfaktor" based by the passed criteria
	 */
	public getCornFactor(cornLine, corn): Promise<string> {
		// noinspection JSNonASCIINames
		return this.selectOneFrom('721', 'Kornfaktor', {
			'Körnungslinie': new DatabaseQueryCondition('==', cornLine),//12
			'bis Korn': new DatabaseQueryCondition('>=', corn),
			'Löschkennzeichen': new DatabaseQueryCondition('!=', 'L')
		});
	}

	/**
	 * Returns the minimum width of the passed series and group
	 */
	public getMinWidthByGroupAndSeries(series, group): Promise<any> {
		return this.selectOneFrom('710', 'von Breite', {
			'Serie': new DatabaseQueryCondition('%', series),
			'Hauptgruppe': new DatabaseQueryCondition('%', group)
		});
	}

	/**
	 * Returns the minimum width of the passed series and group and corn
	 */
	public async getMinWidthByCriteria(series, group): Promise<any> {
		const databaseFile = await this.articleListService.getPfFile('710');
		let result = databaseFile.select('von Breite').where({
			'Serie': new DatabaseQueryCondition('%', series),
			'Hauptgruppe': new DatabaseQueryCondition('%', group)
		}).groupBy('von Breite').execute();
		if (result && result[0] && result[0][0]) {
			return result;
		}
		return undefined;
	}

	/**
	 * Searches for a fixed price for the configured product
	 */
	public searchInFixedPriceTable(group, sma, series, width, length, corn, markings): Promise<any> {
		// noinspection JSNonASCIINames
		return this.selectOneFrom('790', 'Endpreis DM/100Stk Inland', {
			'Hauptgruppe': new DatabaseQueryCondition('%', group),
			'Serie': new DatabaseQueryCondition('===', series),
			'SMA': new DatabaseQueryCondition('%', sma),
			'von Breite': new DatabaseQueryCondition('<=', width),
			'bis Breite': new DatabaseQueryCondition('>=', width),
			'von Länge': new DatabaseQueryCondition('<=', length),
			'bis Länge': new DatabaseQueryCondition('>=', length),
			'von Korn': new DatabaseQueryCondition('<=', corn),
			'bis Korn': new DatabaseQueryCondition('>=', corn),
			'von Zeichnung': new DatabaseQueryCondition('<=', markings),
			'bis Zeichnung': new DatabaseQueryCondition('>=', markings),
			'Löschkennzeichen': new DatabaseQueryCondition('!=', 'L'),
		});
	}

	/**
	 * Check whether the current configuration is valid for configuration
	 */
	public async isValidForPriceCheck(calculationForm: PriceCalculator): Promise<boolean> {
		const databaseFile: DatabaseFile = await this.articleListService.getPfFile('710');
		// noinspection JSNonASCIINames
		let result = databaseFile.where({
			'Serie': new DatabaseQueryCondition('%', calculationForm.series),
			'Hauptgruppe': new DatabaseQueryCondition('%', calculationForm.group),
			'SMA': new DatabaseQueryCondition('%', calculationForm.sma),
			'von Breite': new DatabaseQueryCondition('<=', calculationForm.width),
			'bis Breite': new DatabaseQueryCondition('>=', calculationForm.width),
			'von Länge': new DatabaseQueryCondition('<=', calculationForm.length),
			'bis Länge': new DatabaseQueryCondition('>=', calculationForm.length),
			'von Korn': new DatabaseQueryCondition('<=', calculationForm.corn),
			'bis Korn': new DatabaseQueryCondition('>=', calculationForm.corn),
			'von Zeichnung': new DatabaseQueryCondition('<=', calculationForm.markings),
			'bis Zeichnung': new DatabaseQueryCondition('>=', calculationForm.markings),
			'Löschkennzeichen': new DatabaseQueryCondition('!=', 'L'),
			'Ignorieren': new DatabaseQueryCondition('==', 'N'),
		}).execute();
		if (result.length > 0) {
			const databaseFile: DatabaseFile = await this.articleListService.getPfFile('711');
			// noinspection JSNonASCIINames
			let result = databaseFile.where({
				'Serie': new DatabaseQueryCondition('%', calculationForm.series),
				'Hauptgruppe': new DatabaseQueryCondition('%', calculationForm.group),
				'SMA': new DatabaseQueryCondition('%', calculationForm.sma),
				'von Breite': new DatabaseQueryCondition('<=', calculationForm.width),
				'bis Breite': new DatabaseQueryCondition('>=', calculationForm.width),
				'von Länge': new DatabaseQueryCondition('<=', calculationForm.length),
				'bis Länge': new DatabaseQueryCondition('>=', calculationForm.length),
				'von Korn': new DatabaseQueryCondition('<=', calculationForm.corn),
				'bis Korn': new DatabaseQueryCondition('>=', calculationForm.corn),
				'von Zeichnung': new DatabaseQueryCondition('<=', calculationForm.markings),
				'bis Zeichnung': new DatabaseQueryCondition('>=', calculationForm.markings),
				'Löschkennzeichen': new DatabaseQueryCondition('!=', 'L')
			}).execute();

			return result.length > 0;
		}
		return false;
	}

	/**
	 * Gets the material price of a specific series
	 */
	public async getMaterialPriceCheck(series): Promise<MaterialPriceCheck> {
		const databaseFile = await this.articleListService.getPfFile('720');
		let result = databaseFile.select([
			'SMA ohne SFIB DM/QM',
			'Körnungslinie Inl',
			'SFIB Inland DM/100STK'
		]).where({
			'Artikel-Gruppe': new DatabaseQueryCondition('%', series)
		}).execute();
		if (result && result[0] && result[0][0]) {
			return {
				price: result[0][0],
				cornLine: result[0][1],
				fiber: result[0][2]
			};
		}
		return {
			price: '0',
			cornLine: '0',
			fiber: '0'
		};
	}

	/**
	 * This method substitutes the passed properties based by the pf file 715.
	 */
	public async substituteProperties(calculationForm: PriceCalculator): Promise<Properties> {
		const databaseFile = await this.articleListService.getPfFile('715');
		// noinspection JSNonASCIINames
		let result = databaseFile.select([
			'subst Hauptgruppe',
			'subst SMA',
			'subst Serie',
			'subst Breite',
			'subst Länge',
			'subst Korn',
			'subst Zeichnung'
		]).where({
			'Serie': new DatabaseQueryCondition('%', calculationForm.series),
			'Hauptgruppe': new DatabaseQueryCondition('%', calculationForm.group),
			'SMA': new DatabaseQueryCondition('%', calculationForm.sma),
			'von Breite': new DatabaseQueryCondition('<=', calculationForm.width),
			'bis Breite': new DatabaseQueryCondition('>=', calculationForm.width),
			'von Länge': new DatabaseQueryCondition('<=', calculationForm.length),
			'bis Länge': new DatabaseQueryCondition('>=', calculationForm.length),
			'Löschkennzeichen': new DatabaseQueryCondition('!=', 'L'),
		}).execute();
		return substituteArgumentsByResultSet(result);

		function substituteArgumentsByResultSet(result): Properties {
			let _group = calculationForm.group;
			let _sma = calculationForm.sma;
			let _series = calculationForm.series;
			let _width = calculationForm.width;
			let _length = calculationForm.length;
			let _corn = calculationForm.corn;
			let _markings = calculationForm.markings;

			if (result.length > 0) {
				for (let i = 0; i < result.length; i++) {
					_group = substituteIfNeeded<GROUP>(result[i], 0, _group);
					_sma = substituteIfNeeded(result[i], 1, _sma);
					_series = substituteIfNeeded(result[i], 2, _series);
					_width = substituteIfNeeded(result[i], 3, _width);
					_length = substituteIfNeeded(result[i], 4, _length);
					_corn = substituteIfNeeded(result[i], 5, _corn);
					_markings = substituteIfNeeded(result[i], 6, _markings);
				}
			}

			return {
				group: _group,
				sma: _sma,
				series: _series,
				width: _width,
				length: _length,
				corn: _corn,
				markings: _markings
			};

			function substituteIfNeeded<T extends string>(resultArray, index, old: T): T {
				if (resultArray[index].replace(/\*/g, '').trim().length > 0) {
					return resultArray[index].trim() as T;
				}
				return old;
			}
		}
	}

	/**
	 * Executes a select query on the passed table for the passed field with the passed where condition.
	 * Basically returns either the first row and first column or undefined if nothing was found.
	 */
	private async selectOneFrom(table: string, field: string | string[], where: any, orderMultiple?: any): Promise<any> {
		const result = await this.selectFirst(table, field, where, orderMultiple);
		return result && result[0] ? result[0] : undefined;
	}


	/**
	 * Executes a select query on the passed table for the passed field with the passed where condition.
	 * Basically returns either the first row and first column or undefined if nothing was found.
	 */
	private async selectFirst(table: string, field: string | string[], where: any, orderMultiple?: any): Promise<any> {
		const databaseFile = await this.articleListService.getPfFile(table);
		let sub = databaseFile.select(field).where(where);
		if (typeof orderMultiple !== 'undefined') {
			sub = sub.orderByMultiple(orderMultiple);
		}
		let result = sub.execute();
		return result && result[0] ? result[0] : undefined;
	}

	public async getAccessoryConnections(product: string, smaOption: string): Promise<any> {
		const databaseFile = await this.articleListService.getPfFile('Zubehoer');
		return databaseFile.select('Befestigung').where({
			'Produkt': new DatabaseQueryCondition('==', product),
			'Typ': new DatabaseQueryCondition('==', smaOption)
		}).groupBy('Befestigung').execute();
	}

	public async getAccessoryDimensions(product: string, smaOption: string): Promise<any> {
		const databaseFile = await this.articleListService.getPfFile('Zubehoer');
		return databaseFile.select('Abmessung').where({
			'Produkt': new DatabaseQueryCondition('==', product),
			'Typ': new DatabaseQueryCondition('==', smaOption)
		}).groupBy('Abmessung').execute();
	}
}
