import { Injectable } from '@angular/core';
import { CategoryDto } from './dto/category.dto';
import { DocumentDto } from './dto/document.dto';
import { CachedAjaxService } from '../services/cached-ajax.service';
import { DownloadCategoryGroup } from './pages/list/list.component';
import { Subject } from 'rxjs';

@Injectable()
export class DownloadAreaService {

	public entityChangedSubject: Subject<any> = new Subject<any>();

	constructor(private readonly cachedAjaxService: CachedAjaxService) {

	}

	//DOCUMENT

	public async getDocuments(): Promise<DocumentDto[]> {
		return this.cachedAjaxService.fetchRequest({
			url: 'documents'
		});
	}

	public async addDocument(documentDto: DocumentDto): Promise<void> {
		if (typeof documentDto.file !== 'string') {
			const uploadedFile = await this.cachedAjaxService.fetchUpload('upload', documentDto.file as File) as any;
			documentDto.file = uploadedFile.identifier;
		}

		const addedDocument = await this.cachedAjaxService.fetchRequest({
			url: 'document',
			body: {document: documentDto},
			method: 'POST'
		});
		this.entityChangedSubject.next(addedDocument);
		return addedDocument;
	}

	public async updateDocument(documentDto: DocumentDto): Promise<void> {
		if (typeof documentDto.file !== 'string') {
			const uploadedFile = await this.cachedAjaxService.fetchUpload('upload', documentDto.file as File) as any;
			documentDto.file = uploadedFile.identifier;
		}

		const updatedDocument = await this.cachedAjaxService.fetchRequest({
			url: 'document/' + documentDto.id,
			body: {document: documentDto},
			method: 'PUT'
		});
		this.entityChangedSubject.next(updatedDocument);
		return updatedDocument;
	}

	public async deleteDocument(documentDto: DocumentDto): Promise<void> {
		const deletedDocument = await this.cachedAjaxService.fetchRequest({
			url:'document/' + documentDto.id,
			method: 'DELETE'
		});
		this.entityChangedSubject.next(deletedDocument);
		return deletedDocument;
	}

	//CATEGORY

	public async getCategories(): Promise<CategoryDto[]> {
		return this.cachedAjaxService.fetchRequest({
			url: 'categories'
		})
	}

	public async addCategory(categoryDto: CategoryDto): Promise<void> {
		const categoryAdded = await this.cachedAjaxService.fetchRequest({
			url: 'category',
			body: categoryDto,
			method: 'POST'
		});
		this.entityChangedSubject.next(categoryAdded);
		return categoryAdded;
	}

	public async updateCategory(categoryDto: CategoryDto): Promise<void> {
		const changedCategory = await this.cachedAjaxService.fetchRequest({
			url: 'category/' + categoryDto.id,
			body: categoryDto,
			method: 'PUT'
		});
		this.entityChangedSubject.next(changedCategory);
		return changedCategory;
	}

	public async deleteCategory(categoryDto: CategoryDto): Promise<void> {
		const deletedCategory = await this.cachedAjaxService.fetchRequest({
			url:'category/' + categoryDto.id,
			method: 'DELETE'
		});
		this.entityChangedSubject.next(deletedCategory);
		return deletedCategory;
	}

	public createDownloadCategoryGroups(documents): DownloadCategoryGroup[] {
		return documents.reduce((prev, current) => {
			const foundIndex = prev.findIndex(x => x.category.id === current.category.id);
			if (foundIndex !== -1) {
				prev[foundIndex].documents.push(current);
			} else {
				prev.push({
					category: current.category,
					documents: [current]
				});
			}
			return prev;
		}, [] as DownloadCategoryGroup[])
				.sort((a, b) => a.category.sorting - b.category.sorting);
	}

}
