import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationElement, NavigationService } from './navigation.service';

declare const _etracker;
declare const et_UserDefinedEvent;

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
	public navigationIsOpen = true;
	public navigationElements: NavigationElement[] = [];

	constructor(
			private readonly navigationService: NavigationService,
			private readonly router: Router
	) {
	}

	public async ngOnInit(): Promise<void> {
		this.getNavigationStatus();
		this.navigationElements = await this.navigationService.getNavigationElements();
		if (NavigationComponent.isMobileMenu()) {
			this.navigationService.navigationStatus = false;
			this.navigationIsOpen = false;
		}
		window.scrollTo(0, 0);
		document.addEventListener('click', (event) => {
			if (NavigationComponent.isMobileMenu() || !NavigationComponent.isMobileMenu() || !this.navigationIsOpen) {
				return;
			}
			let element = event.target as Node;
			do {
				if (element.nodeName.toLowerCase() === 'app-navigation' || element.nodeName.toLowerCase() === 'app-burger') {
					return;
				}
			} while (element == element.parentNode);
			this.navigationService.toggleNavigationStatus();
		});
	}

	/**
	 * Check screen size for mobile menu
	 */
	public static isMobileMenu(): boolean {
		return window.innerWidth <= 1024;
	}

	/**
	 * checks if navigation is open or closed
	 */
	private getNavigationStatus(): void {
		this.navigationIsOpen = this.navigationService.navigationStatus;
		this.navigationService.navigationChange.subscribe(() => {
			this.navigationIsOpen = this.navigationService.navigationStatus;
		});
	}

	eTrackerSendEvent(item: NavigationElement) {
		_etracker.sendEvent(new et_UserDefinedEvent(item.action, item.params, item.category, 'Click'));
	}
}
