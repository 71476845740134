import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AjaxLoaderComponent } from './ajax-loader/ajax-loader.component';
import { AjaxLoaderService } from './ajax-loader/ajax-loader.service';
import { CookieMessageComponent } from './cookie-message/cookie-message.component';
import { TableComponent } from './table/table.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { BurgerComponent } from './navigation/burger/burger.component';
import { LanguageService } from './language/language.service';
import { LocalSettingsService } from './language/language-settings.service';
import { SwitchHeaderComponent } from './language/switch-header/switch-header.component';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './modal/modal.component';
import { ModalService } from './modal/modal.service';
import { InputComponent } from '../form/input.component';
import { FormsModule } from '@angular/forms';
import { LayoutService } from './layout.service';
import { FormModule } from '../form/form.module';
import {GtagModule} from "angular-gtag";
import { NotificationComponent } from './notification/notification.component';
import { NotificationService } from './notification/notification.service';

@NgModule({
	declarations: [
		LayoutComponent,
		NavigationComponent,
		HeaderComponent,
		FooterComponent,
		AjaxLoaderComponent,
		CookieMessageComponent,
		TableComponent,
		BurgerComponent,
		SwitchHeaderComponent,
		ModalComponent,
		NotificationComponent
	],
	providers: [
		AjaxLoaderService,
		LanguageService,
		LocalSettingsService,
		ModalService,
		LayoutService,
		NotificationService
	],
    imports: [
        FormModule,
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GtagModule,
    ],
	exports: [
		LayoutComponent,
		NavigationComponent,
		HeaderComponent,
		FooterComponent,
		AjaxLoaderComponent,
		CookieMessageComponent,
		TableComponent,
		BurgerComponent,
		SwitchHeaderComponent,
		ModalComponent,
		InputComponent,
		NotificationComponent
	]
})
export class LayoutModule {
}
