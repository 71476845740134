import { Product } from '../price-calculator/product';

/**
 * The wishlist class.
 */
export class Wishlist {
	/**
	 * the title of the wishlist
	 */
	public title: string;
	/**
	 * the id of the wishlist
	 */
	public id: number;
	/**
	 * the creation date of the wishlist
	 */
	public creationDate: Date;
	/**
	 * the products of the wishlist
	 */
	public products: Product[];

	constructor(wishlistData: Wishlist) {
		this.id = wishlistData.id;
		this.creationDate = wishlistData.creationDate;
		this.title = wishlistData.title;
		this.products = this.initProducts(wishlistData.products);
	}

	/**
	 * Initializes the products of the wishlist.
	 */
	private initProducts(products: Product[]): Product[] {
		return products.map(product => Product.init(product));
	}
}
