import { Injectable } from '@angular/core';
import { Wishlist } from './wishlist';
import { NotificationService } from '../layout/notification/notification.service';
import { AuthenticationService } from '../authorization/services/authentication.service';
import { CachedAjaxService } from '../services/cached-ajax.service';
import { Product } from '../price-calculator/product';
import { WishlistPriceCalculatorInterface } from './wishlist-price-calculator.interface';

@Injectable({
	providedIn: 'root',
})
export class WishlistService {
	constructor(
			private readonly notificationService: NotificationService,
			private readonly authenticationService: AuthenticationService,
			private readonly cachedAjaxService: CachedAjaxService,
	) {
	}

	/**
	 * Returns all wishlists of the currently logged-in user
	 */
	public async getWishlists(): Promise<Wishlist[]> {
		try {
			return await this.cachedAjaxService.fetchRequest({
				url: 'wishlist'
			});
		} catch (e) {
			return [];
		}
	}

	/**
	 * Returns a specific wishlist based by the passed id
	 */
	public async getWishlist(id: number): Promise<Wishlist> {
		return new Wishlist(await this.cachedAjaxService.fetchRequest({
			url: `wishlist/${id}`
		}));
	}

	/**
	 * Creates a new wishlist for the currently logged-in user
	 */
	public async addWishlist(title: string): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: 'wishlist',
			body: {title},
			method: 'POST'
		})
	}

	/**
	 * Removes a wishlist based by the passed id (if owned by the currently logged-in user)
	 */
	public async removeWishlist(id: number): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: `wishlist/${id}`,
			method: 'DELETE'
		});
	}

	/**
	 * Updates the title of the wishlist
	 */
	public async updateWishlist(wishlist: Wishlist): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: `wishlist/${wishlist.id}`,
			body: wishlist,
			method: 'PUT'
		});
	}

	/**
	 * Adds a product to the wishlist
	 */
	public async addToWishlist({ wishlistId, mainGroup, product }: {
		wishlistId: number,
		mainGroup: string,
		product: WishlistPriceCalculatorInterface
	}): Promise<void> {
		const {group, series, width, length, corn} = product
		await this.cachedAjaxService.fetchRequest({
			url: `wishlist/${wishlistId}/products`,
			body: {mainGroup, group, series, width, length, corn},
			method: 'POST'
		});
	}

	/**
	 * Removes a product from a wishlist
	 */
	public async removeFromWishlist(wishlist: Wishlist, product: Product): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: `wishlist/${wishlist.id}/${product.id}`,
			method: 'DELETE'
		});
	}
}
