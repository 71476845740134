import { Component, Input } from '@angular/core';
import { ProductFinderEsService } from '../product-finder-es.service';

@Component({
	selector: 'app-product-detail-link-es',
	templateUrl: './product-detail-link-es.component.html'
})
export class ProductDetailLinkEsComponent {

	private _series: string;
	public productLink: string = '';

	constructor(
			private readonly productFinder: ProductFinderEsService
	) {
	}

	@Input('class')
	public classList: string = '';

	@Input()
	set series(series) {
		this._series = series;
		this.productFinder.getProductFinderLink(series).then((link: string) => {
			this.productLink = link;
		});
	}

	get series(): string {
		return this._series;
	}
}
