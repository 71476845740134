import { Injectable } from '@angular/core';
import { CalculationResult } from './calculation-result';
import { CalculationForm } from './calculation-form';
import { CurrencyPipe } from '@angular/common';
import { SelectOption } from '../form/select-option.interface';
import { Gtag } from 'angular-gtag';
import { CachedAjaxService } from '../services/cached-ajax.service';

@Injectable()
export class CostBenefitService {
	/**
	 * All possible currency's.
	 * This should be moved into a separate module if it is used in more than one place.
	 */
	public readonly currencies: SelectOption[] = [
		{value: 'EUR', label: 'EUR'},
		{value: 'USD', label: 'USD'},
		{value: 'CAD', label: 'CAD'},
		{value: 'GBP', label: 'GBP'}
	];
	/**
	 * All possible units of measurements.
	 * This should be moved into a separate module if it is used in more than one place.
	 */
	public readonly unitOfMeasurements: SelectOption[] = [
		{value: 'metric', label: 'Metrisch'},
		{value: 'imperial', label: 'Imperial'}
	];
	/**
	 * All possible product groups.
	 * This should be moved into a separate module if it is used in more than one place.
	 */
	public readonly productGroups: SelectOption[] = [
		{value: 'disc', label: 'Scheibe'},
		{value: 'band', label: 'Band'}
	];
	/**
	 * All possible types of workpiece units.
	 * This should be moved into a separate module if it is used in more than one place.
	 */
	public readonly workpieceUnits: SelectOption[] = [
		{value: 'pieces', label: 'Stück'},
		{value: 'meter', label: 'Laufender Meter'}
	];
	/**
	 * The calculation formData.
	 * A new form is on initialization of this class.
	 */
	public calculationForm: CalculationForm = CostBenefitService.getNewCalculationForm();
	/**
	 * The calculation result if the form is submitted.
	 */
	public calculationResult: CalculationResult | null = null;
	/**
	 * The localstorage key used to store the form data.
	 */
	private readonly LOCALSTORAGE_KEY = 'cbc.form';

	constructor(
			private readonly cachedAjaxService: CachedAjaxService,
			private readonly currencyPipe: CurrencyPipe,
			private readonly gtag: Gtag
	) {
	}

	/**
	 * Returns an empty form with some default values.
	 */
	public static getNewCalculationForm(): CalculationForm {
		return {
			currency: 'EUR',
			workpieceUnit: 'pieces',
			stations: [{}],
			productGroup: ''
		};
	}

	/**
	 * Formats the passed string to a number if it is a number.
	 * Otherwise, NaN is returned.
	 */
	private static stringToNumber(string: string | number): number {
		let formattedString = `${string}`;
		const dotIndex = formattedString.indexOf('.');
		const commaIndex = formattedString.indexOf(',');
		// if a dot comes before a comma in the string, then it has to be a german locale
		if (commaIndex !== -1 && dotIndex < commaIndex) {
			formattedString = formattedString
					// Remove the dot (it is just used to separate the thousands)
					.replace('.', '')
					// Replace the comma (which is used to display decimals) with a dot
					.replace(',', '.');
		}

		// remove every character that is not a number
		const cleanedString = formattedString.replace(/[^\d.]/g, '');
		return cleanedString === '' ? NaN : +cleanedString;
	}

	/**
	 * Formats the passed value as currency with the currently selected currency in the calculationForm
	 */
	public appendCurrency = (value) => {
		return this.currencyPipe.transform(value, this.calculationForm.currency, 'code', '1.0-0');
	};

	/**
	 * Either gets the form from cache or initializes a new form if the cache is empty or corrupt.
	 */
	public async getCalculationFormFromCache(): Promise<void> {
		const data = window.localStorage.getItem(this.LOCALSTORAGE_KEY);
		if (data !== null) {
			try {
				this.calculationForm = JSON.parse(data) as CalculationForm;
				return;
			} catch (e) {
				// intentionally empty
			}
		}
		this.calculationForm = CostBenefitService.getNewCalculationForm();
	}

	/**
	 * Simply overrides the current cache with either the old data or writes a new entry if the cache is already empty.
	 */
	public async putCalculationFormToCache(form: CalculationForm): Promise<void> {
		window.localStorage.setItem(this.LOCALSTORAGE_KEY, JSON.stringify(form));
	}

	/**
	 * Sends the form to the backend where it can be stored in the database.
	 */
	public async writeCalculationToDatabase(): Promise<void> {
		await this.cachedAjaxService.fetchRequest({
			url: 'cost-benefit',
			method: 'POST',
			body: this.calculationForm,
		});
	}

	/**
	 * Checks if all the number fields of the form are valid numbers.
	 * This is just an additional check to prevent the user from entering invalid data.
	 */
	public isFormValidForCalculation(): boolean {
		const object = this.getFormNumberFieldValues();
		for (const objectKey in object) {
			if (isNaN(object[objectKey])) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Either returns a CalculationResult Object or null if the calculation was unsuccessful.
	 * @todo This method is a bit messed up and should be optimized in the future.
	 */
	public async calculate(): Promise<void> {
		this.benefitTrackingEvent();
		const {
			amount,
			machineCost,
			machiningTimePerWorkpiecePerMin,
			machiningTimePerWorkpiecePerMinVSM,
			setupTime,
			setupTimeVSM,
			workCost
		} = this.getFormNumberFieldValues();
		const totalNeededUnits = Math.ceil(this.calculationForm.stations.reduce((prev, curr) => {
			return prev + amount / CostBenefitService.stringToNumber(curr.units);
		}, 0));
		const totalNeededUnitsVSM = Math.ceil(this.calculationForm.stations.reduce((prev, curr) => {
			return prev + amount / CostBenefitService.stringToNumber(curr.unitsVSM);
		}, 0));
		const materialCost = this.calculationForm.stations.reduce((prev, curr) => {
			return prev + CostBenefitService.stringToNumber(curr.costs) / CostBenefitService.stringToNumber(curr.units);
		}, 0);
		const materialCostVSM = this.calculationForm.stations.reduce((prev, curr) => {
			return prev + CostBenefitService.stringToNumber(curr.costsVSM) / CostBenefitService.stringToNumber(curr.unitsVSM);
		}, 0);
		const workTime = (workCost + machineCost) / 60 * machiningTimePerWorkpiecePerMin;
		const workTimeVSM = (workCost + machineCost) / 60 * machiningTimePerWorkpiecePerMinVSM;
		const setupCost = (workCost + machineCost) / 60 * setupTime * totalNeededUnits / amount;
		const setupCostVSM = (workCost + machineCost) / 60 * setupTimeVSM * totalNeededUnitsVSM / amount;
		const totalCost = materialCost + workTime + setupCost;
		const totalCostVSM = materialCostVSM + workTimeVSM + setupCostVSM;
		const savingsPerYearHours = (totalNeededUnitsVSM * setupTimeVSM - totalNeededUnits * setupTime) / 60 + (machiningTimePerWorkpiecePerMinVSM * amount - machiningTimePerWorkpiecePerMin * amount) / 60;
		this.calculationResult = {
			totalNeededUnits,
			totalNeededUnitsVSM,
			materialCost,
			materialCostVSM,
			workTime,
			workTimeVSM,
			setupCost,
			setupCostVSM,
			totalCost,
			totalCostVSM,
			savingsPerYearPercentage: ((totalCost - totalCostVSM) / totalCost) * -1 * 100,
			savingsPerYearCosts: (totalCost * amount - amount * totalCostVSM) * -1,
			savingsPerYearMaterial: ((totalNeededUnitsVSM - totalNeededUnits) / totalNeededUnits) * 100,
			savingsPerYearHours,
			possibleWorkpieceIncrease: Math.floor((60 / machiningTimePerWorkpiecePerMinVSM) * -savingsPerYearHours),
			graph: {
				workpieces: this.calculationForm.stations.reduce((prev, curr, index) => {
					return [
						...prev,
						{
							'name': curr.seriesVSM || `VSM #${index + 1}`,
							'value': Math.round(amount / CostBenefitService.stringToNumber(curr.unitsVSM))
						},
						{
							'name': curr.series || `Customer #${index + 1}`,
							'value': Math.round(amount / CostBenefitService.stringToNumber(curr.units))
						}
					];
				}, [] as any[]),
				totalCost: this.calculationForm.stations.reduce((prev, curr, index) => {
					const materialCost = CostBenefitService.stringToNumber(curr.costs) / CostBenefitService.stringToNumber(curr.units);
					const materialCostVSM = CostBenefitService.stringToNumber(curr.costsVSM) / CostBenefitService.stringToNumber(curr.unitsVSM);
					return [
						...prev,
						{
							'name': curr.seriesVSM ? `${curr.seriesVSM} Sta. #${index + 1}` : `VSM #${index + 1}`,
							'value': materialCostVSM + workTimeVSM + setupCostVSM
						},
						{
							'name': curr.series ? `${curr.series} Sta. #${index + 1}` : `VSM #${index + 1}`,
							'value': materialCost + workTime + setupCost
						}
					];
				}, [] as any[]),
				totalSum: this.calculationForm.stations.reduce((prev, curr, index) => {
					return [
						...prev,
						{
							'name': curr.seriesVSM || `VSM #${index + 1}`,
							'value': (amount / CostBenefitService.stringToNumber(curr.unitsVSM)) * totalCostVSM
						},
						{
							'name': curr.series || `Customer #${index + 1}`,
							'value': (amount / CostBenefitService.stringToNumber(curr.units)) * totalCost
						}
					];
				}, [] as any[])
			}
		};
	}

	/**
	 * Appends an empty station to the form and updates the cache.
	 */
	public async appendStation(): Promise<void> {
		this.calculationForm.stations.push({});
		console.log(this.calculationForm.stations);
		await this.writeFormToCache();
	}

	/**
	 * Removes a station from the form and updates the cache.
	 */
	public async removeStationFromCalculationForm(index: number): Promise<void> {
		this.calculationForm.stations.splice(index, 1);
		await this.writeFormToCache();
	}

	/**
	 * Resets the form and updates the cache.
	 */
	public async resetCalculationForm(): Promise<void> {
		this.calculationForm = CostBenefitService.getNewCalculationForm();
		await this.writeFormToCache();
	}

	/**
	 * Writes the whole calculation form into the localstorage
	 */
	public async writeFormToCache(): Promise<void> {
		await this.putCalculationFormToCache(this.calculationForm);
	}

	/**
	 * Updates the currency of the graph label.
	 * This is a hack to force the graph to reload and use the new currency selected by the user
	 */
	public async updateGraph() {
		if (this.calculationResult?.graph) {
			this.calculationResult.graph.workpieces = [...this.calculationResult.graph.workpieces];
			this.calculationResult.graph.totalCost = [...this.calculationResult.graph.totalCost];
			this.calculationResult.graph.totalSum = [...this.calculationResult.graph.totalSum];
		}
		await this.writeFormToCache();
	}

	/**
	 * This method formats all relevant number fields into the correct format.
	 * @todo This method should be cleaned up in the future.
	 */
	private getFormNumberFieldValues() {
		const amount = CostBenefitService.stringToNumber(this.calculationForm.amount);
		const workCost = CostBenefitService.stringToNumber(this.calculationForm.workCost);
		const machineCost = CostBenefitService.stringToNumber(this.calculationForm.machineCost);
		const machiningTimePerWorkpiecePerMin = CostBenefitService.stringToNumber(this.calculationForm.machiningTimePerWorkpiecePerMin);
		const machiningTimePerWorkpiecePerMinVSM = CostBenefitService.stringToNumber(this.calculationForm.machiningTimePerWorkpiecePerMinVSM);
		const setupTime = CostBenefitService.stringToNumber(this.calculationForm.setupTime);
		const setupTimeVSM = CostBenefitService.stringToNumber(this.calculationForm.setupTimeVSM);
		return {
			amount,
			workCost,
			machineCost,
			machiningTimePerWorkpiecePerMin,
			machiningTimePerWorkpiecePerMinVSM,
			setupTime,
			setupTimeVSM
		}
	}

	public benefitTrackingEvent() {
		this.trackEvent('click', '', 'Liste mit ' + this.calculationForm.workpiece + ' je Werkstück', this.calculationForm.workpiece + ' Kalkulation je Werkstück');
		this.trackEvent('click', '', 'Liste mit ' + this.calculationForm.material + ' je Material', this.calculationForm.material + ' Kalkulation je Material');
		this.trackEvent('click', '', 'Liste mit ' + this.calculationForm.grindingApplication + ' je Schleifanwendung', this.calculationForm.grindingApplication + ' Kalkulation je Schleifanwendung');
		this.trackEvent('click', '', 'Liste mit ' + this.calculationForm.productGroup + ' je Schleifanwendung', this.calculationForm.productGroup + ' Kalkulation je Schleifanwendung');
		this.trackEvent('click', '', 'Liste mit ' + this.calculationForm.grindingApplication + ' je Schleifanwendung', this.calculationForm.grindingApplication + ' Kalkulation je Schleifanwendung');
		let stationSeriesLabel = '';
		this.calculationForm.stations.forEach((station, index) => {
			if (index !== 0) {
				stationSeriesLabel += ', ';
			}
			stationSeriesLabel += 'Konkurrenzserie:' + station.corn + ' je VSM Serie:' + station.cornVSM;
			this.trackEvent('click', '', 'Marktpreis der Serien', 'Konkurrenzserie:' + station.corn + ' Preis:' + station.costs);

		});
		this.trackEvent('click', '', 'Konkurrenzserie je VSM Serie', stationSeriesLabel);
	}

	/** trackon gtag event  */
	public trackEvent(event: string, category: string, action: string, label: string): void {
		this.gtag.event(event, {
			event_category: category,
			event_label: label,
			value: action
		});
	}
}
