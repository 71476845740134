/**
 * The ID of Germans
 */
export const NATIONALITY_GERMAN = 1;
/**
 * The ID of Spanish
 */
export const NATIONALITY_SPANISH = 2;
/**
 * The ID of Chinese
 */
export const NATIONALITY_CHINESE = 4;

/**
 * A class representing the nationality of the user.
 */
export class UserNationality {
	id: typeof NATIONALITY_GERMAN | typeof NATIONALITY_SPANISH | typeof NATIONALITY_CHINESE;
	name: string;
}
