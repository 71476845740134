import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-generic-product-table',
	templateUrl: './generic-product-table.component.html',
})
export class GenericProductTableComponent {
	@Input()
	public headline: string;
}
