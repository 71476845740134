import { NgModule } from '@angular/core';
import { InputComponent } from './input.component';
import { SelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GtagModule } from 'angular-gtag';
import { FormFieldComponent } from './form-field.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		InputComponent,
		SelectComponent,
		FormFieldComponent
	],
	imports: [
		FormsModule,
		CommonModule,
		GtagModule.forRoot({trackingId: 'UA-141246951-1', trackPageviews: true}),
		TranslateModule,
	],
	providers: [],
	exports: [
		InputComponent,
		SelectComponent,
		FormFieldComponent
	]
})
export class FormModule {
}
