import { Injectable } from '@angular/core';
import { Language } from './language';
import { CachedAjaxService } from '../../services/cached-ajax.service';

interface LanguageOption {
	label: string,
	value: string,
}

@Injectable({
	providedIn: 'root',
})
export class LanguageService {

	constructor(private readonly cachedAjaxService: CachedAjaxService) {
	}

	/**
	 * Returns a list of available languages which can be used to display in a select
	 */
	public async getLanguagesForSelect(): Promise<LanguageOption[]> {
		const languages = await this.getAllLanguages();
		return languages.map(({iso, long}) => {
			return {
				label: long,
				value: iso,
			};
		})
	}

	/**
	 * Returns all possible languages for the user
	 */
	public async getAllLanguages(): Promise<Language[]> {
		return this.cachedAjaxService.fetchRequest({
			url: 'languages',
			authenticated: false,
			cached: true,
		});
	}
}
