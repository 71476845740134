import { AdminErrorMessageService } from '../admin-error-message/admin-error-message.service';

export const ORDER_BY_ASC = 1;
export const ORDER_BY_DESC = -1;

export type OrderMultipleItem = [string, typeof ORDER_BY_ASC | typeof ORDER_BY_DESC];

/**
 * A Database file is a representation of a PF-Files retrieved by the Server.
 * This File can be filtered like an ORM for better readability.
 */
export class DatabaseFile {
	/**
	 * The selectedField is filled when the "select" method is called.
	 * It's later used by the execute method
	 */
	private selectedField: string | string[] = null;
	/**
	 * The groupedField is filled when the "groupBy" method is called.
	 * It's later used by the execute method
	 */
	private groupedField: string = null;
	/**
	 * The orderField is filled when the "orderBy" method is called.
	 * It's later used by the execute method
	 */
	private orderField: string = null;
	/**
	 * The orderDirection is filled when the "orderBy" method is called.
	 * It's later used by the execute method
	 */
	private orderDirection: number = null;
	/**
	 * The whereClauses is filled when the "where" method is called.
	 * It's later used by the execute method
	 */
	private whereClauses: any = null;
	/**
	 * The header is filled on object creation and contains the first row of data.
	 * It's used for all filtering and ordering operations and is later used by the execute method.
	 */
	public header: any = [];
	private orderMultiple?: any[] = null;

	constructor(
			public readonly name: string,
			private readonly csvData: any,
			private readonly adminErrorMessageService: AdminErrorMessageService
	) {
		this.header = this.csvData.data.shift();
	}

	/**
	 * Sets the selectedField property and returns the current object for method chaining.
	 */
	public select(selectedField: string | string[]): DatabaseFile {
		this.selectedField = selectedField;
		return this;
	}

	/**
	 * Sets the whereClauses property and returns the current object for method chaining.
	 */
	public where(clauses: any): DatabaseFile {
		this.whereClauses = clauses;
		return this;
	}

	/**
	 * Sets the groupedField property and returns the current object for method chaining.
	 */
	public groupBy(groupedField: string): DatabaseFile {
		this.groupedField = groupedField;
		return this;
	}

	/**
	 * Sets the orderField and orderDirection properties and returns the current object for method chaining.
	 */
	public orderBy(orderField: string, direction: 1 | -1 = ORDER_BY_ASC): DatabaseFile {
		this.orderField = orderField;
		this.orderDirection = direction;
		return this;
	}

	/**
	 * Sets the orderField and orderDirection properties and returns the current object for method chaining.
	 */
	public orderByMultiple(orders: OrderMultipleItem[]): DatabaseFile {
		this.orderMultiple = orders;
		return this;
	}

	/**
	 * This method invokes all previously defined operations like ordering, grouping or filtering and returns the resulting data.
	 */
	public execute(): any[] {
		let filteredArray = JSON.parse(JSON.stringify(this.csvData.data));
		filteredArray = this.executeWhereQuery(filteredArray);
		filteredArray = this.executeGroupQuery(filteredArray);
		filteredArray = this.executeOrderQuery(filteredArray);
		filteredArray = this.executeOrderMultipleQuery(filteredArray);
		let data = this.executeSelectQuery(filteredArray);
		this.reset();
		return data;
	}

	/**
	 * resets all fields after every execution in case the object is used after the "Database" operation again
	 */
	private reset(): void {
		this.selectedField = null;
		this.groupedField = null;
		this.orderField = null;
		this.orderDirection = null;
		this.whereClauses = null;
	}

	/**
	 * The where query basically filters the dataset based by the configured query conditions (if the where condition was set of cause).
	 */
	private executeWhereQuery(arr: any[]): any[] {
		if (this.whereClauses === null) {
			return arr;
		}
		const fieldValuesIndices = {};
		for (const name in this.whereClauses) {
			if (!this.whereClauseExists(name)) {
				continue;
			}
			fieldValuesIndices[this.getFieldIndexByHeader(name)] = this.whereClauses[name];
		}
		const keys = Object.keys(fieldValuesIndices);
		return arr.filter(value => {
			for (let i = 0; i < keys.length; i++) {
				if (!fieldValuesIndices[keys[i]].compare(value[keys[i]])) {
					return false;
				}
			}

			return true;
		});
	}

	/**
	 * checks whether the where clause exists or eventually was set to undefined
	 */
	private whereClauseExists(name: string): boolean {
		return this.whereClauses.hasOwnProperty(name) && typeof this.whereClauses[name] !== 'undefined';
	}

	/**
	 * The grouping of data is a filtering of the result set to return only unique values of the configured fields.
	 */
	private executeGroupQuery(arr: any[]): any[] {
		const fieldIndex = this.getFieldIndexByHeader(this.groupedField);
		const uniqueValues = [];
		if (this.groupedField === null) {
			return arr;
		}
		return arr.filter(value => {
			if (uniqueValues.indexOf(value[fieldIndex]) !== -1) {
				return false;
			}
			uniqueValues.push(value[fieldIndex]);
			return true;
		});
	}

	/**
	 * The order method does exactly what it is called like. It orders the possibly filtered data based by the configured field and direction.
	 */
	private executeOrderQuery(arr: any[]): any[] {
		const fieldIndex = this.getFieldIndexByHeader(this.orderField);
		if (this.orderField === null) {
			return arr;
		}
		arr.sort((a, b) => {
			let ac = a[fieldIndex];
			let bc = b[fieldIndex];
			if (DatabaseFile.isNumeric(ac) && DatabaseFile.isNumeric(bc)) {
				ac = +ac;
				bc = +bc;
			}
			if (ac === bc) {
				return 0;
			}
			return ac < bc ? this.orderDirection * -1 : this.orderDirection;
		});
		return arr;
	}

	/**
	 * The select query reduces the data to a smaller array containing only the fields used after the execution.
	 */
	private executeSelectQuery(arr: any[]): any[] {
		const fieldIndices = [];
		if (this.selectedField === null) {
			return arr;
		}
		if (typeof this.selectedField === 'string') {
			this.selectedField = [this.selectedField];
		}
		for (let i = 0; i < this.selectedField.length; i++) {
			fieldIndices.push(this.getFieldIndexByHeader(this.selectedField[i]));
		}
		return arr.map(value => {
			const ret = [];
			for (let i = 0; i < fieldIndices.length; i++) {
				ret.push(value[fieldIndices[i]]);
			}
			return ret;
		});
	}

	/**
	 * Simple method to correctly check for numeric values in the PF-Files
	 */
	private static isNumeric(n: any): boolean {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	/**
	 * Searches for the index of a column by its header name
	 */
	private getFieldIndexByHeader(header: string): number {
		const index = this.header.indexOf(header);

		if (header && index < 0) {
			this.adminErrorMessageService.addError(this.name, header);
		}

		return index;
	}

	private executeOrderMultipleQuery(arr: any[]): any[] {
		if (this.orderMultiple === null) {
			return arr;
		}
		for (let i = 0; i < this.orderMultiple.length; i++) {
			const fieldIndex = this.getFieldIndexByHeader(this.orderMultiple[i][0]);
			arr.sort((a, b) => {
				let ac: string | number = a[fieldIndex];
				let bc: string | number = b[fieldIndex];
				if (DatabaseFile.isNumeric(ac) && DatabaseFile.isNumeric(bc)) {
					ac = +ac;
					bc = +bc;
					if (ac === bc) {
						return 0;
					} else {
						return ac < bc ? this.orderMultiple[i][1] * -1 : this.orderMultiple[i][1];
					}
				}
				if (ac === bc) {
					return 0;
				}
				let acc: string = a[fieldIndex];
				let bcc: string = b[fieldIndex];
				// compare ac and bc as strings

				return acc.localeCompare(bcc) ? this.orderMultiple[i][1] * -1 : this.orderMultiple[i][1];
			});
		}

		return arr;
	}
}
