import { Component } from '@angular/core';
import { User } from '../user/user';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../user/user.service';
import { NOTIFICATION_ERROR, NotificationService } from '../../layout/notification/notification.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	/**
	 * The user object.
	 */
	public user: User = new User();
	/**
	 * if true, the login form is submitted.
	 */
	public loginFailed: boolean = false;

	constructor(
			private readonly router: Router,
			private readonly authenticationService: AuthenticationService,
			private readonly userService: UserService,
			private readonly notificationService: NotificationService
	) {
	}

	/**
	 * Logs the user in or displays an error message.
	 */
	public async login(): Promise<boolean | void> {
		this.loginFailed = false;
		try {
			if (!await this.userService.authenticate(this.user.email, this.user.password)) {
				return this.showLoginFailed();
			}
			return this.router.navigate([this.authenticationService.redirectUrl || '/home']);
		} catch (e) {
			this.showLoginFailed();
		}
	}

	/**
	 * Displays an error message.
	 */
	private showLoginFailed(): void {
		this.notificationService.notify('Login fehlgeschlagen. Nutzername oder Passwort falsch', NOTIFICATION_ERROR);
		this.loginFailed = true;
	}
}
