import { Pipe, PipeTransform } from '@angular/core';
import { UserNationality } from '../authorization/user/user-nationality/user-nationality';

@Pipe({
	name: 'userNationalitiesFilter',
	pure: true
})
export class UserNationalitiesFilterPipe implements PipeTransform {

	/**
	 * Returns true if the passed id is found in the UserNationalities array.
	 */
	public transform(value: UserNationality[], id: number): boolean {
		if (typeof value === 'undefined') {
			return false;
		}

		return !!value.find(nationality => nationality.id === id);
	}
}
