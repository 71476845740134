import { Component, OnInit } from '@angular/core';
import { UserGroupService } from '../../authorization/user/user-group/user-group.service';
import { ArticleListService } from '../../article-list/article-list.service';
import { NOTIFICATION_SUCCESS, NotificationService } from '../../layout/notification/notification.service';

@Component({
	selector: 'app-price-calculator-access',
	templateUrl: './price-calculator-access.component.html',
	styleUrls: ['./price-calculator-access.component.scss']
})
export class PriceCalculatorAccessComponent implements OnInit {

	/**
	 * Define groups
	 */
	public accessGroups = [];
	public accessSeries = [];
	public priceGroups = [
		{id: 'blattware', label: 'Blattware'},
		{id: 'band', label: 'Bänder'},
		{id: 'rolle', label: 'Geschnittene Rollen'},
		{id: 'scheibe', label: 'Discs'},
		{id: 'jumbo', label: 'Jumbo'}
	];
	public accessMatrix = null;

	constructor(
			private readonly articleListService: ArticleListService,
			private readonly userGroupService: UserGroupService,
			private readonly notificationService: NotificationService
	) {
	}

	public async ngOnInit(): Promise<void> {
		const databaseFile = await this.articleListService.getPfFile('710');
		let result = databaseFile.select('Serie')
				.groupBy('Serie')
				.orderBy('Serie')
				.execute();
		result = result.map(x => x[0]);
		result = result.filter(x => x.length);
		this.accessSeries = result;
		this.accessGroups = await this.userGroupService.getAllGroups();
		// @todo: show ajax loader
		await this.getAccessMatrix();
	}

	/**
	 * Generate access matrix from groups
	 */
	private async getAccessMatrix(): Promise<void> {
		const {matrix} = await this.userGroupService.getAccessMatrix();

		if (typeof matrix.group === 'undefined') {
			matrix.group = {};
		}
		if (typeof matrix.series === 'undefined') {
			matrix.series = {};
		}
		for (let i = 0; i < this.priceGroups.length; i++) {
			for (let j = 0; j < this.accessGroups.length; j++) {
				if (typeof matrix.group[this.priceGroups[i].id] === 'undefined') {
					matrix.group[this.priceGroups[i].id] = {};
				}
				if (typeof matrix.group[this.priceGroups[i].id][this.accessGroups[j].id] === 'undefined') {
					matrix.group[this.priceGroups[i].id][this.accessGroups[j].id] = false;
				}
			}
		}
		for (let i = 0; i < this.accessSeries.length; i++) {
			for (let j = 0; j < this.accessGroups.length; j++) {
				if (typeof matrix.series[this.accessSeries[i]] === 'undefined') {
					matrix.series[this.accessSeries[i]] = {};
				}
				if (typeof matrix.series[this.accessSeries[i]][this.accessGroups[j].id] === 'undefined') {
					matrix.series[this.accessSeries[i]][this.accessGroups[j].id] = false;
				}
			}
		}

		this.accessMatrix = matrix;
	}

	/**
	 * Updates access for series for users
	 */
	public async updateSeriesAccess(series, group): Promise<void> {
		await this.userGroupService.updateGroupAccess({
			userGroup: group.id,
			method: this.accessMatrix.series[series][group.id] ? 'add' : 'remove',
			series: series
		});
		this.notificationService.notify('Berechtigung erfolgreich angepasst.', NOTIFICATION_SUCCESS);
	}

	/**
	 * Updates access for groups for users
	 */
	public async updateGroupAccess(priceGroup, group): Promise<void> {
		await this.userGroupService.updateGroupAccess({
			userGroup: group.id,
			method: this.accessMatrix.group[priceGroup.id][group.id] ? 'add' : 'remove',
			group: priceGroup.id,
		});
		this.notificationService.notify('Berechtigung erfolgreich angepasst.', NOTIFICATION_SUCCESS);
	}
}
