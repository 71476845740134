import { ServiceLocator } from '../service-locator';
import { PriceCalculatorService } from './price-calculator.service';
import { PriceCalculator } from './price-calculator';
import { GROUP } from './groups';

export class Product {
	public id;
	public price: number | string;
	public pricePromise?: Promise<number | '-'>;

	constructor(
			public mainGroup: any,
			public group: GROUP,
			public series: any,
			public width: any,
			public length: any,
			public corn: any,
	) {
		const calculatorService = ServiceLocator.injector.get(PriceCalculatorService);
		const priceCalculator = new PriceCalculator(calculatorService);
		this.pricePromise = calculatorService.calculatePriceForProduct(this, priceCalculator);

		this.pricePromise.then(price => {
			this.price = price;
		});
	}

	static init(product: Product): Product {
		const copy = new Product(product.mainGroup, product.group, product.series, product.width, product.length, product.corn);
		copy.id = product.id;
		return copy;
	}
}
