import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ArticleListService } from '../article-list/article-list.service';
import { UserService } from '../authorization/user/user.service';
import { FeatureFlag } from '../feature-flags';
import { InputComponent } from '../form/input.component';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { ProductService } from '../generic-product-table/product.service';
import {
	NotificationService,
	NOTIFICATION_ERROR,
	NOTIFICATION_SUCCESS
} from '../layout/notification/notification.service';
import { WishlistEsService } from '../wishlist-es/wishlist-es.service';
import { MainGroup, PriceCalculatorEs } from './price-calculator-es';
import { PriceCalculatorEsService } from './price-calculator-es.service';
import { FormattedSelectOption } from './select-options.interface';

declare const _etracker;
declare const et_UserDefinedEvent;

@Component({
	selector: 'app-price-calculator-es',
	templateUrl: './price-calculator-es.component.html',
	styleUrls: ['../price-calculator/price-calculator.component.scss'],
})
export class PriceCalculatorEsComponent {
	@ViewChild(GenericDialogComponent) wishlistDialog: GenericDialogComponent;

	featureFlagWishlistEs = FeatureFlag.WishlistEs;

	addWishlistForm = this.fb.group({
		name: [''],
	});

	wishlists$ = this.wishlistEsService.wishlists$;

	public mainGroup: MainGroup;
	public calculationForm: PriceCalculatorEs;
	public groupsEs = this.productService.getGroupsEs();
	public maquinaOptions: FormattedSelectOption[];
	public abrasiveOptions: FormattedSelectOption[];
	public seriesOptions: FormattedSelectOption[];
	public cornOptions: FormattedSelectOption[];
	public sizeOptions: FormattedSelectOption[];
	public isUserGroupForStock: boolean = false;
	public formatoOptions: FormattedSelectOption[];

	constructor(
			private readonly productService: ProductService,
			private readonly notificationService: NotificationService,
			public readonly priceCalculatorEsService: PriceCalculatorEsService,
			public readonly articleListService: ArticleListService,
			private readonly userService: UserService,
			private readonly wishlistEsService: WishlistEsService,
			private readonly fb: FormBuilder
	) {
		this.calculationForm = priceCalculatorEsService.calculationForm;
		this.calculationForm.reset();
		this.userService.getCurrentUser().then((user) => {
			this.isUserGroupForStock = user.userGroup.id === 3 || user.userGroup.id === 4;
		});
	}

	/**
	 * onChange of main group select. Updates the current file that should be browsed for products, resets all the form
	 * selects and loads the very next form-step with its select options.
	 */
	public mainGroupSelect(): void {
		this.priceCalculatorEsService.resetAllSelectOptions();
		this.priceCalculatorEsService.selectMainGroup(this.mainGroup);
		this.updateOptions();
	}

	/**
	 * Generic method that should stay as modular as possible. Gets executed on every form select change,
	 * disregarding the type or metric the select is used for. formInputChange() will determine which select options
	 * to fill next via the Object keys of the service's options object literal using a key (string) that it gets
	 * passed through the method parameter by the according form-select.
	 */
	public formInputChange(key: string): void {
		let kkk = '';
		console.log(key);
		if (this.mainGroup === 'Accesorios') {
			if (key === 'formato') {
				kkk = 'corn';
			} else if (key === 'corn') {
				kkk = 'series';
			} else if (key === 'series') {
				kkk = 'size';
			}
		} else if ((this.mainGroup === 'Banda Portatil') && key === 'maquina') {
			kkk = 'abrasive';
		} else if ((this.mainGroup === 'Hojas' || this.mainGroup === 'Rollos Estandar' || this.mainGroup === 'Discos') && key === 'series') {
			kkk = 'size';
		} else if ((this.mainGroup === 'Hojas' || this.mainGroup === 'Rollos Estandar' || this.mainGroup === 'Discos') && key === 'size') {
			kkk = 'corn';
		} else {
			let upcomingSelectKeys = Object.keys(this.priceCalculatorEsService.options);
			upcomingSelectKeys.splice(0, this.findIndexOfCurrentSelect(key) + 1);
			kkk = upcomingSelectKeys[0];
		}
		this.priceCalculatorEsService.selectFromDB(kkk);
		this.priceCalculatorEsService.resetResultFields();
		this.updateOptions();
	}

	/**
	 * Returns the index of a given select in the service's options object literal using the key that is passed through
	 * the method's parameter.
	 */
	private findIndexOfCurrentSelect(key: string): number {
		let i = 0;
		for (let entry in this.priceCalculatorEsService.options) {
			if (this.priceCalculatorEsService.options[key] === this.priceCalculatorEsService.options[entry]) {
				break;
			}
			i++;
		}
		return i;
	}

	/**
	 * Sync the services options with the component to update the template.
	 */
	private updateOptions(): void {
		this.resetOptions();
		[this.maquinaOptions, this.abrasiveOptions, this.formatoOptions, this.seriesOptions, this.cornOptions, this.sizeOptions] = [
			this.priceCalculatorEsService.options.maquina,
			this.priceCalculatorEsService.options.abrasive,
			this.priceCalculatorEsService.options.formato,
			this.priceCalculatorEsService.options.series,
			this.priceCalculatorEsService.options.corn,
			this.priceCalculatorEsService.options.size,
		].map((serviceOptions: string[]) => serviceOptions.map((entry) => ({value: entry, label: entry})));
		this.dddd('maquina');
		this.dddd('abrasive');
		this.dddd('formato');
		this.dddd('series');
		this.dddd('corn');
		this.dddd('size');
	}

	private dddd(key: string) {
		const old = this.calculationForm[key];
		this.calculationForm[key] = '1';
		setTimeout(() => {
			this.calculationForm[key] = old;
		}, 100);
	}

	/**
	 * @description
	 * Reset the component's own class members that store the select options.
	 */
	private resetOptions(): void {
		this.maquinaOptions = [];
		this.abrasiveOptions = [];
		this.formatoOptions = [];
		this.seriesOptions = [];
		this.cornOptions = [];
		this.sizeOptions = [];
	}

	public validateWidthInput(input: InputComponent) {
		this.priceCalculatorEsService.resetResultFields();
		const response = this.priceCalculatorEsService.checkWidthRange();

		if (response.error) {
			if (response.dimensions.maxWidth) {
				input.model = response.dimensions.maxWidth;
			}

			return this.notificationService.notify(response.message, NOTIFICATION_ERROR, {}, 2000);
		}

		this.notificationService.notify('El valor está válido', NOTIFICATION_SUCCESS, {}, 2000);
	}

	public validateLengthInput(input: InputComponent) {
		this.priceCalculatorEsService.resetResultFields();
		const response = this.priceCalculatorEsService.checkLengthRange();

		if (response.error) {
			if (response.dimensions.maxLength) {
				input.model = response.dimensions.maxLength;
			}

			return this.notificationService.notify(response.message, NOTIFICATION_ERROR, {}, 2000);
		}

		this.notificationService.notify('El valor está válido', NOTIFICATION_SUCCESS, {}, 2000);
	}

	public validateExtInput(input: InputComponent) {
		this.priceCalculatorEsService.resetResultFields();
		this.notificationService.notify('El valor está válido', NOTIFICATION_SUCCESS, {}, 2000);
	}

	public validateIntInput(input: InputComponent) {
		this.priceCalculatorEsService.resetResultFields();
		this.notificationService.notify('El valor está válido', NOTIFICATION_SUCCESS, {}, 2000);
	}

	/**
	 * onChange of the discount text-input. Make sure the discount value stays between 0 and 100.
	 */
	public validateDiscountInput(event: KeyboardEvent, input: InputComponent): void {
		if (+input.model >= 0 && +input.model <= 100) {
			return;
		}

		input.model = '100';
		this.notificationService.notify('max. 100%', NOTIFICATION_ERROR, {}, 2000);
	}

	/**
	 * Price calculation method that will be executed by any 'product' main groups (BES, BAN and ROL).
	 * Executes a second validation of the width and length text-inputs and aborts if an error message has been found.
	 */
	public calculatePrice(widthInput: InputComponent, lengthInput: InputComponent): void {
		const requiresValidation = (['Banda Ancha', 'Rollos', 'Banda Estrecha'] as MainGroup[]).includes(this.mainGroup);

		if (!requiresValidation) {
			this.priceCalculatorEsService.getNetPrice();
			return;
		}

		const validationResponse = this.priceCalculatorEsService.checkBothRanges();

		if (!validationResponse.error) {
			this.priceCalculatorEsService.getNetPrice();
			return;
		}

		const inputMap: Record<keyof (typeof validationResponse)['dimensions'], InputComponent> = {
			maxWidth: widthInput,
			maxLength: lengthInput,
			minLength: null,
			minWidth: null,
		};

		Object.entries(validationResponse.dimensions).forEach(([dimension, value]) => {
			const input = inputMap[dimension];

			if (input) {
				input.model = value;
			}
		});

		this.notificationService.notify(validationResponse.message, NOTIFICATION_ERROR, {}, 2000);
	}

	get isValidForm(): boolean {
		const {corn, width, length, batch, size, extDiameter, intDiameter} = this.calculationForm;

		const requiredFields: Record<MainGroup, unknown[]> = {
			Accesorios: [size],
			'Banda Ancha': [corn, width, length],
			'Banda Estrecha': [corn, width, length, batch],
			'Banda Portatil': [width, length],
			Cepillos: [extDiameter, intDiameter, width],
			Discos: [size],
			Hojas: [size],
			Rollos: [corn, width, length],
			'Rollos Estandar': [size],
			'Anillos reforzados': [corn, width, length]
		};

		return requiredFields[this.mainGroup]?.every(Boolean);
	}

	public addProductToWishList(id: number): void {
		this.wishlistEsService.addProduct(id, this.calculationForm);
		this.wishlistDialog.close();
		this.notificationService.notify(`Erfolgreich zu Merkliste hinzugefügt.`, NOTIFICATION_SUCCESS);
	}

	public async addWishlist(): Promise<void> {
		await this.wishlistEsService.addOne(this.addWishlistForm.get('name').value);
		this.notificationService.notify(
				`Neue Merkliste "${this.addWishlistForm.get('name').value}" erstellt.`,
				NOTIFICATION_SUCCESS
		);
		this.addWishlistForm.reset();
	}

	eTrackerSendEvent(group: string) {
		_etracker.sendEvent(new et_UserDefinedEvent('Preisrechner', `${group} wurde ausgewählt`, 'Produktgruppe wählen', 'Click'));
	}
}
