import { Component, ViewChild } from '@angular/core';
import { UserService } from '../authorization/user/user.service';
import { from } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { WishlistEsService, WishlistEs } from './wishlist-es.service';
import { GenericDialogComponent, Payload } from '../generic-dialog/generic-dialog.component';

@Component({
	selector: 'app-wishlist-es',
	templateUrl: './wishlist-es.component.html',
	styleUrls: ['./wishlist-es.component.scss']
})
export class WishlistEsComponent {
	@ViewChild(GenericDialogComponent) renameDialog: GenericDialogComponent;

	user$ = from(this.userService.getCurrentUser());

	form = this.fb.group({
		name: [''],
	});

	editForm = this.fb.group({
		name: [''],
	});

	wishlists$ = this.wishlistEsService.wishlists$;

	constructor(
			private readonly fb: FormBuilder,
			private readonly userService: UserService,
			private readonly wishlistEsService: WishlistEsService,
	) {
	}

	edit({data}: Payload<WishlistEs>): void {
		this.wishlistEsService.updateOne({
			...data,
			title: this.editForm.get('name').value
		} as WishlistEs)
		this.editForm.reset();
	}

	delete(wishlist: WishlistEs): void {
		this.wishlistEsService.deleteOne(wishlist);
	}

	add(): void {
		this.wishlistEsService.addOne(this.form.get('name').value);
		this.form.reset();
	}
}
